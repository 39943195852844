<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onMount, createEventDispatcher, tick } from "svelte";
  import debounce from "lodash-es/debounce";
  import jQuery from "jquery";
  import { queryParameters, updateQueryParameters } from "../../stores/router";
  import CreateExamConfirmation from "./create-exam-confirmation.svelte";
  import DragNDrop from "../../components/drag-n-drop.svelte";
  import user from "../../stores/user";
  import offlineStorage from "store/dist/store.modern.min";
  import { trackAction } from "../../helpers/telemetry";
  import { PATIENT_SEARCH } from "../../patient-module/patients.graphql";
  import { client } from "../../helpers/apollo";
  import { renderFullName } from "../helpers";
  import { shortDate } from "../../helpers/formatters";
  import SearchBar from "../../components/search-bar.svelte";

  // Create an event dispatcher instance.
  const dispatch = createEventDispatcher();

  // Close the confirmation modal if it was left open via URL.
  onMount(() => updateQueryParameters({ modal: null }));

  // Load from local storage. Personalize to the user.
  const offlineCacheName = `create-exam-form-${$user.id}`;
  const offlineCache = offlineStorage.get(offlineCacheName) || {};

  // Form Fields
  let name_first = offlineCache.name_first || "";
  let name_last = offlineCache.name_last || "";
  let mrn = offlineCache.mrn || "";
  let gender = offlineCache.gender || "MALE";
  let birth_date = offlineCache.birth_date || null;
  let indication = offlineCache.indication || "";
  let examDate = offlineCache.examDate || "";
  let examTime = offlineCache.examTime || "";
  let fieldStrength = offlineCache.fieldStrength || "";
  const organization = $user.organization.organizationName;
  let facility = offlineCache.facility || "";
  let scannerModel = offlineCache.scannerModel || "";
  let scansToAdd = [];

  // Backup Form Fields to Local Storage.
  $: offlineBackup({
    name_first,
    name_last,
    mrn,
    gender,
    birth_date,
    indication,
    examDate,
    examTime,
    fieldStrength,
    facility,
    scannerModel,
    isExistingPatient,
  });
  const offlineBackup = debounce(function _offlineBackup(props) {
    offlineStorage.set(offlineCacheName, props);
  }, 350);

  // Exam Date & Time
  const maxDateToday = new Date().toISOString().split("T")[0];
  $: conductedAt = compute_conductedAt(examDate, examTime);
  function compute_conductedAt() {
    if (!examDate) return null;
    const timeStr = examTime ? `T${examTime}` : "";
    return new Date(`${examDate}${timeStr}`);
  }

  // Confirmation Modal - Form Submission
  function handleFormSubmit() {
    errorTxt = false;
    if (!validate()) return false;
    updateQueryParameters({ modal: "confirm-create-exam" }, false);
  }
  $: isConfirming = $queryParameters.modal === "confirm-create-exam";

  function handleExamCreated() {
    dispatch("exam-created");
    handleFormReset();
    trackAction("exam-created");
  }

  // Form Reset
  function handleFormReset() {
    name_first = name_last = mrn = birth_date = indication = examDate = examTime = fieldStrength = facility = scannerModel = errorTxt =
      "";
    gender = "MALE";
    scansToAdd = [];
  }

  // Form Validation
  let errorTxt = false;
  function validate() {
    let pass = true;
    if (!scansToAdd || !scansToAdd.length) {
      errorTxt = translate("validation.file_required");
      pass = false;
    }
    return pass;
  }

  // Patient Search & Creation
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let isExistingPatient = offlineCache.isExistingPatient || false;

  // Initialize some variables for loading state, search results, and DOM reference respectively.
  let patientSearchIsInFlight = false;
  let patientSearchResults;
  let patientSearchResultsEl;
  $: patientSearchResultsJQ = patientSearchResultsEl
    ? jQuery(patientSearchResultsEl)
    : false;

  // Handler to react to changing search text input.
  async function handlePatientSearchChange({ detail: searchTxt }) {
    // Clear any existing results from previous queries.
    patientSearchResults = undefined;

    // Keep the bootstrap-powered dropdown up-to-date with the search text change.
    if (patientSearchResultsJQ) patientSearchResultsJQ.dropdown("update");

    // If search has emptied, STOP here instead of going on to make HTTP request.
    if (!searchTxt) return;

    // Trigger loading state the UI.
    patientSearchIsInFlight = true;

    // execute the HTTP request
    const { data } = await client.query({
      query: PATIENT_SEARCH,
      variables: { cursor: null, search: searchTxt },
    });

    // Undo loading state in UI.
    patientSearchIsInFlight = false;

    // If there's empty results, set the empty state in the UI and STOP.
    if (!data || !data.viewer || !data.viewer.subjects)
      return (patientSearchResults = false);

    // Cache the results.
    patientSearchResults = data.viewer.subjects.edges.map(edge => edge.node);

    // After Svelte update the UI, trigger an update in the bootstrap-powered dropdown.
    await tick();
    if (patientSearchResultsJQ) patientSearchResultsJQ.dropdown("update");
  }

  // Click handler to react to selection of a Patient to create this Exam under.
  function selectPatient(patient) {
    mrn = patient.subjectId;
    gender = patient.sex;
    birth_date = patient.birthDate;
    if (patient.fullName) {
      name_first = patient.fullName.nameFirst;
      name_last = patient.fullName.nameLast;
    }
  }

  // Click handler to clear Patient selection
  function clearSelectedPatient() {
    mrn = birth_date = name_first = name_last = "";
    gender = "MALE";
  }

  // Click handler to toggle between searching and entering a new Patient.
  function handlePatientSearchToggle() {
    clearSelectedPatient();
    isExistingPatient = !isExistingPatient;
  }

  // DOM event handler to make sure the BS dropdown triggers.
  function onSearchBarFocus() {
    if (patientSearchResultsJQ) patientSearchResultsJQ.dropdown("show");
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $examsColor: map-get($module-colors, "exams");
  $scansColor: map-get($module-colors, "scans");

  .add-exam-form {
    border-top-color: $examsColor !important;
  }
  #CreateExamIndication {
    height: calc(100% - 2rem);
  }
  .new-scan-row {
    border-top-color: $scansColor !important;
    @include media-breakpoint-up(sm) {
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .scan-delete {
    @include media-breakpoint-up(sm) {
      position: unset !important;
    }
  }
  .patient-search-results {
    max-height: 8rem;
    overflow: auto;
  }
</style>

<section
  class="add-exam-form | mb-4 pt-4 pb-2 px-4 border rounded position-relative
  shadow | animated fadeIn faster"
  data-component="create-exam-form">

  <!-- Title -->
  <div class="title-bgblock left-0 top-0 position-absolute w-100 underlay" />
  <h3 class="text-truncate mt-3 mb-4">
    <span>{translate('form_labels.create_exam')}</span>
  </h3>

  <!-- Close Button -->
  <a
    class="close position-absolute top-0 right-0 p-3 m-1"
    aria-label={translate('form_labels.close')}
    href="#/"
    on:click={handleFormReset}>
    <span aria-hidden="true">×</span>
  </a>

  <!-- Form -->
  <form on:submit|preventDefault={handleFormSubmit}>

    <div class="text-muted mb-4">
      <p class="d-inline text-muted mb-0">
        {translate('form_labels.create_exam_info')}
      </p>
      <!-- <a class="btn btn-link text-exams pl-2 pt-0" href="#/schedule-exam">{translate("form_labels.mean_to_schedule")}</a> -->
    </div>

    <!-- Scans -->
    <fieldset class="my-2">
      <legend class="text-capitalize">{translate('modules.scan', 99)}</legend>
      {#if scansToAdd && scansToAdd.length}
        {#each scansToAdd as scan, i (scan.name)}
          <div
            class="new-scan-row form-row fade-in border rounded shadow p-3
            position-relative mb-2 mb-xl-2">

            <!-- File Name -->
            <div class="form-group col-sm-6 col-md-4">
              <!-- <div class="form-group col-12 col-xl-4 mb-1"> -->
              <label for="CreatExamScanList{i}" class="text-truncate">
                {translate('form_labels.file_name')}
              </label>
              <input
                type="text"
                id="CreatExamScanList{i}"
                class="form-control form-control-sm"
                disabled
                value={scan.name} />
            </div>

            <!-- Sequence Name
            <div class="form-group col-sm-6 col-lg-3 col-xl-2 mb-1">
              <label for="CreateExamSeqName{i}" class="text-truncate">
                {translate('exam_data.sequence_name')}
              </label>
              <input
                type="text"
                id="CreateExamSeqName{i}"
                class="form-control form-control-sm"
                bind:value={scansToAdd[i].sequenceName} />
            </div> -->

            <!-- Sequence Parameters
            <div class="form-group col-sm-6 col-lg-3 col-xl-2 mb-1">
              <label for="CreateExamSeqParam{i}" class="text-truncate">
                {translate('exam_data.sequence_parameters')}
              </label>
              <input
                type="text"
                id="CreateExamSeqParam{i}"
                class="form-control form-control-sm text-uppercase"
                bind:value={scansToAdd[i].sequenceParameters} />
            </div> -->

            <!-- Contrast Type
            <div class="form-group col-sm-6 col-lg-3 col-xl-2 mb-1">
              <label for="CreatExamContrastType{i}" class="text-truncate">
                {translate('exam_data.contrast_type')}
              </label>
              <input
                type="text"
                id="CreatExamContrastType{i}"
                class="form-control form-control-sm text-uppercase"
                bind:value={scansToAdd[i].contrastType} />
            </div> -->

            <div class="col-sm-6 col-md-8 position-unset">
              <!-- <div class="col-sm-6 col-lg-3 col-xl-2 position-unset"> -->
              <div class="form-row">

                <!-- Description -->
                <div class="form-group col mb-1">
                  <label for="CreateExamDesc{i}" class="text-truncate">
                    {translate('form_labels.description')}
                  </label>
                  <input
                    type="text"
                    id="CreateExamDesc{i}"
                    class="form-control form-control-sm"
                    bind:value={scan.description_user}
                    required />
                </div>

                <!-- Duration
                <div class="form-group col mb-1">
                  <label for="CreateExamDuration{i}" class="text-truncate">
                    {translate('form_labels.duration')}
                  </label>
                  <input
                    type="number"
                    step="0.25"
                    min="0"
                    id="CreateExamDuration{i}"
                    class="form-control form-control-sm"
                    bind:value={scansToAdd[i].duration} />
                </div> -->

                <!-- Delete -->
                <div
                  class="scan-delete col-auto d-flex align-items-end pb-2
                  position-absolute top-0 right-0 py-1 px-2">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    on:click={function handleNewScanRemove() {
                      scansToAdd.splice(i, 1);
                      scansToAdd = scansToAdd;
                    }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/each}
      {/if}

      <!-- Drag n' Drop Uploader -->
      <DragNDrop
        className="mt-3"
        bind:value={scansToAdd}
        multiple="true"
        accept=".nii, .nifti, .dcm, .dicom" />
    </fieldset>

    <!-- Exam Info -->
    <fieldset class="my-2">
      <legend>{translate('exam_data.info')}</legend>
      <div class="form-row">

        <!-- Indication -->
        <div class="form-group col-12">
          <!-- <div class="form-group col-lg-6"> -->
          <label for="CreateExamIndication">
            {translate('exam_data.indication_description')}
          </label>
          <textarea
            class="form-control"
            id="CreateExamIndication"
            placeholder={translate('form_labels.indication_placeholder')}
            bind:value={indication} />
        </div>

        <!-- Split Side -->
        <div class="col-12">
          <!-- <div class="col-lg-6"> -->
          <div class="form-row">

            <!-- Exam Date -->
            <div class="form-group col-sm-6">
              <!-- <div class="form-group col-sm-6 col-xl-4"> -->
              <label for="CreateExamExamDate">
                {translate('exam_data.date', 1)}
              </label>
              <input
                type="date"
                class="form-control"
                id="CreateExamExamDate"
                max={maxDateToday}
                bind:value={examDate}
                required />
            </div>

            <!-- Exam Time -->
            <div class="form-group col-sm-6">
              <!-- <div class="form-group col-sm-6 col-xl-4"> -->
              <label for="CreateExamExamTime">
                {translate('exam_data.conducted_at', 1)}
              </label>
              <input
                type="time"
                class="form-control"
                id="CreateExamExamTime"
                bind:value={examTime} />
            </div>

            <!-- Field Strength
            <div class="form-group col-sm-6 col-xl-4">
              <label for="CreateExamFieldStrength">
                {translate('exam_data.scanner_field_strength', 1)}
              </label>
              <input
                type="number"
                step="0.001"
                min="0"
                class="form-control"
                id="CreateExamFieldStrength"
                bind:value={fieldStrength} />
            </div> -->

            <!-- Scanner Model
            <div class="form-group col-sm-6 col-xl-4">
              <label for="CreateExamScannerModel">
                {translate('exam_data.scanner_model')}
              </label>
              <input
                type="text"
                class="form-control"
                id="CreateExamScannerModel"
                bind:value={scannerModel} />
            </div> -->

            <!-- Organization
            <div class="form-group col-sm-6 col-xl-4">
              <label for="CreateExamOrganization">
                {translate('modules.organizations', 1)}
              </label>
              <input
                type="text"
                class="form-control"
                id="CreateExamOrganization"
                value={organization}
                readonly />
            </div> -->

            <!-- Facility
            <div class="form-group col-sm-6 col-xl-4">
              <label for="CreateExamFacility">
                {translate('exam_data.facility')}
              </label>
              <input
                type="text"
                class="form-control"
                id="CreateExamFacility"
                bind:value={facility} />
            </div> -->
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Patient Info -->
    <fieldset class="my-2">
      <legend>
        <span>{translate('patient_data.info', 1)}</span>
        <button
          type="button"
          class="btn btn-sm btn-link pb-2"
          on:click={handlePatientSearchToggle}>
          <span>
            {isExistingPatient ? translate('patient_data.create_patient') : translate('patient_data.search_patients')}
          </span>
        </button>
      </legend>

      <!-- New Patient -->
      <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -   -->
      {#if !isExistingPatient}
        <div class="form-row">

          <!-- First Name -->
          <div class="form-group col-sm-6 col-lg-6 col-xl-3">
            <label for="CreateExamName">
              {translate('form_labels.name_first', 1)}
            </label>
            <div class="input-group">
              <input
                id="CreateExamName"
                type="text"
                class="form-control"
                aria-label={translate('form_labels.name_first', 1)}
                aria-describedby="PatientLookupAddon"
                bind:value={name_first} />
            </div>
          </div>

          <!-- Last Name -->
          <div class="form-group col-sm-6 col-lg-6 col-xl-3">
            <label for="CreateExamNameLast">
              {translate('form_labels.name_last', 1)}
            </label>
            <div class="input-group">
              <input
                id="CreateExamNameLast"
                type="text"
                class="form-control"
                aria-label={translate('form_labels.name_last', 1)}
                bind:value={name_last} />
            </div>
          </div>

          <!-- MRN -->
          <div class="form-group col-sm-4 col-lg-4 col-xl-2">
            <label for="CreateExamMRN">{translate('patient_data.id', 1)}</label>
            <input
              class="form-control"
              type="text"
              id="CreateExamMRN"
              bind:value={mrn} />
          </div>

          <!-- Gender -->
          <div class="form-group col-sm-4 col-lg-4 col-xl-2">
            <label for="CreateExamGender">
              {translate('patient_data.gender', 1)}
            </label>
            <select
              class="custom-select"
              id="CreateExamGender"
              bind:value={gender}
              required>
              <option value="MALE">
                {translate('patient_data.gender_male', 1)}
              </option>
              <option value="FEMALE">
                {translate('patient_data.gender_female', 1)}
              </option>
            </select>
          </div>

          <!-- Date of Birth -->
          <div class="form-group col-sm-4 col-lg-4 col-xl-2">
            <label for="CreateExamDoB">
              {translate('patient_data.dob', 1)}
            </label>
            <input
              type="date"
              class="form-control"
              id="CreateExamDoB"
              max={maxDateToday}
              bind:value={birth_date} />
          </div>
        </div>

        <!-- Existing Patient -->
        <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -   -->
      {:else}
        <div class="dropdown">
          <div
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            bind:this={patientSearchResultsEl}>
            <SearchBar
              className="mb-2"
              placeholder={translate('patient_data.search_patients_hint')}
              on:searchTxtChange={handlePatientSearchChange}
              on:focus={onSearchBarFocus} />
          </div>

          <!-- Patient Search Results -->
          <div
            class="dropdown-menu w-100 patient-search-results"
            aria-labelledby="dropdownMenuButton">
            {#if patientSearchIsInFlight}
              <div class="px-3">
                <div class="spinner-border small" role="status">
                  <span class="sr-only">{translate('loadingDotDotDot')}</span>
                </div>
              </div>
            {:else if patientSearchResults === undefined}
              <div class="px-3">
                <span class="text-muted">
                  {translate('form_placeholders.typeahead_hint')}
                </span>
              </div>
            {:else if patientSearchResults === false || !patientSearchResults.length}
              <div class="px-3 py-2">
                <span class="text-muted">
                  {translate('patient_data.none_found', 99)}
                </span>
              </div>
            {:else}
              {#each patientSearchResults as patient (patient.subjectId)}
                <button
                  type="button"
                  class="dropdown-item d-flex justify-content-between"
                  on:click={() => selectPatient(patient)}>
                  <span>
                    {renderFullName(patient.fullName)} &middot; {patient.subjectId}
                  </span>
                  <span>{shortDate(patient.birthDate)}</span>
                  <span>{patient.sex}</span>
                </button>
              {/each}
            {/if}
          </div>

          <!-- Selected Patient Details -->
          {#if mrn}
            <div
              class="patient-list-item selected | rounded border mb-2 shadow
              w-100 position-relative">

              <!-- Name -->
              <div>
                <h5 class="font-weight-normal">
                  {#if name_first || name_last}
                    {renderFullName({ name_first, name_last })}
                  {:else}
                    <em>{translate('patient_data.unknown')}</em>
                  {/if}
                </h5>
                <span class="badge badge-patients mr-2">
                  {translate('modules.patient', 1)}
                </span>
              </div>

              <!-- Unique Identifier -->
              <div class="text-truncate">
                <small class="text-muted letter-space-1">
                  {translate('id', 1)}
                </small>
                <br />
                <span>{mrn}</span>
              </div>

              <!-- Date of Birth -->
              <div class="text-truncate d-none d-sm-block">
                <small class="text-muted letter-space-1">
                  {translate('patient_data.dob', 1)}
                </small>
                <br />
                <span>
                  {#if birth_date}
                    {birth_date}
                  {:else}
                    <em>{translate('patient_data.dob_unknown', 1)}</em>
                  {/if}
                </span>
              </div>

              <!-- Gender -->
              <div class="text-truncate d-none d-md-block">
                <small class="text-muted letter-space-1 text-capitalize">
                  {translate('patient_data.gender', 1)}
                </small>
                <br />
                <span>
                  {#if !gender}
                    {translate('patient_data.gender_unknown', 1)}
                  {:else if gender === 'FEMALE'}
                    {translate('patient_data.gender_female', 1)}
                  {:else if gender === 'MALE'}
                    {translate('patient_data.gender_male', 1)}
                  {:else}{translate('patient_data.gender_unknown', 1)}{/if}
                </span>
              </div>

              <!-- Clear Button -->
              <button
                type="button"
                class="close position-absolute top-0 right-0 p-3 m-1"
                on:click={clearSelectedPatient}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
          {/if}
        </div>
      {/if}
    </fieldset>

    <!-- Submit Button -->
    <button type="submit" class="btn btn-exams btn-block mb-2">
      <span>{translate('form_labels.create_exam_submit')}</span>
    </button>

    <!-- Error Message -->
    {#if errorTxt}
      <div>
        <div class="alert alert-danger mb-2" role="alert">
          {@html errorTxt}
        </div>
      </div>
    {/if}

    <!-- Reset Button -->
    <button
      type="button"
      class="btn btn-sm btn-link btn-block"
      on:click={handleFormReset}>
      <span>{translate('form_labels.reset')}</span>
    </button>

  </form>
</section>

<!-- Confirmation Modal -->
{#if isConfirming}
  <CreateExamConfirmation
    {mrn}
    {name_first}
    {name_last}
    {gender}
    {birth_date}
    {indication}
    {conductedAt}
    {fieldStrength}
    {organization}
    {facility}
    {scannerModel}
    {scansToAdd}
    {isExistingPatient}
    on:exam-created={handleExamCreated} />
{/if}
