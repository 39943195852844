// Import our external dependencies and settings.
import { writable } from "svelte/store";
import jwtDecode from "jwt-decode";
import store from "store/dist/store.modern.min";

// Create a store for the token.
export const token = writable(store.get("id_token") || "");
token.subscribe($token => store.set("id_token", $token));

// Function to authenticate against Auth0.
export async function authenticate({ email, password }) {
  // Make the HTTP request to authenticate.
  const resp = await fetch("https://hyperfine-research.auth0.com/oauth/ro", {
    method: "POST",
    body: JSON.stringify({
      client_id: "CQ4yVTafVf4eupvnFLiik3jteddoPOfn",
      sso: false,
      username: email,
      password,
      scope: "openid organization perms user_type",
      connection: "Users",
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });

  // Destruct the props we need from the response.
  const { error, id_token } = await resp.json();

  // Error handling.
  if (!resp.ok) throw new LoginError(error);

  // Read user details from the token.
  const { exp, organization, perms, user_type } = jwtDecode(id_token);

  // Update the store to trigger reactivity.
  token.set(id_token);

  // Return user info.
  return {
    organization,
    perms,
    user_type,
    expiresIn: getTokenTte(exp),
  };
}

// Define Special Error for Login Failure.
class LoginError extends Error {
  constructor(code) {
    super("Login error");
    this.code = code;
  }
}

// Extrapolate the time from an expiration stamp.
function getTokenTte(exp) {
  if (exp == null) {
    return Infinity;
  }
  const expirationTime = exp * 1000;
  return expirationTime - Date.now();
}
