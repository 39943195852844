<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { createEventDispatcher } from "svelte";
  import { dateAndTime, shortDate } from "../../helpers/formatters";
  import { getAuthHeader } from "../../helpers/apollo";
  import { fetchTranslator, makeErrorHandler } from "../../helpers/fetch";
  import { getBadgeClassName, renderFullName } from "../helpers";
  import Modal from "../../components/modal.svelte";
  import PhaseButton from "../../components/phase-button.svelte";
  import { httpCreateScan } from "../add-scan-form.svelte";

  // Bindings
  export let name_first;
  export let name_last;
  export let mrn;
  export let gender;
  export let birth_date;
  export let indication;
  export let conductedAt;
  export let fieldStrength;
  export let organization;
  export let facility;
  export let scannerModel;
  export let scansToAdd;
  export let isExistingPatient;

  // Create event listener.
  const dispatch = createEventDispatcher();

  // Give submit button focus when opened.
  let submitBtn = null;
  $: if (submitBtn) submitBtn.focus();

  // Form Submission - HTTP Requests to create Patient, Exam, and Scans.
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |

  // UI variables for rendering http request status.
  let isInFlight = false;
  let isSuccessful = false;
  let freshExam = null;
  let errorMsg = "";

  // Error handler to update the UI (and report error in abstraction)
  const fetchErrorHandler = makeErrorHandler(err => {
    isInFlight = isSuccessful = false;
    freshExam = null;
    errorMsg = err;
  });

  // Form Submission Handler to make HTTP requests for Patient, Exam, and Scans.
  async function handleFormSubmit() {
    // Reset error and mark UI as in-flight.
    errorMsg = "";
    isInFlight = true;

    // Create the Patient
    // *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  |
    const patient = await fetch(
      "https://app-4534.on-aptible.com/api/v1/subjects/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeader(),
        },
        body: JSON.stringify({
          data: {
            birth_date,
            full_name: { name_first, name_last },
            sex: gender,
            subject_id: mrn,
          },
        }),
      }
    )
      .then(fetchTranslator)
      .catch(fetchErrorHandler);

    // Create the Exam
    // *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  |
    const exam = await fetch("https://app-4534.on-aptible.com/api/v1/exams/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        data: {
          subject: patient.id,
          tags: [],
          conducted_dt: conductedAt ? conductedAt.toISOString() : null,
          description_user: indication,
          device: "3rd Party",
        },
      }),
    })
      .then(fetchTranslator)
      .catch(fetchErrorHandler);

    // Create the Scans
    // *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  *  |
    const scans = await Promise.all(
      scansToAdd.map(async scan => {
        const httpScan = await httpCreateScan({
          file: scan,
          conductedDt: exam.conducted_dt,
          examId: exam.id,
          descriptionTxt: scan.description_user,
        })
          .then(fetchTranslator)
          .catch(fetchErrorHandler);

        return httpScan;
      })
    );
    if (errorMsg) return (isInFlight = false);

    // Respond to the successful creation.
    dispatch("exam-created");
    freshExam = {
      ...exam,
      subject: patient,
      seriesSet: scans,
    };
    isInFlight = false;
    isSuccessful = !!freshExam;
  }

  // Error Rendering
  let errorMsgEl;
  $: if (errorMsgEl) {
    errorMsgEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  // Proxy between scans to create and scan that were created.
  $: scans = freshExam ? freshExam.seriesSet : scansToAdd;
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $examsColor: map-get($module-colors, "exams");
  $scansColor: map-get($module-colors, "scans");
  $patientsColor: map-get($module-colors, "patients");
  .confirm-modal {
    :global(.modal-content) {
      border-top-color: $examsColor !important;
    }
    .summary {
      border-top-color: $examsColor !important;
    }
    .patient-summary {
      border-top-color: $patientsColor !important;
    }
  }
</style>

<div class="confirm-modal" data-component="create-exam-confirmation">
  <Modal title="Confirm Exam Add" className="modal-xl modal-dialog-scrollable">
    <div class="modal-body">
      <div class="container-fluid fade-in px-0">
        <div class="row pt-3">

          <!-- Summary Panel -->
          <div class="col-12 col-lg-6 col-xl-5 | pb-4">
            <div class="position-relative">
              <div class="title-bgblock left-0 top-0 position-absolute w-100" />
            </div>
            <section
              class="summary | p-4 border rounded position-relative shadow">
              <h1 class="text-truncate">
                {#if freshExam}
                  {renderFullName(freshExam.subject.full_name)}
                {:else if isExistingPatient}
                  {renderFullName({ name_first, name_last })}
                {:else}
                  <em>{translate('form_labels.new_patient')}</em>
                {/if}
              </h1>
              <span class="badge badge-exams mr-2">
                {translate('form_labels.new_exam')}
              </span>
              <div class="details-list | mt-3">
                <small
                  class="text-muted text-capitalize letter-space-1 d-block mb-2">
                  {translate('overview', 1)}
                </small>

                <!-- MRN (Existing Patient Only) -->
                {#if isExistingPatient}
                  <div class="row d-flex justify-content-between py-1">
                    <div class="col-auto">
                      {translate('exam_data.medical_records_number_short')}
                    </div>
                    <div class="col text-right text-truncate">
                      {freshExam && freshExam.subject && freshExam.subject.subject_id ? freshExam.subject.subject_id : mrn}
                    </div>
                  </div>
                {/if}

                <!-- Conducted At -->
                <div
                  class="updated-at | row d-flex justify-content-between py-1">
                  <span class="col-auto text-capitalize">
                    {translate('time.conducted_at')}
                  </span>
                  {#if conductedAt || (freshExam && freshExam.conducted_dt)}
                    <span
                      class="col text-right text-truncate"
                      title={dateAndTime(conductedAt || freshExam.conducted_dt)}>
                      {dateAndTime(conductedAt || freshExam.conducted_dt)}
                    </span>
                  {:else}
                    <em class="col text-right text-truncate">
                      {translate('unknown')}
                    </em>
                  {/if}
                </div>

                <!-- Referring Physician -->
                <div class="row d-flex justify-content-between py-1">
                  <div class="col-auto">
                    {translate('people_roles.referring_physician')}
                  </div>
                  <div class="col text-right text-truncate">
                    {translate('you')}
                  </div>
                </div>

                <!-- Field Strength
                <div class="row d-flex justify-content-between py-1">
                  <div class="col-auto">
                    {translate('exam_data.scanner_field_strength')}
                  </div>
                  <div
                    class="col text-right text-truncate"
                    class:font-italic={!fieldStrength}>
                    {fieldStrength || translate('unknown')}
                  </div>
                </div> -->

                <!-- Scanner Model
                <div class="row d-flex justify-content-between py-1">
                  <div class="col-auto">
                    {translate('exam_data.scanner_model')}
                  </div>
                  <div
                    class="col text-right text-truncate"
                    class:font-italic={!scannerModel}>
                    {scannerModel || translate('unknown')}
                  </div>
                </div> -->

                <!-- Organization -->
                <div class="row d-flex justify-content-between py-1">
                  <div class="col-auto">
                    {translate('modules.organizations')}
                  </div>
                  <div
                    class="col text-right text-truncate"
                    class:font-italic={!organization}>
                    {organization || translate('unknown')}
                  </div>
                </div>
              </div>
              <div class="details-list | mt-4">
                <small
                  class="text-muted text-capitalize letter-space-1 d-block mb-2">
                  {translate('exam_data.indication_description')}
                </small>
                <p
                  class:font-italic={!indication || !freshExam || !freshExam.description_user}>
                  {#if indication}
                    {indication}
                  {:else if freshExam && freshExam.description_user}
                    {freshExam.description_user}
                  {:else}{translate('exam_data.indication_missing')}{/if}
                </p>
              </div>
            </section>
          </div>

          <!-- Right Side -->
          <div class="col-12 col-lg-6 col-xl-7 | pb-4">

            <!-- Patient Info -->
            {#if !isExistingPatient}
              <div class="position-relative">
                <div
                  class="title-bgblock left-0 top-0 position-absolute w-100" />
              </div>
              <section
                class="patient-summary | p-4 border rounded position-relative
                shadow mb-3">
                <h1 class="text-truncate">
                  {#if name_first || name_last}
                    {#if name_first}
                      <span>{name_first}</span>
                    {/if}
                    {#if name_last}
                      <span>{name_last}</span>
                    {/if}
                  {:else if freshExam && freshExam.subject && freshExam.subject.full_name}
                    <span>{renderFullName(freshExam.subject.full_name)}</span>
                  {:else}
                    <em>{translate('anonymous')}</em>
                  {/if}
                </h1>
                <span class="badge badge-patients mr-2">
                  {translate('form_labels.new_patient')}
                </span>
                <div class="details-list | mt-3">
                  <small
                    class="text-muted text-capitalize letter-space-1 d-block
                    mb-2">
                    {translate('overview', 1)}
                  </small>

                  <!-- MRN -->
                  {#if mrn || (freshExam && freshExam.subject && freshExam.subject.subject_id)}
                    <div
                      class="row d-flex justify-content-between py-1 | animated
                      fadeIn faster">
                      <span class="col-auto text-capitalize">
                        {translate('exam_data.medical_records_number_short', 1)}
                      </span>
                      <span
                        class="col text-right text-truncate"
                        title={mrn || freshExam.subject.subject_id}>
                        {mrn || freshExam.subject.subject_id}
                      </span>
                    </div>
                  {/if}

                  <!-- Date of Birth -->
                  <div class="row d-flex justify-content-between py-1">
                    <span class="col-auto text-capitalize">
                      {translate('patient_data.dob', 1)}
                    </span>
                    {#if birth_date || (freshExam && freshExam.subject && freshExam.subject.birth_date)}
                      <span
                        class="col text-right text-truncate"
                        title={shortDate(birth_date || freshExam.subject.birth_date)}>
                        {shortDate(birth_date || freshExam.subject.birth_date)}
                      </span>
                    {:else}
                      <em class="col text-right text-truncate">
                        {translate('patient_data.dob_unknown')}
                      </em>
                    {/if}
                  </div>

                  <!-- Gender -->
                  <div class="row d-flex justify-content-between py-1">
                    <span class="col-auto text-capitalize">
                      {translate('patient_data.gender', 1)}
                    </span>
                    {#if gender || (freshExam && freshExam.subject && freshExam.subject.sex)}
                      <span
                        class="col text-right text-truncate text-capitalize"
                        title={gender || freshExam.subject.sex}>
                        {gender || freshExam.subject.sex}
                      </span>
                    {:else}
                      <em class="col text-right text-truncate">
                        {translate('patient_data.gender_unknown')}
                      </em>
                    {/if}
                  </div>

                  <!-- Organization -->
                  <div
                    class="organizations | row d-flex justify-content-between
                    py-1">
                    <div class="col-auto">
                      {translate('modules.organizations', 1)}
                    </div>
                    <div
                      class="col text-right text-truncate"
                      class:font-italic={!organization}
                      title={organization}>
                      {organization || translate('unknown')}
                    </div>
                  </div>
                </div>
              </section>
            {/if}

            <!-- Scan List -->
            <div class="container-fluid">
              {#if !scans || !scans.length}
                <h3 class="p-4 font-italic text-center">
                  {translate('exam_data.no_scans')}
                </h3>
              {/if}
              {#each scans as { name, description_user, ...scan } (name)}
                <!-- {#each scansToAdd as { name, sequenceName, sequenceParameters, contrastType, duration } (name)} -->
                <div
                  class="row mb-3 scan-list-item | border shadow rounded
                  selected {description_user}">

                  <!-- Name and Type -->
                  <div class="col-12 col-xl my-2">
                    <h5 class="font-weight-normal text-uppercase">
                      {description_user}
                    </h5>
                    <span
                      class="border border-scans {getBadgeClassName(description_user)}
                      mr-2">
                      {description_user}
                    </span>
                  </div>

                  <!-- Sequence Parameters
                  <div class="col-xl-5 col-8 my-2">
                    <span class="small letter-space-1 text-muted d-block mb-2">
                      {translate('exam_data.sequence_parameters')}
                    </span>
                    <span>{sequenceParameters || ''}</span>
                  </div> -->

                  <!-- Duration
                  <div class="col-xl-2 col-4 my-2">
                    <span class="small letter-space-1 text-muted d-block mb-2">
                      {translate('exam_data.sequence_duration')}
                    </span>
                    <span>{duration || ''}</span>
                  </div> -->
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <form
      class="modal-footer flex-column"
      on:submit|preventDefault={handleFormSubmit}>

      <PhaseButton
        className="btn btn-block mx-0"
        startClassName="btn-exams"
        successTxt={translate('form_labels.success')}
        bind:elThis={submitBtn}
        {isInFlight}
        {isSuccessful}>
        <span>{translate('form_labels.confirm')}</span>
      </PhaseButton>

      {#if freshExam}
        <a
          href={`#exams/details/${freshExam.id}`}
          class="btn btn-block btn-link mx-0 | animated fadeIn faster">
          <span>{translate('form_labels.open_new_exam')}</span>
        </a>
      {/if}

      <!-- Error Message -->
      {#if errorMsg}
        <div
          class="animated fadeIn faster alert alert-danger text-monospace mt-2
          w-100"
          role="alert"
          bind:this={errorMsgEl}>
          <h4 class="alert-heading">
            {translate('support.error_has_occurred')}
          </h4>
          <p>{errorMsg}</p>
        </div>
      {/if}
    </form>
  </Modal>
</div>
