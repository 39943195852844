<script>
  import { route1, route2 } from "../stores/router";
  import PatientHome from "./patient-home.svelte";
  import PatientDetails from "./patient-details.svelte";
</script>

<section data-component="patient-module" class="h-100">

  <!-- Sub Routing -->
  {#if $route1 === 'details'}
    <PatientDetails examSubjectId={$route2} />
  {:else if !$route1}
    <PatientHome />
  {/if}
</section>
