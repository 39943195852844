//
// Add the current user details to our Analytics session.
export function trackUser(user) {
  // Sentry.io
  if (window.Sentry) {
    window.Sentry.configureScope(scope => scope.setUser(user));
  }

  // Google Analytics
  if (window.ga) {
    window.ga("set", "userId", user ? user.userId : user);
    window.ga("send", "event", "authentication", "user-id available");
  }
}

// Remove the current user details from our Analytics session.
export function untrack() {
  // Sentry.io
  if (window.Sentry) {
    window.Sentry.configureScope(scope => scope.clear());
  }

  // Google Analytics
  if (window.ga) {
    window.ga("set", "userId", null);
    window.ga("send", "event", "logout", "user-id unavailable");
  }
}

// Register a page change.
export function trackPageView(page = "") {
  if (!window.ga) return console.info(`Page View : ${page}`);
  window.ga("set", "page", page);
  window.ga("send", "pageview");
}

// Register a user action.
export function trackAction(category, action) {
  if (!window.ga) return console.info(`Page Action : ${category} : ${action}`);
  window.ga("send", "event", category, action);
}
