// Import our external dependencies.
import { writable } from "svelte/store";
import { ready as i18nReady } from "i18n"; //eslint-disable-line import/no-unresolved
import makeUUID from "../helpers/uuid";

// Create a local array and writable store for the notifications.
let _notifications = [];
const notifications = writable(_notifications);
export default notifications;

// Provide a function to show the App refresh prompt after updating.
export async function showRefreshPrompt(handleAccept) {
  await i18nReady;
  _notifications = [
    {
      uuid: makeUUID(),
      status: "ok",
      hed_key: "notifications.update_hed",
      dek_key: "notifications.update_dek",
      led_key: "notifications.update_led",
      icon: "fas fa-cogs",
      at: new Date(),
      async handleLedClick() {
        if (handleAccept) await handleAccept();
        window.location.reload();
      },
    },
    ..._notifications,
  ];
  notifications.set(_notifications);
  return _notifications;
}

// Provide a function to dismiss notifications.
export function dismiss(uuid) {
  if (!uuid) return false;
  _notifications = _notifications.filter(
    notification => notification.uuid !== uuid
  );
  notifications.set(_notifications);
}
