<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onDestroy } from "svelte";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { dateAndTime, isImagingSequence } from "../../helpers/formatters";
  import {
    route,
    route3,
    queryParameters,
    updateQueryParameters,
  } from "../../stores/router";
  import { scanIds } from "../../viewer-module/viewer.svelte";
  import SearchBar from "../../components/search-bar.svelte";
  import AddScanForm from "../add-scan-form.svelte";
  import { client } from "../../helpers/apollo";
  import { renderFullName, getBadgeClassName } from "../helpers";
  import { compute_comments } from "../../viewer-module/comments-panel.svelte";
  import PhaseButton from "../../components/phase-button.svelte";
  import Analysis from "./analysis.svelte";
  import { trackPageView } from "../../helpers/telemetry";
  import DeleteExamConfirmationModal from "./delete-exam-conf-modal.svelte";
  import DeleteScanConfirmationModal from "./delete-scan-conf-modal.svelte";
  import { GET_EXAM } from "../gql/get-exam.graphql";
  import { GET_ORG_NAME } from "../gql/get-org-name.graphql";
  import { GET_USER_DETAILS } from "../gql/get-user-details.graphql";
  import { updateExam } from "../gql/update-exam.graphql";

  // Analytics
  $: telemetry($route3);
  let doTrack = true;
  onDestroy(() => (doTrack = false));
  function telemetry($route3) {
    setTimeout(() => {
      if (!doTrack) return false;
      const page = $route3 ? `exam-details/${$route3}` : `exam-details`;
      trackPageView(page);
    });
  }

  // Props
  export let examId = "";
  export let organizationId = "";
  export let userId = "";
  let exam = false;
  let uploadedBy = false;
  let organization = false;
  let addScanFormEl = false;

  // Prepare the API link.
  let httpError = false;
  const api = client.watchQuery({
    query: GET_EXAM,
    variables: { examId },
  });
  const api_subscription = api.subscribe(
    ({ data }) => {
      if (!data || !data.exam) {
        exam = null;
        return false;
      }
      exam = data.exam;
      indicationInputTxt = data.exam.descriptionUser;
      getOrgFromExam(exam.organization).then(org => (organization = org));
      getUserFromExam(exam.createdBy).then(newUser => (uploadedBy = newUser));
    },
    err => (httpError = err)
  );
  onDestroy(() => api_subscription.unsubscribe());
  $: getDetails(examId);
  function getDetails() {
    httpError = false;
    api.refetch({ examId });
  }

  // Make a chronological list of comments.
  $: comments = exam ? compute_comments(exam.seriesSet) : false;

  // This property is the href for the "Open Scans" button.
  $: viewerHref = (function compute_viewerHref() {
    const newQPs = {};
    if ((!$scanIds || !$scanIds.length) && exam && exam.seriesSet)
      newQPs.scans = exam.seriesSet
        .filter(scan => isImagingSequence(scan))
        .map(scan => scan.seriesId)
        .toString();
    return `#/${formatUrl({
      $route,
      $queryParameters,
      newQPs,
      zero: "multiviewer",
      one: null,
      two: null,
      three: null,
      four: null,
    })}`;
  })();

  // Get the organization name from the org ID attached to an exam
  // TODO: We should remove this extra HTTP request when the API
  // embeds org info with the exam data
  async function getOrgFromExam({ organizationId }) {
    const resp = await client.query({
      query: GET_ORG_NAME,
      variables: { organizationId },
    });
    return resp.data.organization;
  }

  // Get the user details from the user ID attached to an exam
  // TODO: We should remove this extra HTTP request when the API
  // embeds user info with the exam data
  async function getUserFromExam({ userId }) {
    const resp = await client.query({
      query: GET_USER_DETAILS,
      variables: { userId },
    });
    return resp.data.user;
  }

  // When a scan is selected, queue it's id into the URL.
  function handleScanSelection(event, seriesId) {
    // cache the selected scans
    const selected_scans = $scanIds || [];

    // Add or remove the scan from the array.
    const isSelected = event.target.checked;
    const scans = isSelected
      ? [...selected_scans, seriesId]
      : selected_scans.filter(id => id !== seriesId);
    updateQueryParameters({ scans: scans.toString() });
  }

  // When the select all is checked or unchecked, queue/unqueue them all.
  function handleAllScanSelection(e, $queryParameters, $route, seriesSet) {
    // Select or Deselect ALL.
    const isSelected = e.target.checked;
    const scans = isSelected
      ? seriesSet
          .filter(scan => isImagingSequence(scan))
          .map(scan => scan.seriesId)
          .toString()
      : null;
    updateQueryParameters({ scans });
  }

  // Automatically show the Add Scan Form when it's shown.
  $: if (addScanFormEl)
    addScanFormEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  // Exam Editing
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let isUpdatingExamInFlight = false;
  let indicationInputTxt = "";
  let indicationEditEl;
  let newIndication = false;
  $: if (indicationEditEl) indicationEditEl.focus();
  $: isEditIndication = $queryParameters["edit-indication"];
  async function handleIndicationSave() {
    isUpdatingExamInFlight = true;
    newIndication = false;
    await client
      .mutate({
        mutation: updateExam,
        variables: {
          input: {
            examId,
            descriptionUser: indicationInputTxt,
          },
        },
        update(store, { data: { updateExam } }) {
          const data = store.readQuery({
            query: GET_EXAM,
            variables: { examId },
          });
          data.exam.descriptionUser = updateExam.exam.descriptionUser;
          store.writeQuery({ query: GET_EXAM, variables: { examId }, data });
          newIndication = updateExam.exam.descriptionUser;
        },
      })
      .catch(err => (updateExamError = err));
    isUpdatingExamInFlight = false;
  }

  // Error Handling
  let updateExamError = "";
  let updateExamErrorEl;
  $: if (updateExamErrorEl) {
    updateExamErrorEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  // Scan Deletion
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  function handleScanDeletion({ detail: { scanId } }) {
    const cacheResult = client.readQuery({
      query: GET_EXAM,
      variables: { examId },
    });
    cacheResult.exam.seriesSet = cacheResult.exam.seriesSet.filter(series => {
      return series.seriesId !== scanId;
    });
    client.writeQuery({
      query: GET_EXAM,
      variables: { examId },
      data: { ...cacheResult },
    });
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $examColor: map-get($module-colors, "exams");

  $examsColor: map-get($module-colors, "exams");
  .summary {
    border-top-color: $examsColor !important;
  }
  :global(.scan-list-item) {
    transition: border-top-color 200ms ease-out;
    &:hover,
    &.selected {
      text-decoration: none;
      color: inherit;
      border-top-color: map-get($module-colors, "scans") !important;
    }

    // Scan Type colors for rows
    @each $scan, $color in $scan-colors {
      &.#{$scan} {
        &:hover,
        &.selected {
          border-top-color: $color !important;
        }
      }
    }
  }
  .select-checkbox-cell {
    background-color: $gray-925;
  }
  .summary h1 {
    margin-top: 1px;
  }
  .series-id-cell {
    width: 240px;
    @include media-breakpoint-up(md) {
      width: 140px;
    }
    @include media-breakpoint-up(lg) {
      width: 290px;
    }
    @include media-breakpoint-up(xl) {
      width: 340px;
    }
  }
  .add-scan-btn {
    border-color: $secondary;
    &:hover {
      border-color: map-get($module-colors, "scans");
    }
  }
  .secondary-nav .nav-link {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 200ms ease-out;
    &.active {
      border-bottom-color: $examColor;
    }
  }
</style>

<section data-component="exam-details">

  <!-- Error State -->
  {#if httpError}
    <div class="alert alert-danger" role="alert">🤖 {httpError}</div>

    <!-- Uninitialized - Loading -->
  {:else if exam === false}
    <div class="py-5 text-center spinner-delayed">
      <div class="spinner-border" role="status">
        <span class="sr-only">
          {translate('notifications.loadingDotDotDot')}
        </span>
      </div>
    </div>

    <!-- Unauthorized or Can't find Exam -->
  {:else if !exam}
    <h3 class="my-5 text-center">{translate('exam_data.not_found')}</h3>

    <!-- Loaded Content -->
  {:else}
    <!-- Breadcrumbs -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item">
          <a href="#/exams">{translate('modules.exam', 99)}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {exam && exam.subject ? renderFullName(exam.subject.fullName) : examId}
          {#if exam.conductedDt}{`(${dateAndTime(exam.conductedDt)})`}{/if}
        </li>
      </ol>
    </nav>

    <div class="container-fluid fade-in">
      <div class="row">

        <!-- Summary Panel -->
        <div class="col-12 col-md-5 col-xl-4 | pb-4">
          <section
            class="summary | p-4 border rounded position-relative shadow">

            <div class="title-bgblock left-0 top-0 position-absolute w-100" />
            {#if exam.subject}
              <a
                class="unlink position-relative"
                href={`#${formatUrl({
                  $route,
                  $queryParameters,
                  zero: 'patients',
                  one: 'details',
                  two: exam.subject.examSubjectId,
                  three: null,
                })}`}>
                <h1
                  class="text-truncate"
                  title={renderFullName(exam.subject.fullName)}>
                  {renderFullName(exam.subject.fullName)}
                </h1>
              </a>
            {:else}
              <h1 class="text-truncate">
                <em>{translate('patient_data.unknown')}</em>
              </h1>
            {/if}
            <span class="badge badge-exams mr-2">
              {translate('modules.exam', 1)}
            </span>
            <div class="details-list | mt-4 mt-md-5">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2">
                {translate('overview', 1)}
              </small>
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate('exam_data.medical_records_number_short', 1)}
                </span>
                <span
                  class="col text-right text-truncate"
                  title={exam.subject.subjectId || ''}>
                  {exam.subject.subjectId || ''}
                </span>
              </div>
              <div class="updated-at | row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate('time.conducted_at')}
                </span>
                <span
                  class="col text-right text-truncate"
                  title={dateAndTime(exam.conductedDt)}>
                  {dateAndTime(exam.conductedDt)}
                </span>
              </div>
              <div
                class="referring-physician | row d-flex justify-content-between
                py-1">
                <div class="col-auto">
                  {translate('people_roles.referring_physician')}
                </div>
                <div
                  class="col text-right text-truncate"
                  title={uploadedBy.name || ''}>
                  {uploadedBy.name || ''}
                </div>
              </div>
              <div
                class="organizations | row d-flex justify-content-between py-1">
                <div class="col-auto">
                  {translate('modules.organizations', 1)}
                </div>
                <div
                  class="col text-right text-truncate"
                  title={organization.organizationName || ''}>
                  {organization.organizationName || ''}
                </div>
              </div>
            </div>

            <!-- Indication (Edit too) -->
            <div class="details-list | mt-4">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2">
                {translate('exam_data.indication_description')}
              </small>
              {#if isEditIndication}
                <form on:submit|preventDefault={handleIndicationSave}>
                  <textarea
                    class="form-control"
                    rows="3"
                    bind:this={indicationEditEl}
                    bind:value={indicationInputTxt}
                    disabled={isUpdatingExamInFlight || newIndication} />
                  <div class="d-flex pt-2">
                    <a
                      class="btn btn-sm btn-block w-100 btn-outline-secondary
                      mr-1"
                      href={`#/${formatUrl({
                        $route,
                        $queryParameters,
                        newQPs: { 'edit-indication': null },
                      })}`}>
                      <span>
                        {newIndication ? translate('form_labels.close') : translate('form_labels.cancel')}
                      </span>
                    </a>
                    <PhaseButton
                      className="btn btn-sm btn-block w-100 mt-0 ml-1"
                      startClassName="btn-primary"
                      successTxt={translate('form_labels.success')}
                      isInFlight={isUpdatingExamInFlight}
                      isSuccessful={newIndication}
                      type="submit">
                      <span>{translate('form_labels.save')}</span>
                    </PhaseButton>
                  </div>
                  {#if updateExamError}
                    <div
                      class="animated fadeIn faster alert alert-danger
                      text-monospace mt-2"
                      role="alert"
                      bind:this={updateExamErrorEl}>
                      <h4 class="alert-heading">
                        {translate('support.error_has_occurred')}
                      </h4>
                      <p>{updateExamError}</p>
                    </div>
                  {/if}
                </form>
              {:else if exam.descriptionUser}
                <p>{exam.descriptionUser}</p>
              {:else}
                <em class="small">
                  {translate('exam_data.indication_missing')}
                </em>
              {/if}
            </div>

            <!-- Ellipsis Menu -->
            <div class="dropdown position-absolute top-0 right-0">
              <button
                class="btn btn-sm btn-outline-secondary mt-3 mr-3"
                type="button"
                id="ExamOptionsMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i class="fas fa-fw fa-ellipsis-h fa-sm" />
                <span class="sr-only">{translate('more_options')}</span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="ExamOptionsMenu">
                <a
                  class="dropdown-item"
                  href={`#/${formatUrl({
                    $route,
                    $queryParameters,
                    newQPs: { 'edit-indication': true },
                  })}`}>
                  {translate('form_labels.edit_indication')}
                </a>
                <a
                  class="dropdown-item"
                  href={`#/${formatUrl({
                    $route,
                    $queryParameters,
                    newQPs: { modal: 'delete-exam' },
                  })}`}>
                  {translate('delete')}
                </a>
              </div>
            </div>
          </section>
        </div>

        <!-- Right Side -->
        <div class="col-12 col-md-7 col-xl-8 | pb-4">

          <!-- Sub Navigation -->
          <nav class="nav secondary-nav pl-3 border-bottom mb-3">
            <a
              class="nav-link unlink text-capitalize"
              class:active={!$route3 || $route3 === 'scans'}
              href={`#/${formatUrl({
                $route,
                $queryParameters,
                three: 'scans',
              })}`}>
              {translate('modules.scan', 99)}
            </a>
            <a
              class="nav-link unlink"
              class:active={$route3 === 'comments'}
              href={`#/${formatUrl({
                $route,
                $queryParameters,
                three: 'comments',
              })}`}>
              {translate('multiviewer.comment', 99)}
            </a>
          </nav>

          <div class="details">

            <!-- Sub Router -->
            {#if !$route3 || $route3 === 'scans'}
              <section class="sub-route-scans | fade-in">

                <!-- Scans Content -->
                <div class="scan-list">

                  <!-- Controls -->
                  <div class="container-fluid px-0 mb-1">
                    <div class="form-row">

                      <!-- Select All Checkbox -->
                      <div class="col-auto pb-2">
                        <label
                          class="select-checkbox-cell | border border-black
                          h-100 mb-0 px-2 py-1 d-flex align-items-center
                          border-right"
                          for="selectAllScans">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="selectAllScans"
                              on:change={e => handleAllScanSelection(e, $queryParameters, $route, exam.seriesSet)}
                              checked={exam.seriesSet.length && $scanIds && $scanIds.length === exam.seriesSet.length} />
                            <label
                              class="custom-control-label"
                              for="selectAllScans" />
                          </div>
                        </label>
                      </div>

                      <!-- Search Input -->
                      <div class="col pb-2">
                        <SearchBar />
                      </div>

                      <!-- Open Viewer Button -->
                      <div
                        class="col-12 col-sm-auto col-md-12 col-lg-auto pb-2">
                        <a
                          class="btn btn-primary btn-block text-truncate"
                          href={viewerHref}>
                          {#if $scanIds && $scanIds.length}
                            {translate('exam_data.view_selected_scans', $scanIds.length)}
                          {:else}
                            {translate('exam_data.view_all_scans', exam.seriesSet.length)}
                          {/if}
                        </a>
                      </div>
                    </div>
                  </div>

                  <!-- Scan List -->
                  {#if !exam || !exam.seriesSet || !exam.seriesSet.length}
                    <h3 class="text-center">
                      {translate('exam_data.no_scans')}
                    </h3>
                  {:else}
                    {#each exam.seriesSet.filter(isImagingSequence) as { seriesId, descriptionUser, dataUrl, rawUrl, imageRawUrl }, i (seriesId)}
                      <a
                        class="scan-list-item unlink rounded border mb-2 d-flex
                        shadow {descriptionUser
                          .split('_')
                          .join(' ')
                          .toLowerCase()}"
                        class:selected={$scanIds && $scanIds.includes(seriesId)}
                        href="#/multiviewer?scans={seriesId}">

                        <!-- Select Checkbox -->
                        <label
                          class="select-checkbox-cell mb-0 px-2 py-2 d-flex
                          align-items-center border-right"
                          for="select-scan-check-{i}">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="select-scan-check-{i}"
                              on:change={e => handleScanSelection(e, seriesId)}
                              checked={$scanIds && $scanIds.includes(seriesId)} />
                            <label
                              class="custom-control-label"
                              for="select-scan-check-{i}" />
                          </div>
                        </label>

                        <!-- Name -->
                        <div class="name-and-type px-3 py-2 flex-grow-1">
                          <h5 class="font-weight-normal text-uppercase">
                            {descriptionUser.replace(/_/g, ' ')}
                          </h5>
                          <span
                            class="{getBadgeClassName(descriptionUser)} mr-2">
                            {descriptionUser.replace(/_/g, ' ')}
                          </span>
                        </div>

                        <!-- Ellipsis Menu -->
                        <div class="pl-3 py-2">
                          <div class="dropdown h-100">
                            <button
                              class="btn viewer-header-btn close pl-3 pr-4 h-100"
                              type="button"
                              id="EllipsisMenu{seriesId}"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fas fa-fw fa-ellipsis-h fa-sm" />
                              <span class="sr-only">
                                {translate('more_options')}
                              </span>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              aria-labelledby="EllipsisMenu{seriesId}">

                              <!-- Data Download -->
                              {#if dataUrl}
                                <a class="dropdown-item" href={dataUrl}>
                                  {translate('exam_data.download_image_data')}
                                </a>
                              {:else}
                                <span
                                  class="dropdown-item disabled"
                                  tabindex="-1"
                                  aria-disabled="true">
                                  {translate('exam_data.download_image_data')}
                                </span>
                              {/if}

                              <!-- Deletion -->
                              <a
                                class="dropdown-item"
                                href={`#${formatUrl({
                                  $route,
                                  $queryParameters,
                                  newQPs: {
                                    modal: `delete-scan-${seriesId}`,
                                    delIndication: descriptionUser,
                                  },
                                })}`}>
                                {translate('delete')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                    {/each}
                  {/if}

                  <!-- Upload Scan Button / Form -->
                  {#if !$queryParameters['add-scan']}
                    <a
                      class="add-scan-btn | btn btn-block btn-sm
                      btn-outline-scans text-light"
                      href={`#/${formatUrl({
                        $route,
                        $queryParameters,
                        newQPs: { 'add-scan': true },
                      })}`}>
                      <span>{translate('form_labels.create_scan')}</span>
                    </a>
                  {:else}
                    <span bind:this={addScanFormEl}>
                      <AddScanForm
                        {exam}
                        closeUrl={`#/${formatUrl({
                          $route,
                          $queryParameters,
                          newQPs: { 'add-scan': null },
                        })}`}
                        on:uploaded={() => api.refetch({ examId })} />
                    </span>
                  {/if}
                </div>
              </section>
            {:else if $route3 === 'comments'}
              <Analysis
                className="fade-in"
                {comments}
                scanId={exam.seriesSet.length ? exam.seriesSet[0].seriesId : ''} />
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}

  <!-- Exam Deletion Confirmation Modal -->
  {#if $queryParameters.modal === 'delete-exam'}
    <DeleteExamConfirmationModal {examId} />
  {/if}

  <!-- Scan Deletion Confirmation Modal -->
  {#if $queryParameters.modal && $queryParameters.modal.includes('delete-scan')}
    <DeleteScanConfirmationModal
      scanId={$queryParameters.modal.slice(12)}
      indication={$queryParameters.delIndication}
      on:deleted={handleScanDeletion} />
  {/if}
</section>
