<script>
  import { route1, route2 } from "../stores/router";
  import ExamHome from "./exam-home.svelte";
  import ExamDetails from "./exam-details/exam-details.svelte";
</script>

<style type="text/scss">
  @import "bootstrap/variables";
  $examsColor: map-get($module-colors, "exams");
  .nav-link {
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 200ms ease-out;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &.active {
      border-bottom-color: $examsColor;
    }
  }
</style>

<section data-component="exam-module" class="h-100">

  <!-- Sub Routing -->
  {#if $route1 === 'details'}
    <ExamDetails examId={$route2} />
  {:else if !$route1}
    <ExamHome />
  {/if}
</section>
