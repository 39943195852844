<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import VolumeViewer from "volume-viewer"; //eslint-disable-line import/no-unresolved
  import { createEventDispatcher } from "svelte";
  import makeUUID from "../helpers/uuid";

  // External Props
  export let brightness = false;
  export let contrast = false;
  export let globalScrollZ = false;
  export let globalScrollY = false;
  export let globalScrollX = false;
  export let globalPanX = false;
  export let globalPanY = false;
  export let globalRotation = false;
  export let scrollZ = false;
  export let scrollY = false;
  export let scrollX = false;
  export let panX = false;
  export let panY = false;
  export let rotation = false;
  export let autoBrightness = false;
  export let autoContrast = false;
  export let viewMode = false;
  export let clipOffset = false;
  export let volumeViewer = false;
  export let zoom = false;

  // Internals
  const roundMultiplier = 10000;
  const dispatch = createEventDispatcher();
  const uuid = makeUUID();
  $: isAutoScroll = scrollZ === false;
  $: isAutoPan = panX === false;
  $: isAutoRotation = !rotation;
  $: computedBrightness = (brightness === false
    ? autoBrightness
    : brightness
  ).toFixed(2);
  $: computedContrast = (contrast === false ? autoContrast : contrast).toFixed(
    2
  );

  // These are "automatic" if they're not set through the URL (via brightness
  // prop) or if they are specifically set to the automatic value.
  $: isAutoBrightness =
    brightness === false ||
    Math.round(brightness * roundMultiplier) ===
      Math.round(autoBrightness * roundMultiplier);
  $: isAutoContrast =
    contrast === false ||
    Math.round(contrast * roundMultiplier) ===
      Math.round(autoContrast * roundMultiplier);

  // Track Slice and Slice Count
  let sliceCount;
  let sliceIdx;

  $: if (volumeViewer) {
    updateSliceUI(
      rotation,
      globalRotation,
      scrollZ,
      globalScrollZ,
      globalScrollY,
      globalScrollX
    );
  }

  function updateSliceUI() {
    const { slice, count } = volumeViewer.getFacingPlaneSlice();
    sliceIdx = slice;
    sliceCount = count;

    // Update the URL via scrollX, scrollY, & scrollZ positions!
    const { px, py, pz } = volumeViewer.getPlaneOffset();

    // Update global or local scroll positions.
    if (scrollZ === false) globalScrollZ = pz;
    else scrollZ = pz;

    if (scrollY === false) globalScrollY = py;
    else scrollY = py;

    if (scrollX === false) globalScrollX = px;
    else scrollX = px;
  }

  // Calculate Zoom
  $: zoomRange = volumeViewer ? volumeViewer.getZoomLinearRange() : false;

  // DOM Event Handlers
  function handleBrightnessInputChange({ target: { valueAsNumber } }) {
    brightness = valueAsNumber;
  }
  function handleContrastInputChange({ target: { valueAsNumber } }) {
    contrast = valueAsNumber;
  }
  function handleClipPlaneInputChange({ target: { valueAsNumber } }) {
    clipOffset = valueAsNumber;
  }
  function handleScrollRangeChange({ target: { valueAsNumber } }) {
    if (volumeViewer) {
      volumeViewer.setFacingPlaneSlice(valueAsNumber);
      updateSliceUI();
    }
  }
  function handleZoomInputChange({ target: { valueAsNumber } }) {
    zoom = VolumeViewer.zoomLinearToScaled(valueAsNumber);
  }
  function toggleScrollLock() {
    if (isAutoScroll) {
      scrollZ = globalScrollZ;
      scrollY = globalScrollY;
      scrollX = globalScrollX;
    } else {
      scrollZ = scrollY = scrollX = false;
    }
  }
  function togglePanLock() {
    if (isAutoPan) {
      panX = globalPanX;
      panY = globalPanY;
    } else {
      panX = panY = false;
    }
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "ui/viewer-module/vars";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $controlWidth: 55px;
  .controls-overlay {
    z-index: 1;
    .control-hint {
      display: none;
      line-height: 1rem;
      max-width: 9rem;
    }
  }
  .mobile-hover-enabler {
    transition: height 0ms linear 10ms;
  }
  .icon {
    width: 2.75rem;
    background-color: $viewer-controls-bg;
    @include media-breakpoint-up(sm) {
      width: 3.25rem;
    }
  }
  .font-size-10 {
    font-size: 0.625rem;
    @include media-breakpoint-up(xl) {
      font-size: 0.75rem;
    }
  }
  .font-size-12 {
    font-size: 0.75rem;
  }

  // Show the controls when hovered over. Non-pseudo also for automated testing.
  :global(.controls-overlay) {
    &:hover,
    &.hover {
      .control-hint {
        display: block;
      }
      .mobile-hover-enabler {
        height: 0 !important;
      }
      + .blur-click-enabler {
        display: block !important;
      }
      .vis-on-hover {
        opacity: 1;
      }
      .hint-theres-more {
        display: none;
      }
    }
  }

  .btn {
    font-size: 0.75rem;
    padding-left: 2px;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: $input-btn-font-size;
      padding-left: $input-btn-padding-x;
    }
  }
  .fa-link,
  .fa-unlink {
    font-size: 0.6em;
  }

  .vis-on-hover {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
</style>

<div
  class="controls-overlay | position-absolute bottom-0 left-0 pb-0 text-center">

  <!-- Rotation -->
  <div class="d-flex align-items-center my-md-2 vis-on-hover">
    <button
      on:click={() => (rotation = isAutoRotation ? { ...globalRotation } : false)}
      class="btn unbutton"
      data-cy="slice-btn">
      <div class="icon text-truncate position-relative">
        <i class="fas fa-fw fa-lg fa-cube | d-block mx-auto my-1" />
        <span class="text-truncate d-block font-size-10 mx-auto">Rotation</span>
        <i
          class="fas fa-fw fa-xs position-absolute top-0 left-0 {isAutoRotation ? 'fa-link' : 'fa-unlink'}" />
      </div>
    </button>
    <div class="control-hint | ml--2 text-left | animated fadeIn faster">
      <button on:click={togglePanLock} class="btn unbutton" data-cy="pan-btn">
        <div class="icon text-truncate position-relative">
          <i class="fas fa-fw fa-lg fa-expand-alt | d-block mx-auto my-1" />
          <span class="text-truncate d-block font-size-10 mx-auto">Pan</span>
          <i
            class="fas fa-fw fa-xs position-absolute top-0 left-0 {isAutoPan ? 'fa-link' : 'fa-unlink'}" />
        </div>
      </button>
    </div>
  </div>

  <!-- Zoom -->
  <div class="d-flex align-items-center my-md-2 vis-on-hover">
    <button
      class="btn unbutton"
      on:click={() => (zoom = false)}
      data-cy="zoom-btn">
      <div class="icon position-relative">
        <i class="fas fa-fw fa-lg fa-search-plus | d-block mx-auto my-1" />
        <span class="text-truncate d-block font-size-10 mx-auto">
          {zoom ? zoom.toFixed(2) : 1}
        </span>
        <span
          class="font-size-12 | position-absolute top-0 left-0 mt--2 pl-1 |
          animated fadeIn faster"
          class:d-none={zoom !== false}>
          {translate('acronym_automatic')}
        </span>
      </div>
    </button>
    <div class="control-hint | ml--2 text-left | animated fadeIn faster">
      <span>{translate('multiviewer.zoom')}</span>
      <input
        type="range"
        class="custom-range"
        id={`dom-zooom-${uuid}`}
        min={zoomRange.maxValue}
        max={zoomRange.minValue}
        step="0.00001"
        on:change={handleZoomInputChange}
        on:input={handleZoomInputChange}
        value={VolumeViewer.zoomScaledToLinear(zoom)}
        data-cy="zoom-ctrl" />
    </div>
  </div>

  <!-- Ellipsis to hint that there's more controls -->
  <div class="position-relative hint-theres-more">
    <div class="position-absolute bottom-0 ml-2 ml-md-3 pl-1 pl-sm-2 mb-2">
      <i class="fas fa-fw fa-ellipsis-h" />
    </div>
  </div>

  <!-- Clip Button (3D mode only) -->
  {#if viewMode === '3d'}
    <div class="d-flex align-items-center my-sm-2 | animated fadeIn faster">
      <button
        class="btn unbutton"
        on:click={e => dispatch('reclip', e)}
        data-cy="clip-btn">
        <div class="icon text-truncate py-1">
          <i class="fas fa-fw fa-lg fa-hand-scissors" />
        </div>
      </button>
      <div class="control-hint | ml--2 text-left | animated fadeIn faster">
        <span>{translate('multiviewer.clip')}</span>
        {#if clipOffset !== false}
          <input
            type="range"
            class="custom-range"
            id={`dom-clip-${uuid}`}
            min="-1"
            max="1"
            step="0.00001"
            on:change={handleClipPlaneInputChange}
            on:input={handleClipPlaneInputChange}
            value={clipOffset}
            data-cy="clip-range" />
        {/if}
      </div>
    </div>
  {/if}

  <!-- Slice Lock Button (not in 3D) -->
  {#if viewMode !== '3d'}
    <div class="d-flex align-items-center my-md-2 | animated fadeIn faster">
      <button
        class="btn unbutton"
        on:click={toggleScrollLock}
        data-cy="slice-btn">
        <div class="icon text-truncate position-relative">
          <i class="fas fa-fw fa-lg fa-layer-group | d-block mx-auto my-1" />
          <span class="text-truncate d-block font-size-10 mx-auto">
            {sliceIdx} / {sliceCount}
          </span>
          <i
            class="fas fa-fw fa-xs position-absolute top-0 left-0 {isAutoScroll ? 'fa-link' : 'fa-unlink'}" />
        </div>
      </button>
      <div class="control-hint | ml--2 text-left | animated fadeIn faster">
        <span>{isAutoScroll ? translate('unlock') : translate('lock')}</span>
        <input
          type="range"
          class="custom-range"
          id={`slice-${uuid}`}
          min="1"
          max={sliceCount}
          step="0.00001"
          on:change={handleScrollRangeChange}
          on:input={handleScrollRangeChange}
          value={sliceIdx}
          data-cy="slice-ctrl" />
      </div>
    </div>
  {/if}

  <!-- Brightness -->
  <div class="d-flex align-items-center my-md-2">
    <button
      class="btn unbutton"
      on:click={() => (brightness = false)}
      data-cy="brightness-btn">
      <div class="icon position-relative">
        <i class="fas fa-fw fa-lg fa-sun | d-block mx-auto my-1" />
        <span class="text-truncate d-block font-size-10 mx-auto">
          {computedBrightness}
        </span>
        <span
          class="font-size-12 | position-absolute top-0 left-0 mt--2 pl-1 |
          animated fadeIn faster"
          class:d-none={!isAutoBrightness}>
          {translate('acronym_automatic')}
        </span>
      </div>
    </button>
    <div class="control-hint | ml--2 text-left | animated fadeIn faster">
      <span>{translate('multiviewer.brightness')}</span>
      <input
        type="range"
        class="custom-range"
        id={`dom-bri-${uuid}`}
        min="0.01"
        max="1"
        step="0.00001"
        on:change={handleBrightnessInputChange}
        on:input={handleBrightnessInputChange}
        value={computedBrightness}
        data-cy="brightness-ctrl" />
    </div>
  </div>

  <!-- Contrast -->
  <div class="d-flex align-items-center my-md-2">
    <button
      class="btn unbutton"
      on:click={() => (contrast = false)}
      data-cy="contrast-btn">
      <div class="icon position-relative">
        <i class="fas fa-fw fa-lg fa-moon | d-block mx-auto my-1" />
        <span class="text-truncate d-block font-size-10 mx-auto">
          {computedContrast}
        </span>
        <span
          class="font-size-12 | position-absolute top-0 left-0 mt--2 pl-1 |
          animated fadeIn faster"
          class:d-none={!isAutoContrast}>
          {translate('acronym_automatic')}
        </span>
      </div>
    </button>
    <div class="control-hint | ml--2 text-left | animated fadeIn faster">
      <span>{translate('multiviewer.contrast')}</span>
      <input
        type="range"
        class="custom-range"
        id={`contrast-${uuid}`}
        min="0.01"
        max="1"
        step="0.00001"
        on:change={handleContrastInputChange}
        on:input={handleContrastInputChange}
        value={computedContrast}
        data-cy="contrast-ctrl" />
    </div>
  </div>

  <!-- Mobile Hover/Click Guard -->
  <div
    class="mobile-hover-enabler | position-absolute top-0 left-0 w-100 h-100" />
</div>
<div
  class="blur-click-enabler | position-absolute top-0 left-0 w-100 h-100 d-none" />
