<script context="module">
  // Link to send feedback to dev team.
  export const supportEmailHref = `mailto:cloud-support@hyperfine-research.com?Subject=User%20Feedback%20-%20Indigen%20v${window.indigen.version}`;
</script>

<script>
  // Import our external dependencies.
  import { translate, languages, loadLocale } from "i18n"; //eslint-disable-line import/no-unresolved
  import jQuery from "jquery";
  import { onDestroy, tick } from "svelte";
  import { route0, refreshRoute } from "../stores/router";
  import {
    authenticate,
    doRegisterUser,
    getPasswordValidations,
  } from "../stores/user";

  // Cache some datas
  const passwordValidations = getPasswordValidations();

  // Props
  let email = "";
  let password = "";
  let passwordConfirm = "";
  let name = "";
  let loading = false;
  let errorMessage = false;
  let invite = false;
  let successEl = false;
  let errorEl = false;

  // Track what view-mode they're in.
  $: isCreateAccount = $route0 === "create-account";

  // Password Validity Tooltip : Use BS Tooltip Plugin
  function destroyTooltips() {
    jQuery("[data-toggle='tooltip']").tooltip("dispose");
  }
  $: passwordIsValid = !passwordValidations.find(
    ({ check }) => !check(password)
  );
  $: passwordValidityPopoverHTML = `
    <div class="text-left">
      ${passwordValidations.reduce((html, { check, ruleText }) => {
        return `${html}${
          check(password)
            ? "<i class='fa-fw fas fa-check-circle text-success'></i>"
            : "<i class='fa-fw fas fa-exclamation-circle text-danger'></i>"
        }<span>${ruleText}</span><br>`;
      }, "")}
    </div>
  `;
  $: if (
    passwordValidityPopoverHTML ||
    passwordConfirmTooltipText ||
    isCreateAccount
  ) {
    destroyTooltips();
    tick().then(() => {
      jQuery("[data-toggle='tooltip']").tooltip();
    });
  }
  onDestroy(destroyTooltips);

  // Password Confirmation
  $: passwordsMatch = passwordConfirm && password === passwordConfirm;
  $: passwordConfirmTooltipText = translate(
    passwordsMatch
      ? "validation.passwords_match"
      : "validation.passwords_no_match"
  );

  // Form Submission
  async function handleSubmit() {
    // Update the UI to show we're in-flight.
    loading = true;
    errorMessage = false;

    // Reference the appropriate HTTP request function
    const authenticateOrSignUp = isCreateAccount
      ? doRegisterUser
      : authenticate;

    // Make the HTTP request and update the DOM when it responds.
    invite = await authenticateOrSignUp({ email, password, name }).catch(
      err => !(errorMessage = err)
    );

    // Update DOM that we're finished.
    loading = false;

    // stop execution if there's an error.
    if (errorMessage) return;

    // redirect them from the login page
    if ($route0 === "login") {
      window.location = `#/`;
      refreshRoute();
    }
  }

  // Scroll alerts into view when they appear.
  $: if (successEl)
    successEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  $: if (errorEl)
    errorEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

  // DOM Event Handlers
  function handleEmailInputChange({ target: { value } }) {
    email = value;
  }
  function handlePasswordInputChange({ target: { value } }) {
    password = value;
  }
  function handlePasswordConfInputChange({ target: { value } }) {
    passwordConfirm = value;
  }
  function handleNameInputChange({ target: { value } }) {
    name = value;
  }
</script>

<style>
  .skinny-pete {
    max-width: 800px;
  }
  .logo {
    max-width: 90px;
  }
</style>

<div class="h-100 d-flex flex-column" data-component="preauth">

  <!-- Login -->
  <section class="pt-1 pb-5 my-auto">
    <div class="mx-auto container-fluid skinny-pete">
      <div class="row">
        <a class="col-12 col-sm-2 text-center pb-5 pt-3" href="#/">
          <img
            class="logo"
            src="images/h-logo.svg"
            alt={translate('app_name')} />
        </a>

        <form
          class="col-12 offset-sm-1 col-sm-9"
          on:submit|preventDefault={handleSubmit}>
          <div class="form-group">
            <label for="InputEmail">{translate('form_labels.email')}</label>
            <input
              on:keyup={handleEmailInputChange}
              on:change={handleEmailInputChange}
              type="email"
              class="form-control"
              id="InputEmail"
              placeholder={translate('form_placeholders.email')}
              required
              disabled={invite || loading} />
          </div>
          <div class="form-group">
            <label for="InputPwd">
              <span>{translate('form_labels.password')}</span>
              {#if isCreateAccount}
                <button
                  type="button"
                  class="fas btn {passwordIsValid ? 'fa-check-circle text-success' : 'fa-exclamation-circle text-danger'}"
                  data-toggle="tooltip"
                  data-html="true"
                  title={passwordValidityPopoverHTML} />
              {/if}
            </label>
            <input
              on:keyup={handlePasswordInputChange}
              on:change={handlePasswordInputChange}
              type="password"
              class="form-control"
              id="InputPwd"
              placeholder={translate('form_placeholders.password')}
              required
              disabled={invite || loading} />
          </div>

          <!-- Password Confirmation & Name Fields for Create Account -->
          {#if isCreateAccount}
            <div class="form-group">
              <label for="InputPwdConf">
                <span>{translate('form_labels.password_confirm')}</span>
                <button
                  type="button"
                  class="fas btn {passwordsMatch ? 'fa-check-circle text-success' : 'fa-exclamation-circle text-danger'}"
                  data-toggle="tooltip"
                  title={passwordConfirmTooltipText} />
              </label>
              <input
                on:keyup={handlePasswordConfInputChange}
                on:change={handlePasswordConfInputChange}
                type="password"
                class="form-control"
                id="InputPwdConf"
                placeholder={translate('form_placeholders.password_confirm')}
                required
                disabled={invite || loading} />
            </div>
            <div class="form-group">
              <label for="InputName">{translate('form_labels.name')}</label>
              <input
                on:keyup={handleNameInputChange}
                on:change={handleNameInputChange}
                type="text"
                class="form-control"
                id="InputName"
                placeholder={translate('form_placeholders.name')}
                required
                disabled={invite || loading} />
            </div>
          {/if}

          <!-- Submission and Buttons -->
          <div class="d-flex justify-content-between">
            <div>
              <button
                type="submit"
                class="btn btn-primary float-left"
                disabled={invite || loading || (isCreateAccount && (!passwordsMatch || !passwordIsValid))}>
                {#if isCreateAccount}
                  {translate('form_labels.create_account')}
                {:else}{translate('form_labels.log_in')}{/if}
              </button>
              {#if loading}
                <div
                  class="position-relative float-left ml-3 mt-1 spinner-delayed">
                  <div
                    class="spinner-border position-absolute top-0"
                    role="status">
                    <span class="sr-only">
                      {translate('notifications.loadingDotDotDot')}
                    </span>
                  </div>
                </div>
              {/if}
            </div>
            <div>
              {#if isCreateAccount}
                <a href="#/login" class="btn btn-link mr--2">
                  {translate('form_labels.log_in')}
                </a>
              {:else}
                <a href="#/create-account" class="btn btn-link mr--2">
                  {translate('form_labels.create_account')}
                </a>
              {/if}
            </div>
          </div>
        </form>

        <!-- Show success messaging for Create Account -->
        {#if invite}
          <div
            class="col-12 pt-5 text-monospace | animated fadeIn"
            bind:this={successEl}>
            <div class="alert alert-success" role="alert">
              <h4 class="alert-heading">
                {translate('support.great_news', invite)}
              </h4>
              <p>{translate('support.invite_success', invite)}</p>
            </div>
          </div>
        {/if}

        <!-- Error Info -->
        {#if errorMessage}
          <div
            class="col-12 pt-5 text-monospace | animated fadeIn"
            bind:this={errorEl}>
            <div class="alert alert-danger" role="alert">
              <h4 class="alert-heading">
                {translate('support.error_has_occurred')}
              </h4>
              <p>{errorMessage}</p>
            </div>
          </div>
        {/if}

      </div>
    </div>
  </section>

  <!-- Footer - Languages & Useage Policies -->
  <footer class="mt-auto container-fluid py-1 border-top">
    <div class="row align-items-center">
      <div class="col-auto mr-auto">
        {#each languages as { name, id } (id)}
          <button
            class="btn btn-link btn-sm"
            on:click={event => loadLocale(id)}>
            <span>{name}</span>
          </button>
        {/each}
      </div>
      <div class="col-12 col-lg text-lg-center pl-4 text-muted small">
        {@html translate('policies')}
      </div>
      <div class="col-12 col-lg-auto">
        <a href={supportEmailHref} class="btn btn-link btn-sm">
          {translate('form_labels.report_issue')}
        </a>
      </div>
    </div>
  </footer>
</div>
