// Import our external dependencies.
import { readable } from "svelte/store";

// Settings
const interval_milliseconds = 10000;

// Create and export a global moment tracker with fixed granularity
export default readable(new Date(), set => {
  const interval = setInterval(() => {
    set(new Date());
  }, interval_milliseconds);
  return () => clearInterval(interval);
});
