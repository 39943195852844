<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved

  // External Props
  export let className = "";
  export let startClassName = "primary";
  export let isInFlight = false;
  export let isSuccessful = false;
  export let successTxt = false;
  export let isFailed = false;
  export let failTxt = "";
  export let type = "submit";
  export let elThis = null;
</script>

<style>
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
</style>

<button
  {type}
  class="btn {isSuccessful || isFailed ? '' : startClassName}
  {className}
  {isFailed ? 'btn-danger' : ''}"
  disabled={isInFlight || isSuccessful}
  class:btn-success={isSuccessful}
  bind:this={elThis}
  on:click>
  <div class="d-inline-block position-relative">

    <!-- Waiting, In-Flight, or Failed -->
    {#if isFailed}
      <span>{failTxt}</span>
    {:else if isSuccessful}
      <span>{successTxt}</span>
    {:else}
      <slot />
    {/if}

    <!-- Loading Spinner -->
    {#if isInFlight}
      <div class="position-absolute top-0 right-0 mr--5 | animated fadeIn">
        <div class="spinner-border small" role="status">
          <span class="sr-only">{translate('loadingDotDotDot')}</span>
        </div>
      </div>
    {/if}
  </div>
</button>
