<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { client } from "../helpers/apollo";
  import { trackPageView } from "../helpers/telemetry";
  import { GET_ORGANIZATIONS } from "./graphs.graphql";

  // Analytics
  trackPageView("organizations-home");

  // Get the Organization from the API.
  let organizations = false;
  (async function GetOrganizationsFromAPI() {
    const resp = await client.query({ query: GET_ORGANIZATIONS });
    const { organization } = resp.data.viewer;
    organizations = [organization];
  })();
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $organizationsColor: map-get($module-colors, "organizations");

  .organization-list-item {
    transition: border-top-color 200ms ease-out;
    background-color: $body-bg;
    display: grid;
    grid-template-columns: 1fr 150px;
    grid-column-gap: map-get($spacers, 3);
    padding: map-get($spacers, 2) map-get($spacers, 3) map-get($spacers, 3)
      map-get($spacers, 3);

    &:hover {
      text-decoration: none;
      color: inherit;
      border-top-color: $organizationsColor !important;
    }

    // Responsive Layout
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 200px 110px;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 200px 120px 80px 90px;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 320px 140px 90px 110px;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 390px 210px 110px 110px;
    }
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 1fr 430px 390px 220px 170px;
    }
  }

  .load-more-spinner {
    top: -4px;
  }
</style>

<section data-component="organization-home">
  <main class="p-3">
    <h1 class="mb-3">{translate('modules.organizations', 99)}</h1>

    <section class="organization-list">

      <!-- List of Organizations -->
      <div class="organizations-list-api-call | position-relative">

        <!-- Loading... -->
        {#if organizations === false}
          <div
            class="d-flex justify-content-center align-items-center bg-spinner
            my-4">
            <div class="spinner-border" role="status">
              <span class="sr-only">{translate('loadingDotDotDot')}</span>
            </div>
          </div>

          <!-- Data Received -->
        {:else}
          <div>

            <!-- No Results -->
            {#if !organizations || !organizations.length}
              <h3 class="text-center mt-5">
                {translate('organization_data.none_found', 99)}
              </h3>

              <!-- Results Found -->
            {:else}
              <!-- Organizations List -->
              {#each organizations as organization (organization.organizationId)}
                <a
                  class="organization-list-item | unlink rounded border mb-2
                  shadow w-100"
                  href="#/organizations/details/{organization.organizationId}">

                  <!-- Name -->
                  <div>
                    <h5 class="font-weight-normal">
                      {#if organization.organizationName}
                        {organization.organizationName}
                      {:else}
                        <em>{translate('organization_data.unknown')}</em>
                      {/if}
                    </h5>
                    <span class="badge badge-organizations mr-2">
                      {translate('modules.organizations', 1)}
                    </span>
                  </div>

                  <!-- Unique Identifier -->
                  <div class="text-truncate">
                    <small class="text-muted letter-space-1">
                      {translate('organization_data.id', 1)}
                    </small>
                    <br />
                    <span>{organization.organizationId}</span>
                  </div>

                  <!-- Address -->
                  <div class="text-truncate d-none d-sm-block">
                    <small class="text-muted letter-space-1">
                      {translate('organization_data.address', 1)}
                    </small>
                    <br />
                    {#if organization.address}
                      <a
                        class="unlink"
                        href={`https://www.google.com/maps/@?api=1&map_action=map`}>
                        {organization.address || ''}
                      </a>
                    {:else}
                      <em>{translate('unknown')}</em>
                    {/if}
                  </div>

                  <!-- Member Count -->
                  <div class="text-truncate d-none d-md-block">
                    <small class="text-muted letter-space-1 text-capitalize">
                      {translate('organization_data.member', 99)}
                    </small>
                    <br />
                    <a
                      class="unlink"
                      href={`#/organizations/details/${organization.organizationId}/staff`}>
                      {@html organization.users.edges.length || `<em>${translate('unknown')}</em>`}
                    </a>
                  </div>

                  <!-- Exam Count -->
                  <div class="text-truncate d-none d-md-block">
                    <small class="text-muted letter-space-1 text-capitalize">
                      {translate('modules.exam', 99)}
                    </small>
                    <br />
                    <a
                      class="unlink"
                      href={`#/organizations/details/${organization.organizationId}/exams`}>
                      {@html organization.examCount || `<em>${translate('unknown')}</em>`}
                    </a>
                  </div>
                </a>
              {/each}
            {/if}
          </div>
        {/if}
      </div>
    </section>

  </main>
</section>
