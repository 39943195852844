// Import our external dependencies.
import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
import { formatUrl } from "mcgregor-utils/url-utils";
import { renderFullName, getBadgeClassName } from "../exam-module/helpers";

// Helper Functions
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -|

export function renderMetaData(translate, scan, exam) {
  return `
  <div class="d-flex justify-content-between">
    <span title="${translate("patient_data.name")}">
      ${
        exam && exam.subject
          ? renderFullName(exam.subject.fullName)
          : translate("patient_data.unknown")
      }
    </span>
    <span title="${translate("exam_data.scanner_model")}">
      ${translate("exam_data.scanner_model_unknown")}
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span title="${translate("exam_data.medical_records_number")}">
      ${
        exam && exam.subject
          ? exam.subject.subjectId
          : translate("exam_data.medical_records_number_unknown")
      }
    </span>
    <span title="${translate("exam_data.scanner_field_strength")}">
      ${translate("exam_data.scanner_field_strength_unknown")}
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span title="${translate("patient_data.dob", 1)}">
      ${
        exam && exam.subject
          ? exam.subject.birthDate
          : translate("patient_data.dob_unknown")
      }
    </span>
    <span title="${translate("exam_data.sequence_name")}">
      ${translate("exam_data.sequence_name_unknown")}
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span title="${translate("patient_data.gender", 1)}">
      ${translate("patient_data.gender_unknown")}
    </span>
    <span title="${translate("exam_data.contrast_type")}">
      ${translate("exam_data.contrast_type_unknown")}
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span title="${translate("exam_data.created_at")}">
      ${
        exam && exam.conductedDt
          ? exam.conductedDt
          : translate("exam_data.created_at_unknown")
      }
    </span>
    <span title="${translate("exam_data.sequence_parameters")}">
      ${translate("exam_data.sequence_parameters_unknown")}
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span title="${translate("exam_data.facility")}">
      ${translate("exam_data.facility_unknown")}
    </span>
    <span title="${translate("exam_data.sequence_duration")}">
      ${translate("exam_data.sequence_duration_unknown")}
    </span>
  </div>
  `;
}

export function renderScanToAdd(
  scanId,
  scan,
  $route,
  $queryParameters,
  $scansToAdd
) {
  const removeMeURL = formatUrl({
    $route,
    $queryParameters,
    newQPs: {
      "scans-to-add": $scansToAdd.filter(existingId => scanId !== existingId),
    },
  });
  return `
  <div class="d-flex justify-content-between align-items-center position-relative">
    ${
      scan
        ? `
      <div class="text-left">
        <span class="d-block text-truncate">${renderFullName(
          scan.exam.subject.fullName
        )}</span>
        <span class="${getBadgeClassName(
          scan.descriptionUser
        )}">${scan.descriptionUser.replace(/_/g, " ")}</span>
      </div>
    `
        : `
      <div class="spinner-border text-scans mx-auto" role="status">
        <span class="sr-only">${translate(
          "notifications.loadingDotDotDot"
        )}</span>
      </div>
    `
    }
    <a
      class="close stretched-link pl-3"
      aria-label="Remove"
      href='#/${removeMeURL}'>
      <span aria-hidden="true">×</span>
    </a>
  </div>
  `;
}

export function getScanClassName(scan) {
  if (!scan || !scan.descriptionUser) return "";
  return scan.descriptionUser.toLowerCase().replace(/_/g, " ");
}
