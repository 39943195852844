<script>
  // Import our external dependencies.
  import { onDestroy } from "svelte";
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { queryParameters, updateQueryParameters } from "../stores/router";
  import SearchBar from "../components/search-bar.svelte";
  import DefaultExamListItem from "./exam-list-item.svelte";
  import { client } from "../helpers/apollo";
  import { GET_EXAMS } from "./gql/get-exams.graphql";

  // External Props
  export let ExamListItem = DefaultExamListItem;
  export let filter = "";
  export let perPage = 10;
  export let searchUrlKey = "search";
  export let triggerRefresh = 0;

  // Prepare our API call and Exam data.
  let isLoading = true;
  let exams = null;
  let nextPageCursor = null;
  let httpError = false;
  const api = client.watchQuery({
    query: GET_EXAMS,
    variables: {
      perPage,
      cursor: null,
      search: $queryParameters[searchUrlKey] || null,
    },
  });
  const api_subscription = api.subscribe(
    ({ data, loading }) => {
      if (!data || !data.viewer || !data.viewer.exams) return;
      const { edges, pageInfo } = data.viewer.exams;
      exams = edges.map(edge => edge.node);
      isLoading = loading;
      nextPageCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    },
    err => {
      httpError = err;
      isLoading = false;
    }
  );
  onDestroy(() => api_subscription.unsubscribe());

  // Search - Replace URL which triggers $queryParameter reactivity.
  $: searchTxt = $queryParameters[searchUrlKey] || "";
  function handleSearchTxtChange({ detail }) {
    updateQueryParameters({ [searchUrlKey]: detail });
  }
  $: search = $queryParameters[searchUrlKey];

  // Execute API Call - Search Change
  $: executeApiCall(filter, search, triggerRefresh);
  function executeApiCall(filter, search) {
    httpError = false;
    const filterAndSearch = [];
    if (filter) filterAndSearch.push(filter);
    if (search) filterAndSearch.push(search);
    api.refetch({
      perPage,
      search: filterAndSearch.join(" ") || null,
      cursor: null,
    });
  }

  // Execute API Call - Load More
  let isLoadingMore = false;
  function handleLoadMoreClick() {
    isLoadingMore = true;
    api.fetchMore({
      variables: { cursor: nextPageCursor },
      updateQuery(prev, { fetchMoreResult }) {
        isLoadingMore = false;
        if (!fetchMoreResult) return prev;
        fetchMoreResult.viewer.exams.edges = [
          ...prev.viewer.exams.edges,
          ...fetchMoreResult.viewer.exams.edges,
        ];
        return fetchMoreResult;
      },
    });
  }
</script>

<style>
  .load-more-spinner {
    top: -4px;
  }
</style>

<section data-component="exam-list">

  <!-- Search & Filter Controls -->
  <div class="pb-3 position-relative">
    <SearchBar
      {searchTxt}
      placeholder={translate('exam_data.search_exam', 99)}
      on:searchTxtChange={handleSearchTxtChange} />
    <slot />
  </div>

  <!-- List of Exams -->
  <div class="exams-list-api-call | position-relative">

    <!-- Error State -->
    {#if httpError}
      <div class="alert alert-danger" role="alert">🤖 {httpError}</div>

      <!-- Loading... -->
    {:else if isLoading}
      <div
        class="d-flex justify-content-center align-items-center bg-spinner my-4">
        <div class="spinner-border" role="status">
          <span class="sr-only">{translate('loadingDotDotDot')}</span>
        </div>
      </div>

      <!-- Data Received -->
    {:else}
      <div>

        <!-- No Results -->
        {#if !exams || !exams.length}
          <h3 class="text-center mt-5">No Exams Found</h3>

          <!-- Results Found -->
        {:else}
          {#each exams as exam (exam.examId)}
            <svelte:component this={ExamListItem} {exam} />
          {/each}

          <!-- Load More Button -->
          {#if nextPageCursor}
            <div class="text-center py-3">
              <button
                class="btn btn-secondary"
                on:click={handleLoadMoreClick}
                disabled={isLoadingMore}>
                <span>Load More</span>
              </button>
              <div class="d-inline position-relative ml-2">
                {#if isLoadingMore}
                  <div class="load-more-spinner | position-absolute left-0">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">
                        {translate('loadingDotDotDot')}
                      </span>
                    </div>
                  </div>
                {/if}
              </div>
            </div>
          {/if}
        {/if}
      </div>
    {/if}
  </div>
</section>
