<script context="module">
  export function compute_comments(scans) {
    if (!scans || !scans.reduce) return false;
    const raw_comments = scans.reduce((accumulator, scan) => {
      if (!scan || !scan.annotationSet) return accumulator;
      // embed the scanId into each comment.
      scan.annotationSet.forEach(anno => (anno.seriesId = scan.seriesId));
      return [...accumulator, ...scan.annotationSet];
    }, []);
    return sortBy(
      uniqBy(raw_comments, "id"),
      c => c.annotations.createdAt
    ).reverse();
  }
</script>

<script>
  // Import external dependencies.
  import { translate, distanceInWords, parseDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { createEventDispatcher, onDestroy } from "svelte";
  import uniqBy from "lodash-es/uniqBy";
  import sortBy from "lodash-es/sortBy";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { route, queryParameters } from "../stores/router";
  import now from "../stores/now";
  import user from "../stores/user";
  import { client as api } from "../helpers/apollo";
  import { addSeriesAnnotation } from "./viewer.graphql";
  import { getScanClassName } from "./helpers";
  import { getInitials, getTags } from "../helpers/formatters";
  import { renderFullName, getBadgeClassName } from "../exam-module/helpers";
  import PhaseButton from "../components/phase-button.svelte";
  import { GET_SCAN } from "./viewer.graphql";
  import { trackAction } from "../helpers/telemetry";

  // External Props
  export let scans;
  export let scanId = "";
  export let coordinate;

  // Internals
  const waitForDOM = 100;
  const dispatch = createEventDispatcher();
  $: comments = compute_comments(scans);

  // Active Comment and Scan Selection
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let activeComment;
  let activeScan;
  $: activeCommentId =
    $queryParameters.comments && !isFormOpen
      ? $queryParameters.comments
      : false;
  $: compute_activeComment_activeScan(scans, activeCommentId);
  function compute_activeComment_activeScan() {
    if (!scans || !scans.length || !activeCommentId)
      return (activeComment = activeScan = false);
    activeScan = scans.find(scan => {
      if (!scan || !scan.annotationSet) return false;
      activeComment = scan.annotationSet.find(({ id }) => {
        return sterilize(id) === activeCommentId;
      });
      return activeComment;
    });
  }

  // Scroll to the selected comment
  let annotationListEl;
  let goToCommentTimer;
  onDestroy(() => clearTimeout(goToCommentTimer));
  $: highlightCommentAndScan(activeComment, annotationListEl);
  function highlightCommentAndScan() {
    if (!activeComment) return false;

    // broadcast event - lets multiviewer highlight the associated scan.
    dispatch("commentSelected", { comment: activeComment, scan: activeScan });

    // find the DOM element of the selected comment...
    if (!annotationListEl) return false;
    const commentEl = annotationListEl.querySelector(
      `#anno-${sterilize(activeComment.id)}`
    );
    if (!commentEl) return false;

    // ... scroll to it.
    clearTimeout(goToCommentTimer);
    goToCommentTimer = setTimeout(() => {
      commentEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, waitForDOM);
  }

  // Helper Functions.
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  function sterilize(txt = "") {
    return txt.replace(/[.,/?!$%^&*;:{}=\-_`~()]/g, "");
  }

  function getCommentQP(id) {
    const steril_id = sterilize(id);
    return $queryParameters.comments === steril_id ? "true" : steril_id;
  }

  // Create Comment Form
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let commentBodyTxt = "";
  let comment = false;
  let isUploading = false;
  let uploadError = false;
  $: isFormOpen = $queryParameters.comments === "new";
  $: newCommentSelectedScan =
    scans && scanId ? scans.find(s => s && s.seriesId === scanId) : false;

  // Open and Close URLs to bind to buttons
  $: openFormHref = `#/${formatUrl({
    $route,
    $queryParameters,
    newQPs: {
      comments: "new",
    },
  })}`;
  $: closeFormHref = `#/${formatUrl({
    $route,
    $queryParameters,
    newQPs: {
      comments: true,
    },
  })}`;

  // Scroll to the form when it opens.
  let draftCommentFormEl;
  let formElDOMTimer;
  onDestroy(() => clearTimeout(formElDOMTimer));
  $: if (draftCommentFormEl) {
    clearTimeout(formElDOMTimer);
    formElDOMTimer = setTimeout(() => {
      draftCommentFormEl.scrollIntoView({ behavior: "smooth" });
    }, waitForDOM);
  }

  // Form Submission
  async function handleFormSubmit() {
    // Validate the form
    if (!scanId)
      return (uploadError = translate("validation.scan_id_required"));
    if (!commentBodyTxt)
      return (uploadError = translate("validation.message_required"));

    // Structure the body of the request
    const rightNow = new Date();
    const scanId_cached = scanId;
    const input = {
      seriesId: scanId,
      annotation: {
        comments: null,
        annotations: {
          points: [coordinate],
          message: commentBodyTxt,
          author: {
            name: $user.name,
            id: $user.id,
          },
          createdAt: rightNow.toISOString(),
        },
      },
    };

    // Make API Call...
    isUploading = true;
    uploadError = false;
    const resp = await api
      .mutate({
        mutation: addSeriesAnnotation,
        variables: { input },
        update(store, { data: { addSeriesAnnotation } }) {
          const data = store.readQuery({
            query: GET_SCAN,
            variables: { scanId },
          });
          data.series.annotationSet = addSeriesAnnotation.series.annotationSet;
          store.writeQuery({ query: GET_SCAN, variables: { scanId }, data });
        },
      })

      // ... handle errors from API.
      .catch(err => {
        isUploading = false;
        uploadError = err;
      });

    // ... handle success.
    isUploading = false;
    const { annotationSet } = resp.data.addSeriesAnnotation.series;
    comment = annotationSet[annotationSet.length - 1];
    dispatch("newComment", { comment, scanId: scanId_cached });
    trackAction("multiviewer", "add comment");
  }

  // Form Resets
  function handleFormReset() {
    isUploading = uploadError = comment = commentBodyTxt = "";
  }
  $: if ($queryParameters.comments === "true") handleFormReset();
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "src/ui/viewer-module/_vars";
  $scan-module-color: map-get($module-colors, "scans") !important;
  $annotation-color: $primary;

  // Stylings
  .comments-panel {
    border-top-width: 2px !important;
  }
  .comments-panel-header {
    height: $pane-header-height;
    background-color: $gray-925;
  }
  .annotation-scroller {
    height: calc(100% - #{$pane-header-height});
  }
  .annotation {
    border-top: 1px solid $annotation-color;
  }
  .comment {
    border-top: 1px solid $annotation-color;
  }
  .draft-comment-form {
    border-top-color: $primary !important;
    padding-bottom: 2rem;
    z-index: 0;
    background-color: $body-bg;
  }
  .scan-card {
    background-color: $gray-935;
    border: 1px solid $border-color;
    border-top: 1px solid $scan-module-color;
    @each $scan, $color in $scan-colors {
      &.#{$scan} {
        border-top-color: $color !important;
      }
    }
  }
  .no-scan-selected {
    min-height: 4.125rem;
    border: 1px dashed $secondary;
  }
  .annotation-list {
    .annotation {
      transition: opacity 0.2s ease-out;
    }
    &.active .annotation {
      opacity: 0.5;
    }
    &.active .annotation.active {
      opacity: 1;
    }
  }
</style>

<div
  class="comments-panel | border-top border-primary d-flex flex-column h-100
  bg-black"
  data-component="comments-panel">

  <!-- Header -->
  <header class="comments-panel-header row no-gutters align-items-center">
    <div class="col px-3">
      <i class="far fa-comments mr-1" />
      <span>{translate('multiviewer.comment', 99)}</span>
    </div>
    <div class="col-auto h-100">
      <a
        class="close px-2 pb-1 h-100 d-flex align-items-center"
        aria-label={translate('form_labels.close')}
        href={`#/${formatUrl({
          $route,
          $queryParameters,
          newQPs: { comments: null },
        })}`}>
        <span aria-hidden="true">×</span>
      </a>
    </div>
  </header>

  <div class="annotation-scroller | px-3 flex-grow-1 overflow-auto">

    <!-- Add Annotation Button / Form -->
    {#if !isFormOpen}
      <a class="btn btn-sm btn-primary btn-block my-3" href={openFormHref}>
        <span>{translate('annotations.add')}</span>
      </a>
    {:else}
      <div class="pt-3 mb-3" bind:this={draftCommentFormEl}>
        <section
          class="draft-comment-form | pt-4 px-4 border rounded position-relative
          shadow">

          <!-- Title -->
          <div
            class="title-bgblock left-0 top-0 position-absolute w-100 underlay" />
          <h3 class="text-truncate mt-3 mb-4">
            <span>{translate('annotations.create_comment')}</span>
          </h3>

          <!-- Close Button -->
          <a
            class="close position-absolute top-0 right-0 p-3 m-1"
            aria-label={translate('form_labels.close')}
            href={closeFormHref}
            on:click={() => (comment = false)}>
            <span aria-hidden="true">×</span>
          </a>

          <p class="text-muted">{translate('annotations.new_one_hint')}</p>

          <!-- Form -->
          <form on:submit|preventDefault={handleFormSubmit}>

            <!-- Comment Body -->
            <div class="form-group">
              <label for="ASF-Description">
                {translate('form_labels.description')}
              </label>
              <textarea
                class="form-control"
                id="ASF-Description"
                disabled={isUploading || comment}
                bind:value={commentBodyTxt}
                required />
            </div>

            <!-- Scan (read only) -->
            <label class="text-capitalize">
              {translate('modules.scan', 1)}
            </label>
            {#if newCommentSelectedScan}
              <div
                class="scan-card | mb-3 py-2 px-3 rounded {getScanClassName(newCommentSelectedScan)}">
                <span class="d-block text-truncate">
                  {renderFullName(newCommentSelectedScan.exam.subject.fullName)}
                </span>
                <span
                  class="badge text-uppercase {getBadgeClassName(newCommentSelectedScan.descriptionUser)}">
                  {newCommentSelectedScan.descriptionUser.replace(/_/g, ' ')}
                </span>
              </div>
            {:else}
              <div
                class="no-scan-selected | text-secondary mb-3 py-2 px-3 rounded
                d-flex align-items-center">
                <span>Select a Scan to comment on</span>
              </div>
            {/if}

            <!-- Submit Button -->
            <PhaseButton
              className="btn-block"
              startClassName="btn-primary"
              successTxt={translate('annotations.create_comment_success')}
              isInFlight={isUploading}
              isSuccessful={comment}>
              <span>{translate('annotations.create_comment_submit')}</span>
            </PhaseButton>

            <!-- Success State - Reset Button -->
            {#if comment}
              <div class="text-center h-0 | animated fadeIn">
                <button
                  on:click={handleFormReset}
                  type="button"
                  class="btn btn-sm btn-link text-truncate">
                  <span>{translate('form_labels.create_another')}</span>
                </button>
              </div>
            {/if}

            <!-- Error State - Alert -->
            {#if uploadError}
              <div class="alert alert-danger mt-3 mb-0">
                <span class="alert-text">{uploadError}</span>
              </div>
            {/if}
          </form>

        </section>
      </div>
    {/if}

    <!-- Empty State - No Comments -->
    {#if !isFormOpen && (!comments || !comments.length)}
      <div
        class="border-dashed p-5 text-center text-muted border border-secondary
        rounded mt-5">
        <h4 class="mb-0">{translate('multiviewer.no_comments')}</h4>
      </div>

      <!-- Annotations -->
    {:else}
      <div
        class="annotation-list"
        class:active={$queryParameters.comments !== 'true' && $queryParameters.comments !== 'new'}
        bind:this={annotationListEl}>
        {#each comments as { id, annotations }, i (id)}
          <div
            class="annotation | my-3 | bg-dark rounded position-relative"
            class:active={sterilize(id) === $queryParameters.comments}
            id={`anno-${sterilize(id)}`}>
            <div class="clearfix p-3">
              {#if annotations.author}
                <figure
                  class="avatar | rounded-circle bg-primary float-right ml-2
                  mb-1 overflow-hidden text-truncate text-center text-uppercase"
                  title={annotations.author.name}>
                  <figcaption>
                    {getInitials(annotations.author.name)}
                  </figcaption>
                </figure>
              {/if}
              <a
                class="text-truncate h6 stretched-link"
                href={`#/${formatUrl({
                  $route,
                  $queryParameters,
                  newQPs: { comments: getCommentQP(id) },
                })}`}>
                {#if annotations.author}
                  {#if annotations.author.id === $user.id}
                    {translate('you')}
                  {:else}{annotations.author.name}{/if}
                {/if}
                {#if annotations.createdAt}
                  <small class="text-muted ml-1">
                    {distanceInWords(parseDate(annotations.createdAt), $now)}
                    {translate('ago')}
                  </small>
                {/if}
              </a>
              <p class="mb-0 message">{annotations.message}</p>
            </div>

            <div class="tags | px-3 ">
              {#each getTags(annotations.message) as tag}
                <span class="badge badge-primary badge-{tag} mr-2 mb-2">
                  {tag}
                </span>
              {/each}
            </div>
            <!-- {#if comments && comments.length}
              <div class="comments">
                {#each comments as comment}
                  <div class="comment px-3 py-2">
                    <p class="mb-0">
                      In fermentum urna ut lectus semper placerat.
                    </p>
                  </div>
                {/each}
              </div>
            {/if} -->
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>
