<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onMount, onDestroy } from "svelte";
  import jQuery from "jquery";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import uniqBy from "lodash-es/uniqBy";
  import debounce from "lodash-es/debounce";
  import store from "store/dist/store.modern.min";
  import Viewer, { scanIds, urlDebounceMs } from "./viewer.svelte";
  import { client } from "../helpers/apollo";
  import {
    route,
    queryParameters,
    updateQueryParameters,
  } from "../stores/router";
  import user from "../stores/user";
  import { renderMetaData } from "./helpers";
  import AddScanModal from "./add-scan-modal.svelte";
  import MultiviewerPaneHeader from "./multiviewer-pane-header.svelte";
  import ExamHeader from "./exam-header.svelte";
  import CommentsPanel from "./comments-panel.svelte";
  import TutorialModal from "./tutorial-modal.svelte";
  import ShortcutsModal from "./shortcuts-modal.svelte";
  import { trackPageView, trackAction } from "../helpers/telemetry";
  import { GET_SCAN } from "./viewer.graphql";

  // Tool Selection
  const window_tools = [
    {
      name: "luminosity",
      icon: "sun",
      hed: translate("multiviewer.luminosity"),
    },
    {
      name: "rocker",
      icon: "cube",
      hed: translate("multiviewer.rocker"),
    },
  ];
  $: individualizedToolKey = `${$user.id}-multiviewer_active_tool_idx`;
  $: active_tool_idx = store.get(individualizedToolKey) || 1;
  $: store.set(individualizedToolKey, active_tool_idx);
  function handleToolSelection(idx) {
    active_tool_idx = idx;
    trackAction(
      "multiviewer",
      `select tool - ${window_tools[active_tool_idx].name}`
    );
  }

  // Show Details
  $: individualizedShowInfoKey = `${$user.id}-multiviewer_show_info`;
  $: showMetadata = store.get(individualizedShowInfoKey) || false;
  $: store.set(individualizedShowInfoKey, showMetadata);
  function handleDetailsToggle() {
    showMetadata = !showMetadata;
    trackAction("multiviewer", `${showMetadata ? "show" : "hide"} details`);
  }

  // Show Exam Header
  $: individualizedHideExamHeaderKey = `${$user.id}-multiviewer_hide_exam`;
  $: hideExamHeader = store.get(individualizedHideExamHeaderKey) || false;
  $: store.set(individualizedHideExamHeaderKey, hideExamHeader);
  function handleShowExamHander() {
    hideExamHeader = false;
    highlightScans = false;
    trackAction("multiviewer", "showExamHeader");
  }

  // Array of Scan Objects (loaded from server)
  let scans = [];
  $: compute_scans($scanIds);
  function compute_scans($scanIds) {
    const fresh_scans = [];
    $scanIds.forEach(async (scanId, i) => {
      fresh_scans[i] = false;
      const resp = await client.query({
        query: GET_SCAN,
        variables: { scanId },
      });
      const scan = resp.data.series;
      fresh_scans[i] = scan;
      scans = [...fresh_scans];
    });
    scans = fresh_scans;
  }

  // Array of Exam objects (embedded within Scans)
  $: exams = scans.reduce((accumulator, scan) => {
    if (scan) accumulator.push(scan.exam);
    else if (scan === false)
      // strict false value implies loading.
      accumulator.push(false);
    return accumulator;
  }, []);
  $: uniqueExams = uniqBy(exams, "examId");

  // Track the highlighted exam.
  let activeExamId = false;
  let highlightScans = false;
  let highlightedExamId = false;

  // Reactive link to open the Add Scan modal.
  $: addScanUrl = `#/${formatUrl({
    $route,
    $queryParameters,
    newQPs: { modal: "add-scan" },
  })}`;

  // This function is called from a loop in the template.
  // By computing the identifier, we can allow for duplicate scans (IDs) to be opened.
  const getScanLoopIdentifier = (function getScanLoopIdentifier_closure() {
    let idCheckCount = 0;
    let deduplicator = {};
    return function getScanLoopIdentifier(id, maxCount) {
      // Return the ID or a modified ID if this is a duplicate scan.
      let identifier;
      if (!deduplicator[id]) {
        deduplicator[id] = 1;
        identifier = id;
      } else {
        deduplicator[id]++;
        identifier = `${id}_${deduplicator[id]}`;
      }

      // Reset the deduplicator once we've checked all the scans.
      idCheckCount++;
      if (idCheckCount >= maxCount) {
        idCheckCount = 0;
        deduplicator = {};
      }

      return identifier;
    };
  })();

  // Reflect changes from the viewers into the URL. Accumulates and debounces changes.
  let isDestroyed = false;
  const updateURL = (function updateURL_closure() {
    let newQPs = {};
    onDestroy(() => (isDestroyed = true));
    const debounced_url_reflection = debounce(function url_reflection() {
      // stop execution if this component's been destroyed.
      // this is necessary because of the debounce (timeout).
      if (isDestroyed) return false;

      // convert queued values into strings for the URL.
      const stringifiedQPs = {};
      Object.keys(newQPs).forEach(key => {
        let value = newQPs[key];
        const dataType = typeof value;
        if (value === false) value = null;
        else if (dataType === "number") value = value.toFixed(4);
        else if (dataType === "object") value = JSON.stringify(value);
        stringifiedQPs[key] = value;
      });
      updateQueryParameters(stringifiedQPs);
      newQPs = {};
    }, urlDebounceMs);

    return function updateURL(event, index = false) {
      const { key, value } = event.detail;
      newQPs[`${key}${index === false ? "" : index}`] = value;
      debounced_url_reflection();
    };
  })();

  // Reflect global scroll into the URL.
  $: url_globalScrollZ = $queryParameters.poz;
  $: url_globalScrollY = $queryParameters.poy;
  $: url_globalScrollX = $queryParameters.pox;
  $: globalScrollZ = parseFloat(url_globalScrollZ) || 0.0;
  $: globalScrollY = parseFloat(url_globalScrollY) || 0.0;
  $: globalScrollX = parseFloat(url_globalScrollX) || 0.0;
  $: updateURL({ detail: { key: "poz", value: globalScrollZ } });
  $: updateURL({ detail: { key: "poy", value: globalScrollY } });
  $: updateURL({ detail: { key: "pox", value: globalScrollX } });

  // Reflect global pan into the URL.
  $: url_globalPanX = $queryParameters.panx;
  $: url_globalPanY = $queryParameters.pany;
  $: globalPanX = parseFloat(url_globalPanX) || 0.0;
  $: globalPanY = parseFloat(url_globalPanY) || 0.0;
  $: updateURL({ detail: { key: "panx", value: globalPanX } });
  $: updateURL({ detail: { key: "pany", value: globalPanY } });

  // Reflect global rotation into the URL.
  $: url_globalRotation = $queryParameters.rot;
  $: globalRotation = url_globalRotation || null;
  $: updateURL({ detail: { key: "rot", value: globalRotation } });

  // Re-Render the panes if a scan is added or removes, comments are toggled, or the screen size changes.
  let renderTrigger = 0;
  let screenWidth = 0;
  let screenHeight = 0;
  $: scanCount = $scanIds.length;
  $: triggerRender(
    scanCount,
    isCommentPanelOpen,
    screenWidth,
    screenHeight,
    hideExamHeader
  );
  function triggerRender() {
    renderTrigger++;
  }

  // Modals
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  $: isAddScanModalOpen = $queryParameters.modal === "add-scan";
  $: isTutorialModalOpen = $queryParameters.modal === "tutorial";
  $: isShortcutsModalOpen = $queryParameters.modal === "shortcuts";

  // Share Link
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let shareButton; // gets bound to DOM element
  let shareTooltipTimer;
  $: shareLink = $route || $queryParameters ? window.location : window.location;
  function handleShareLinkClick() {
    trackAction("multiviewer", "share");

    // Use native share dialog if it's supported.
    if (window.navigator.share)
      return window.navigator.share({ url: window.location }).catch(() => true);

    // Otherwise, copy the current address to their clipboard and alert them.
    if (window.navigator.clipboard && window.navigator.clipboard.writeText) {
      window.navigator.clipboard.writeText(shareLink);
      jQuery(shareButton).tooltip("show");
      shareTooltipTimer = setTimeout(() => {
        jQuery(shareButton).tooltip("hide");
      }, 3000);
    }
  }
  onMount(function setupTooltips() {
    if (!window.navigator.share) jQuery(shareButton).tooltip();
  });
  onDestroy(function teardownTooltips() {
    clearTimeout(shareTooltipTimer);
    jQuery(shareButton).tooltip("dispose");
  });

  // Commenting
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  $: isCommentPanelOpen = !!$queryParameters.comments;
  const scanHighlightDuration = 1500;
  let selectedPanelIdx;
  let highlightCommentedScan;
  onDestroy(() => clearTimeout(highlightCommentedScan));
  $: if ($scanIds.length === 1) selectedPanelIdx = 0;
  $: newCommentCoordinate = {
    x: $queryParameters[`pox${selectedPanelIdx}`]
      ? parseFloat($queryParameters[`pox${selectedPanelIdx}`])
      : parseFloat(globalScrollX),
    y: $queryParameters[`poy${selectedPanelIdx}`]
      ? parseFloat($queryParameters[`poy${selectedPanelIdx}`])
      : parseFloat(globalScrollY),
    z: $queryParameters[`poz${selectedPanelIdx}`]
      ? parseFloat($queryParameters[`poz${selectedPanelIdx}`])
      : parseFloat(globalScrollZ),
  };

  function handleCommentSelected({ detail: { comment, scan } }) {
    trackAction("multiviewer", "show comment");

    // Find which panel to scroll to.
    if (scan && $scanIds[selectedPanelIdx] !== scan.seriesId) {
      selectedPanelIdx = $scanIds.findIndex(sid => sid === scan.seriesId);
    }

    // Darken the other panels for a moment.
    clearTimeout(highlightCommentedScan);
    highlightCommentedScan = setTimeout(() => {
      highlightCommentedScan = null;
    }, scanHighlightDuration);

    // Update the Z-index in the URL for that index.
    updateQueryParameters({
      [`poz${selectedPanelIdx}`]: comment.annotations.points[0].z,
      [`poy${selectedPanelIdx}`]: comment.annotations.points[0].y,
      [`pox${selectedPanelIdx}`]: comment.annotations.points[0].x,
    });
  }

  // Analytics
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  $: telemetry(
    isCommentPanelOpen,
    isAddScanModalOpen,
    isTutorialModalOpen,
    isShortcutsModalOpen
  );
  function telemetry() {
    setTimeout(() => {
      if (isDestroyed) return;
      let page = "multiviewer";
      if (isAddScanModalOpen) page = "multiviewer/add-scan";
      else if (isTutorialModalOpen) page = "multiviewer/tutorial";
      else if (isShortcutsModalOpen) page = "multiviewer/shortcuts";
      else if (isCommentPanelOpen) page = "multiviewer/comments";
      trackPageView(page);
    });
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "ui/viewer-module/vars";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $scanColor: map-get($module-colors, "scans");
  $multiviewer-controls-height: 3rem; // 48px
  $exam-header-height: 3rem; // 48px

  // Create a fullscreen, fixed layout.
  .controls {
    height: $multiviewer-controls-height;
  }
  .exam-header-positioner {
    height: $exam-header-height;
  }
  .panels {
    height: calc(
      100% - #{$multiviewer-controls-height} - #{$exam-header-height}
    );
    @include media-breakpoint-up(md) {
      flex-direction: row !important;
    }
  }

  .multiviewer.hide-exam-header {
    .exam-header-positioner {
      display: none;
    }
    .panels {
      height: calc(100% - #{$multiviewer-controls-height});
    }
  }

  // Stylings
  .viewer-windows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1px;
    min-height: 0;
    min-width: 0;
  }
  .pane {
    overflow: hidden;
    min-width: 0;
    border-top: 2px solid $scanColor;
    color: $scanColor;
    @each $scan, $color in $scan-colors {
      &.scan-type-#{$scan} {
        border-top-color: $color !important;
        color: $color;
      }
    }
  }
  .panes-1,
  .panes-3,
  .panes-5,
  .panes-7,
  .panes-9,
  .panes-11,
  .panes-13,
  .panes-15 {
    .pane:last-child {
      grid-column: 1/3;
    }
  }

  // Responsive Stylings
  @include media-breakpoint-only(xs) {
    .panes-2 {
      grid-template-columns: 1fr;
    }
  }
  @include media-breakpoint-up(lg) {
    .viewer-windows {
      grid-template-columns: 1fr 1fr 1fr;
      .pane {
        grid-column: inherit !important;
      }
      &.panes-1 {
        grid-template-columns: 1fr;
      }
      &.panes-2,
      &.panes-4 {
        grid-template-columns: 1fr 1fr;
      }
      &.panes-5 .pane-4 {
        grid-column: 2/4 !important;
      }
      &.panes-7 .pane-6 {
        grid-column: 1/4 !important;
      }
      &.panes-8 .pane-7 {
        grid-column: 2/4 !important;
      }
      &.panes-10,
      .panes-11,
      .panes-12,
      .panes-13,
      .panes-14,
      .panes-15 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .pane {
          grid-column: inherit !important;
        }
      }
      &.panes-10 {
        .pane-8 {
          grid-column: 1/3 !important;
        }
        .pane-9 {
          grid-column: 3/5 !important;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .viewer-windows {
      &.panes-7,
      .panes-8,
      .panes-10,
      .panes-11,
      .panes-12,
      .panes-13,
      .panes-14,
      .panes-15,
      .panes-16 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      &.panes-7 .pane-6 {
        grid-column: 3/5 !important;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .viewer-windows {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      &.panes-3,
      &.panes-5,
      &.panes-6,
      &.panes-9 {
        grid-template-columns: 1fr 1fr 1fr;
      }
      &.panes-8 .pane-7 {
        grid-column: inherit !important;
      }
    }
  }

  // Metadata Toggling
  .viewer-windows.hide-metadata {
    :global(.metadata) {
      display: none !important;
    }
  }
  .viewer-windows {
    :global(.metadata) {
      z-index: 2;
    }
    :global(.metadata span) {
      background-color: $viewer-controls-bg;
    }
  }

  // Comment Panel Positioning
  .comment-panel-positioner {
    z-index: 1;
    height: 45%;
    @include media-breakpoint-up(md) {
      position: relative !important;
      width: 500px !important;
      height: 100%;
      border-left: 1px solid $secondary;
    }
  }

  // Commenting
  .viewer-windows.add-comment {
    cursor: crosshair;
  }
</style>

<svelte:window bind:innerWidth={screenWidth} bind:innerHeight={screenHeight} />
<section
  data-component="multiviewer"
  class="multiviewer | h-100 position-relative"
  class:hide-exam-header={hideExamHeader}>

  <!-- Empty State -->
  {#if !$scanIds.length}
    <div
      class="border-dashed p-5 text-center text-muted border border-secondary
      rounded align-self-center v-center m-3">
      <h1 class="mb-5">{translate('multiviewer.no_scans_selected')}</h1>
      <a class="btn btn-secondary" href={addScanUrl}>
        <span>{translate('multiviewer.add_scan')}</span>
      </a>
    </div>

    <!-- Loaded State -->
  {:else}
    <!-- Controls -->
    <div class="controls p-2 d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div
          class="btn-toolbar"
          role="toolbar"
          aria-label={translate('multiviewer.panel_drag_ctrls')}
          title={translate('multiviewer.panel_drag_ctrls')}>

          <!-- Drag Action Selection. Radio Buttons or Dropdown on Mobile -->
          <div class="drag-action-selection">

            <!-- Dropdown Button ( Mobile ) -->
            <div class="btn-group mr-2 | d-md-none">
              <button
                type="button"
                class="btn btn-sm btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i
                  class="fas fa-fw fa-{window_tools[active_tool_idx].icon} mr-1" />
                <span class="d-none d-sm-inline">
                  {window_tools[active_tool_idx].hed}
                </span>
              </button>
              <div class="dropdown-menu">
                {#each window_tools as { icon, hed }, i (hed)}
                  {#if i !== active_tool_idx}
                    <button
                      type="button"
                      class="dropdown-item pl-2 pr-3"
                      on:click={() => handleToolSelection(i)}>
                      <i class="fas fa-fw fa-{icon} mr-1" />
                      <span>{hed}</span>
                    </button>
                  {/if}
                {/each}
              </div>
            </div>

            <!-- Radio Buttons ( Large Screen ) -->
            <div
              class="btn-group btn-group-sm mr-2 | d-none d-md-flex"
              role="group"
              aria-label="First group">
              {#each window_tools as { icon, hed }, i (hed)}
                <button
                  type="button"
                  class="btn btn-primary"
                  class:active={i === active_tool_idx}
                  on:click={() => handleToolSelection(i)}>
                  <i class="fas fa-fw fa-{icon} mr-1" />
                  <span>{hed}</span>
                </button>
              {/each}
            </div>
          </div>
        </div>

        <!-- Show Details Button -->
        <button
          type="button"
          class="btn btn-sm btn-primary mr-2"
          class:active={showMetadata}
          title={translate('multiviewer.show_details')}
          on:click={handleDetailsToggle}>
          <i class="fas fa-fw fa-info" />
          <span class="d-none d-md-inline">
            {translate('multiviewer.show_details')}
          </span>
        </button>

        <!-- Add Scan Button -->
        <a
          class="btn btn-sm btn-primary mr-2"
          href={addScanUrl}
          title={translate('multiviewer.add_scan')}>
          <i class="fas fa-fw fa-plus" />
          <span class="d-none d-lg-inline">
            {translate('multiviewer.add_scan')}
          </span>
        </a>

        <!-- Share Button -->
        <a
          class="btn btn-sm btn-primary mr-2"
          href={shareLink}
          on:click={handleShareLinkClick}
          bind:this={shareButton}
          title={translate('multiviewer.url_in_clipboard')}
          data-toggle="popover"
          data-trigger="manual">
          <i class="fas fa-share-alt" />
          <span class="d-none d-lg-inline">{translate('share')}</span>
        </a>

        <!-- Info Button -->
        <a
          class="btn btn-sm btn-link"
          href={`#/${formatUrl({
            $route,
            $queryParameters,
            newQPs: { modal: 'tutorial' },
          })}`}
          title={translate('multiviewer.tutorial.multiviewer_controls')}>
          <i class="far fa-question-circle" />
        </a>

        <!-- Keyboard Shortcuts -->
        <a
          class="btn btn-sm btn-link d-none d-sm-block"
          href={`#/${formatUrl({
            $route,
            $queryParameters,
            newQPs: { modal: 'shortcuts' },
          })}`}
          title={translate('multiviewer.tutorial.keyboard_shortcuts')}>
          <i class="fas fa-keyboard" />
        </a>
      </div>

      <!-- Right Side Controls (Exam Header and Comments toggles) -->
      <div class="d-flex align-items-center">

        {#if hideExamHeader}
          <button
            class="btn btn-sm btn-primary ml-2"
            on:click={handleShowExamHander}
            title={translate('multiviewer.show_exam_header')}>
            <i class="fas fa-fw fa-microscope" />
            <span class="d-none d-sm-inline">
              {translate('multiviewer.show_exam_header')}
            </span>
          </button>
        {/if}

        {#if !isCommentPanelOpen}
          <a
            class="btn btn-sm btn-primary ml-2"
            href={`#/${formatUrl({
              $route,
              $queryParameters,
              newQPs: { comments: true },
            })}`}
            title={translate('multiviewer.comment', 99)}>
            <i class="far fa-fw fa-comments" />
            <span class="d-none d-sm-inline">
              {translate('multiviewer.comment', 99)}
            </span>
          </a>
        {/if}
      </div>
    </div>

    <!-- Exam Header -->
    <div
      class="exam-header-positioner"
      on:mouseenter={() => (highlightScans = true)}
      on:mouseleave={() => (highlightScans = false)}>
      <ExamHeader
        exams={uniqueExams}
        {highlightedExamId}
        bind:hideExamHeader
        bind:activeExamId />
    </div>

    <!-- Scans & Comments -->
    <div class="panels d-flex flex-grow-1 flex-column">

      <!-- List of Scans -->
      <div
        class="viewer-windows panes-{$scanIds.length} position-relative w-100
        bg-secondary flex-grow-1"
        class:hide-metadata={!showMetadata}
        class:add-comment={$queryParameters.comments === 'new'}>
        {#each $scanIds as scanId, i (getScanLoopIdentifier(scanId, $scanIds.length))}
          <div
            class="pane pane-{i} flex-grow-1 d-flex bg-black flex-column
            scan-type-{scans[i] ? scans[i].descriptionUser
                  .split('_')[0]
                  .toLowerCase() : ''}"
            on:mouseenter={() => (highlightedExamId = scans[i] ? scans[i].exam.examId : false)}
            on:mouseleave={() => (highlightedExamId = false)}
            on:click={() => (selectedPanelIdx = i)}>
            <MultiviewerPaneHeader
              urlIndex={i}
              scanId={$scanIds[i]}
              scan={scans[i]}
              scanIds={$scanIds}
              viewMode={$queryParameters[`mod${i}`] || '2d'}
              isInterpolated={$queryParameters[`interp${i}`] !== 'false'} />
            <Viewer
              {scanId}
              {renderTrigger}
              bind:globalScrollZ
              bind:globalScrollY
              bind:globalScrollX
              bind:globalRotation
              bind:globalPanX
              bind:globalPanY
              on:controlChange={e => updateURL(e, i)}
              className=" flex-grow-1 d-flex w-100 {!hideExamHeader && highlightScans && (!scans[i] || scans[i].exam.examId !== activeExamId) ? 'lowlight' : ''}
              {highlightCommentedScan && selectedPanelIdx !== i ? 'lowlight' : ''}
              {$queryParameters.comments === 'new' && selectedPanelIdx && selectedPanelIdx !== i ? 'lowlight' : ''}
              "
              dragControl={window_tools[active_tool_idx].name}
              brightness={$queryParameters[`bri${i}`] ? parseFloat($queryParameters[`bri${i}`]) : false}
              contrast={$queryParameters[`con${i}`] ? parseFloat($queryParameters[`con${i}`]) : false}
              scrollX={$queryParameters[`pox${i}`] ? parseFloat($queryParameters[`pox${i}`]) : false}
              scrollY={$queryParameters[`poy${i}`] ? parseFloat($queryParameters[`poy${i}`]) : false}
              scrollZ={$queryParameters[`poz${i}`] ? parseFloat($queryParameters[`poz${i}`]) : false}
              panX={$queryParameters[`panx${i}`] ? parseFloat($queryParameters[`panx${i}`]) : false}
              panY={$queryParameters[`pany${i}`] ? parseFloat($queryParameters[`pany${i}`]) : false}
              scan={scans[i]}
              annotation={{ type: 'volume', url: 'https://something.somewhere' }}
              viewMode={$queryParameters[`mod${i}`] || '2d'}
              rotationStr={$queryParameters[`rot${i}`] ? $queryParameters[`rot${i}`] : false}
              clipStr={$queryParameters[`cli${i}`] ? $queryParameters[`cli${i}`] : false}
              zoom={$queryParameters[`zoom${i}`] ? parseFloat($queryParameters[`zoom${i}`]) || false : false}
              isInterpolated={$queryParameters[`interp${i}`] !== 'false'}>
              <div
                class="metadata | small w-100 px-1 px-lg-2 mt-1 | animated
                fadeIn faster">
                {#if scans[i]}
                  {@html renderMetaData(translate, scans[i], exams[i])}
                {/if}
                <!-- FYI, more metadata is added within the viewer (pixel-spacing, etc) -->
              </div>
            </Viewer>
          </div>
        {/each}
      </div>

      <!-- Comments Panel -->
      {#if isCommentPanelOpen}
        <div class="comment-panel-positioner | overflow-auto">
          <CommentsPanel
            {scans}
            scanId={$scanIds[selectedPanelIdx]}
            coordinate={newCommentCoordinate}
            on:newComment={() => compute_scans($scanIds)}
            on:commentSelected={handleCommentSelected} />
        </div>
      {/if}

    </div>
  {/if}

  <!-- Modals -->
  {#if isAddScanModalOpen}
    <AddScanModal exams={uniqueExams} {scans} />
  {:else if isTutorialModalOpen}
    <TutorialModal />
  {:else if isShortcutsModalOpen}
    <ShortcutsModal />
  {/if}
</section>
