<script context="module">
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { derived } from "svelte/store";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import {
    route,
    queryParameters,
    updateQueryParameters,
  } from "../stores/router";

  export const closeUrl = derived(
    [route, queryParameters],
    ([$route, $queryParameters]) => {
      if (!$route || !$queryParameters) return "";
      return `#/${formatUrl({
        $route,
        $queryParameters,
        newQPs: { modal: null },
      })}`;
    }
  );
</script>

<script>
  // Include our external dependencies.
  import { fade } from "svelte/transition";

  // External props
  export let title = "";
  export let className = "";
  export let closeHref = "";

  // Close on `Esc`.
  function handleKeydown({ keyCode }) {
    if (keyCode === 27) updateQueryParameters({ modal: null }, false);
  }
</script>

<style type="text/scss">
  @import "bootstrap/variables";
  .modal,
  .modal-content,
  .modal:hover,
  .modal-content:hover {
    text-decoration: none;
    color: $body-color;
  }
</style>

<svelte:window on:keydown={handleKeydown} />
<a
  transition:fade={{ duration: 100 }}
  class="modal show d-block"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ModalComponent_Title"
  data-component="modal"
  href={closeHref || $closeUrl}>
  <div class="modal-dialog {className}" role="document">
    <a
      class="modal-content"
      href={`#/${formatUrl({ $route, $queryParameters })}`}>
      <div class="modal-header align-items-center py-1">
        <h1 class="modal-title text-truncate" id="ModalComponent_Title" {title}>
          {title}
        </h1>
        <a
          class="close"
          data-dismiss="modal"
          aria-label={translate('form_labels.close')}
          href={closeHref || $closeUrl}>
          <span aria-hidden="true">×</span>
        </a>
      </div>
      <slot />
    </a>
  </div>
</a>
