// Import external dependencies.
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split, ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";
import { token } from "./auth0";

// Headers and environment settings.
let auth0_token = "";
const GRAPHQL_URL = "app-4534.on-aptible.com/graphql";
const jsonheader = { "content-type": "application/json" };
export function getAuthHeader() {
  const Authorization = auth0_token ? `JWT ${auth0_token}` : null;
  return { Authorization };
}

// Keep apollo synchronized with Auth0.
token.subscribe($token => (auth0_token = $token));

// Apollo Client Setup
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|

// Cache settings
const cache = new InMemoryCache();

// Web Socket settings for GraphQL Subscriptions
const wsLink = new WebSocketLink({
  uri: `ws://${GRAPHQL_URL}`,
  options: {
    reconnect: true,
    lazy: true,
  },
  connectionParams: () => ({ headers: { ...jsonheader, ...getAuthHeader() } }),
});

// HTTP Settings for Queries & Mutations
const httpLink = new HttpLink({
  uri: `https://${GRAPHQL_URL}`,
});

// Authentication & Auth Error Handling
const authHeaders = setContext((_, { headers }) => ({
  headers: { ...headers, ...getAuthHeader(), ...jsonheader },
}));
const authErrorHandler = onError(({ graphQLErrors /*, networkError*/ }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        /* eslint-disable-line no-console */
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
});

// Compose our Link.
const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  ApolloLink.from([authHeaders, authErrorHandler, httpLink])
);

// Create and Export the Apollo Client.
export const client = new ApolloClient({ link, cache });
