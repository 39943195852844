<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onDestroy } from "svelte";
  import { client } from "../helpers/apollo";
  import { route3 } from "../stores/router";
  import ExamList from "../exam-module/exam-list.svelte";
  import { trackPageView } from "../helpers/telemetry";
  import { GET_ORGANIZATION } from "./graphs.graphql";

  // Analytics
  $: telemetry($route3);
  let doTrack = true;
  onDestroy(() => (doTrack = false));
  function telemetry($route3) {
    setTimeout(() => {
      if (!doTrack) return false;
      const page = $route3
        ? `organization-details/${$route3}`
        : `organization-details`;
      trackPageView(page);
    });
  }

  // External Props
  export let organizationId = "";

  // Internal Props
  let organization = false;

  // Load Organization Details from the API
  $: (async function GetOrganizationFromAPI(id) {
    organization = false;
    const resp = await client.query({
      query: GET_ORGANIZATION,
      variables: { organizationId: id },
    });
    organization = resp.data.organization;
  })(organizationId);
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $organizationsColor: map-get($module-colors, "organizations");
  $staffColor: map-get($module-colors, "staff");

  // Stylings
  .summary {
    border-top-color: $organizationsColor !important;
  }
  .secondary-nav {
    .nav-link {
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 200ms ease-out;
      &.active {
        border-bottom-color: $organizationsColor;
      }
    }
  }
  .summary h1 {
    margin-top: 1px;
  }

  // Exam List Item Stylings
  .organization-details-component {
    :global(.exam-list-item) {
      grid-template-columns: 1fr 130px;

      // Responsive Layout
      @include media-breakpoint-up(sm) {
        grid-template-columns: 1fr 150px 100px;
      }
      @include media-breakpoint-only(sm) {
        padding-right: 0;
      }
      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 150px;
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 135px 100px;
      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 190px 150px 100px;
      }
      @include media-breakpoint-up(xxl) {
        grid-template-columns: 1fr 240px 170px 120px;
      }
    }
    :global(.exam-list-item div:nth-child(2)) {
      display: none !important;
    }
    :global(.exam-list-item div:nth-child(3)) {
      display: none !important;
      @include media-breakpoint-up(xl) {
        display: block !important;
      }
    }
    :global(.exam-list-item a:nth-child(5)) {
      display: none !important;
      @include media-breakpoint-up(sm) {
        display: flex !important;
      }
      @include media-breakpoint-only(md) {
        display: none !important;
      }
    }
  }

  // Staff List Item Styling
  .staff-row {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: map-get($spacers, 3);
    transition: border-top-color 0.2s ease-out;

    &:hover {
      border-top-color: $staffColor !important;
    }

    @include media-breakpoint-up(sm) {
      grid-template-columns: 2fr 3fr 90px;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 2fr 3fr 120px;
    }
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 2fr 3fr 180px;
    }
  }
</style>

<section
  class="organization-details-component"
  data-component="organization-details">

  <!-- Breadcrumbs -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item">
        <a href="#/organizations">{translate('modules.organizations', 99)}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {organization ? organization.organizationName : organizationId}
      </li>
    </ol>
  </nav>

  <!-- Uninitialized - Loading -->
  {#if organization === false}
    <div class="py-5 text-center spinner-delayed">
      <div class="spinner-border" role="status">
        <span class="sr-only">
          {translate('notifications.loadingDotDotDot')}
        </span>
      </div>
    </div>

    <!-- Unauthorized or Can't find Organization -->
  {:else if !organization}
    <h3 class="my-5 text-center">
      {translate('organization_data.none_found', 1)}
    </h3>

    <!-- Loaded Content -->
  {:else}
    <div class="container-fluid fade-in">
      <div class="row">

        <!-- Summary Panel -->
        <div class="col-12 col-md-6 col-lg-5 col-xl-4">
          <section class="summary | p-4 mb-4 border rounded position-relative">
            <div class="title-bgblock left-0 top-0 position-absolute w-100" />
            <a class="unlink position-relative" href={window.location.hash}>
              <h1
                class="text-truncate"
                title={organization ? organization.organizationName : organizationId}>
                {organization ? organization.organizationName : organizationId}
              </h1>
            </a>
            <span class="badge badge-organizations mr-2">
              {translate('modules.organizations', 1)}
            </span>
            <div class="details-list | mt-5">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2">
                {translate('overview', 1)}
              </small>
              <div class="d-flex justify-content-between py-1">
                <span class="pr-4">{translate('identifier', 1)}</span>
                {#if organizationId}
                  <span class="text-truncate" title={organizationId}>
                    {organizationId}
                  </span>
                {:else}
                  <em>{translate('unknown')}</em>
                {/if}
              </div>
            </div>
            <div class="d-flex justify-content-between py-1">
              <span class="pr-4">
                {translate('organization_data.address', 1)}
              </span>
              {#if organization.address}
                <a
                  class="unlink text-truncate"
                  href="https://maps.google.com"
                  title={organization.address}>
                  {organization.address}
                </a>
              {:else}
                <em>{translate('unknown')}</em>
              {/if}
            </div>
          </section>
        </div>

        <!-- Right Side -->
        <div class="col-12 col-md-6 col-lg-7 col-xl-8 | pb-4">
          <div class="details">

            <!-- Sub Navigation -->
            <nav class="nav secondary-nav pl-3 border-bottom mb-3">
              <a
                class="nav-link unlink"
                class:active={$route3 === 'exams' || !$route3}
                href="#/organizations/details/{organizationId}/exams">
                {translate('modules.exam', 99)}
              </a>
              <a
                class="nav-link unlink"
                class:active={$route3 === 'staff'}
                href="#/organizations/details/{organizationId}/staff">
                {translate('modules.staff', 99)}
              </a>
            </nav>

            <!-- Sub Routing -->
            <div>
              {#if !$route3 || $route3 === 'exams'}
                <ExamList />
              {:else if $route3 === 'staff'}
                <!-- Staff List -->
                {#each organization.users.edges as { node: user }, i (user.id)}
                  <div
                    class="staff-row | rounded border shadow mb-2 pt-2 px-3 pb-3">

                    <!-- Name -->
                    <div>
                      <h5
                        class="font-weight-normal"
                        title={translate('staff_data.name')}>
                        {#if user.name}
                          {user.name}
                        {:else}
                          <em>{translate('staff_data.name_unknown')}</em>
                        {/if}
                      </h5>
                      <span class="badge badge-staff mr-2">
                        {translate('modules.staff', 1)}
                      </span>
                    </div>

                    <!-- Email -->
                    <div class="text-truncate">
                      <small class="text-muted letter-space-1">
                        {translate('form_labels.email', 1)}
                      </small>
                      <br />
                      <a class="unlink" href={`mailto:${user.email}`}>
                        {user.email}
                      </a>
                    </div>

                    <!-- Is Verified -->
                    <div class="text-truncate d-none d-sm-block">
                      <small class="text-muted letter-space-1">
                        {translate('staff_data.is_verified', 1)}
                      </small>
                      <br />
                      <span>
                        {user.emailVerified ? translate('yes') : translate('no')}
                      </span>
                    </div>

                  </div>
                {/each}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}
</section>
