<script>
  // Import our external dependencies.
  import { fade } from "svelte/transition";
  import { route0, queryParameters } from "./stores/router";
  import { closeUrl } from "./components/modal.svelte";
  import Header from "./components/global-header.svelte";
  import ExamModule from "./exam-module/exam-module.svelte";
  import PatientModule from "./patient-module/patient-module.svelte";
  import OrganizationModule from "./organizations-module/organization-module.svelte";
  import About from "./components/about.svelte";
  import FourOhFour from "./components/four-oh-four.svelte";
  import Multiviewer from "./viewer-module/multiviewer.svelte";
  import Preauth from "./components/preauth.svelte";
  import user from "./stores/user";
</script>

<style>
  .main {
    overflow: auto;
  }
  .core-routing {
    padding-top: 56px;
  }
</style>

<main data-component="app" class="main h-100 position-relative">

  <!-- User Login & Account Creation -->
  {#if !$user || !$user.userId || $route0 === 'login' || $route0 === 'create-account'}
    <Preauth />

    <!-- Application -->
  {:else}
    <div class="core-routing | h-100">

      <!-- Main View Routing -->
      {#if !$route0 || $route0 === 'exams'}
        <ExamModule />
      {:else if $route0 === 'multiviewer'}
        <Multiviewer />
      {:else if $route0 === 'patients'}
        <PatientModule />
      {:else if $route0 === 'organizations'}
        <OrganizationModule />
      {:else if $route0 === 'about'}
        <About />
      {:else}
        <FourOhFour />
      {/if}
    </div>
    <Header className="position-absolute top-0 w-100" />

    <!-- Modal Backdrop -->
    {#if $queryParameters.modal}
      <a
        transition:fade={{ duration: 100 }}
        class="modal-backdrop show"
        href={$closeUrl}>
        <span />
      </a>
    {/if}
  {/if}
</main>
