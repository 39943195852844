<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onDestroy } from "svelte";
  import { GET_PATIENTS } from "./patients.graphql";
  import { client } from "../helpers/apollo";
  import { shortDate } from "../helpers/formatters";
  import { queryParameters, updateQueryParameters } from "../stores/router";
  import { renderFullName } from "../exam-module/helpers";
  import SearchBar from "../components/search-bar.svelte";
  import { trackPageView } from "../helpers/telemetry";

  // Analytics
  trackPageView("patients-home");

  // Prepare our API call and Patient data.
  let isLoading = true;
  let patients = null;
  let nextPageCursor = null;
  let httpReqErr = false;
  const api = client.watchQuery({
    query: GET_PATIENTS,
    variables: {
      search: $queryParameters.search || null,
      cursor: null,
    },
  });
  const api_subscription = api.subscribe(
    ({ data, loading }) => {
      if (!data || !data.viewer || !data.viewer.subjects) return;
      const { edges, pageInfo } = data.viewer.subjects;
      patients = edges.map(edge => edge.node);
      isLoading = loading;
      nextPageCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    },
    err => {
      httpReqErr = err;
      isLoading = false;
    }
  );
  onDestroy(() => api_subscription.unsubscribe());

  // Search - Replace URL which triggers $queryParameter reactivity.
  $: searchTxt = $queryParameters.search || "";
  function handleSearchTxtChange({ detail }) {
    updateQueryParameters({ search: detail });
  }
  $: search = $queryParameters.search || null;
  $: performSearch(search);
  function performSearch() {
    httpReqErr = false;
    api.refetch({
      search,
      cursor: null,
    });
  }

  // Load More
  let isLoadingMore = false;
  function handleLoadMoreClick() {
    isLoadingMore = true;
    api.fetchMore({
      variables: { cursor: nextPageCursor },
      updateQuery(prev, { fetchMoreResult }) {
        isLoadingMore = false;
        if (!fetchMoreResult) return prev;
        fetchMoreResult.viewer.subjects.edges = [
          ...prev.viewer.subjects.edges,
          ...fetchMoreResult.viewer.subjects.edges,
        ];
        return fetchMoreResult;
      },
    });
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $patientsColor: map-get($module-colors, "patients");

  :global(.patient-list-item) {
    transition: border-top-color 200ms ease-out;
    background-color: $body-bg;
    display: grid;
    grid-template-columns: 1fr 150px;
    grid-column-gap: map-get($spacers, 3);
    padding: map-get($spacers, 2) map-get($spacers, 3) map-get($spacers, 3)
      map-get($spacers, 3);

    &:hover,
    &.selected {
      text-decoration: none;
      color: inherit;
      border-top-color: $patientsColor !important;
    }

    // Responsive Layout
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 200px 110px;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 200px 120px 80px 90px;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 320px 140px 90px 110px;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 390px 210px 110px 110px;
    }
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 1fr 430px 390px 220px 170px;
    }
  }

  .load-more-spinner {
    top: -4px;
  }
</style>

<section data-component="patient-home">
  <main class="p-3">
    <h1 class="mb-3">{translate('modules.patient', 99)}</h1>

    <section class="patient-list">

      <!-- Search & Filter Controls -->
      <div class="pb-3">
        <SearchBar
          {searchTxt}
          placeholder={translate('patient_data.search_patients')}
          on:searchTxtChange={handleSearchTxtChange} />
      </div>

      <!-- List of Patients -->
      <div class="patients-list-api-call | position-relative">

        <!-- Error -->
        {#if httpReqErr}
          <div class="alert alert-danger" role="alert">🤖 {httpReqErr}</div>

          <!-- Loading... -->
        {:else if isLoading}
          <div
            class="d-flex justify-content-center align-items-center bg-spinner
            my-4">
            <div class="spinner-border" role="status">
              <span class="sr-only">{translate('loadingDotDotDot')}</span>
            </div>
          </div>

          <!-- Data Received -->
        {:else}
          <div>

            <!-- No Results -->
            {#if !patients || !patients.length}
              <h3 class="text-center mt-5">
                {translate('patient_data.none_found', 99)}
              </h3>

              <!-- Results Found -->
            {:else}
              <!-- Patients List -->
              {#each patients as { exams, ...patient } (patient.examSubjectId)}
                <a
                  class="patient-list-item | unlink rounded border mb-2 shadow
                  w-100"
                  href="#/patients/details/{patient.examSubjectId}">

                  <!-- Name -->
                  <div>
                    <h5 class="font-weight-normal">
                      {#if patient.fullName}
                        {renderFullName(patient.fullName)}
                      {:else}
                        <em>{translate('patient_data.unknown')}</em>
                      {/if}
                    </h5>
                    <span class="badge badge-patients mr-2">
                      {translate('modules.patient', 1)}
                    </span>
                  </div>

                  <!-- Unique Identifier -->
                  <div class="text-truncate">
                    <small class="text-muted letter-space-1">
                      {translate('id', 1)}
                    </small>
                    <br />
                    <span>{patient.subjectId}</span>
                  </div>

                  <!-- Date of Birth -->
                  <div class="text-truncate d-none d-sm-block">
                    <small class="text-muted letter-space-1">
                      {translate('patient_data.dob', 1)}
                    </small>
                    <br />
                    <span>
                      {#if patient.birthDate}
                        {patient.birthDate}
                      {:else}
                        <em>{translate('patient_data.dob_unknown', 1)}</em>
                      {/if}
                    </span>
                  </div>

                  <!-- Gender -->
                  <div class="text-truncate d-none d-md-block">
                    <small class="text-muted letter-space-1 text-capitalize">
                      {translate('patient_data.gender', 1)}
                    </small>
                    <br />
                    <span>
                      {#if !patient.sex}
                        {translate('patient_data.gender_unknown', 1)}
                      {:else if patient.sex === 'FEMALE'}
                        {translate('patient_data.gender_female', 1)}
                      {:else if patient.sex === 'MALE'}
                        {translate('patient_data.gender_male', 1)}
                      {:else}{translate('patient_data.gender_unknown', 1)}{/if}
                    </span>
                  </div>

                  <!-- Last Seen -->
                  <div class="text-truncate d-none d-md-block">
                    <small class="text-muted letter-space-1 text-capitalize">
                      {translate('patient_data.last_seen')}
                    </small>
                    <br />
                    {#if exams && exams.edges && exams.edges.length}
                      <a
                        class="unlink"
                        href={`#/exams/details/${exams.edges[0].node.examId}`}>
                        {shortDate(exams.edges[0].node.conductedDt)}
                      </a>
                    {/if}
                  </div>
                </a>
              {/each}

              <!-- Load More Button -->
              {#if nextPageCursor}
                <div class="text-center py-3">
                  <button
                    class="btn btn-secondary"
                    on:click={handleLoadMoreClick}
                    disabled={isLoadingMore}>
                    <span>{translate('form_labels.load_more')}</span>
                  </button>
                  <div class="d-inline position-relative ml-2">
                    {#if isLoadingMore}
                      <div class="load-more-spinner | position-absolute left-0">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">
                            {translate('loadingDotDotDot')}
                          </span>
                        </div>
                      </div>
                    {/if}
                  </div>
                </div>
              {/if}
            {/if}
          </div>
        {/if}
      </div>
    </section>

  </main>
</section>
