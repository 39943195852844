// Include external dependencies.
import noop from "lodash-es/noop";

// What errors should we ignore?
const whitelist = [401];

// Converts 400 and 500 level errors into Exceptions.
export async function fetchTranslator(resp) {
  if (resp.ok) {
    const respJson = await resp.json();
    return respJson.data;
  }
  const err = new Error((await resp.text()) || resp.statusText);
  err.code = resp.status;
  throw err;
}

export function makeErrorHandler(render = noop) {
  return err => {
    render(err);
    if (!err.code || !whitelist.includes(err.code))
      if (window.Sentry) window.Sentry.captureException(err);
      else window.console.error(err);
  };
}
