<script>
  // Include our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { dateAndTime } from "../helpers/formatters";
  import { renderFullName } from "../exam-module/helpers";
  import { route, queryParameters } from "../stores/router";
  import { trackAction } from "../helpers/telemetry";

  // External Props
  export let exams = false;
  export let hideExamHeader;
  export let highlightedExamId;
  export let activeExamId;

  // Reactively Compute the index, prev and next index, and urls.
  $: urlOpenExam = $queryParameters["exam-info"];
  $: examIdx = compute_examIdx(highlightedExamId, urlOpenExam, exams);
  function compute_examIdx(highlightedExamId, urlOpenExam, exams) {
    if (!exams) return 0;
    const id = highlightedExamId || urlOpenExam || false;
    if (!id) return 0;
    return exams.findIndex(exam => exam && exam.examId === id) || 0;
  }
  $: if (examIdx < 0) examIdx = 0;
  $: if (exams && exams[examIdx]) activeExamId = exams[examIdx].examId;
  $: exam = exams[examIdx];
  $: prevExamIdx = examIdx - 1 < 0 ? exams.length - 1 : examIdx - 1;
  $: nextExamIdx = examIdx + 1 >= exams.length ? 0 : examIdx + 1;
  $: prevUrl =
    exams && exams[prevExamIdx]
      ? `#${formatUrl({
          $route,
          $queryParameters,
          newQPs: { "exam-info": exams[prevExamIdx].examId },
        })}`
      : "";
  $: nextUrl =
    exams && exams[nextExamIdx]
      ? `#${formatUrl({
          $route,
          $queryParameters,
          newQPs: { "exam-info": exams[nextExamIdx].examId },
        })}`
      : "";

  // DOM Event Handlers
  function handleHide() {
    hideExamHeader = true;
    trackAction("multiviewer", "hideExamHeader");
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";
  $examColor: map-get($module-colors, "exams");

  // Stylings
  .exam-header {
    height: 48px;
    border-top: 1px solid $examColor;
  }

  .exam-row {
    display: grid;
    grid-template-columns: 2fr 3fr 4fr 60px;
    grid-column-gap: map-get($spacers, 3);

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr 1fr 1fr 60px;
    }
  }
</style>

<header class="exam-header d-flex" data-component="exam-header">

  <div class="exam-details flex-grow-1">

    <!-- Loading -->
    {#if exam === false}
      <div class="animated fadeIn delay d-flex h-100 align-items-center">
        <div class="spinner-border text-exams" role="status">
          <span class="sr-only">
            {translate('notifications.loadingDotDotDot')}
          </span>
        </div>
      </div>

      <!-- Not Found / Inaccessible -->
    {:else if !exam}
      <div class="d-flex align-items-center justify-content-around h-100">
        <em>{translate('exam_data.not_found')}</em>
      </div>

      <!-- Exam Details - Patient Name, MRN, Indication, etc. -->
    {:else if exam}
      <div class="exam-row | animated fadeIn faster">

        <!-- Subject Name -->
        <div class="text-truncate px-2 py-1">
          <small class="text-muted letter-space-1 d-block">
            {translate('patient_data.name_short')}
          </small>
          <a
            class="unlink small d-block text-truncate"
            href={`#/patients/details/${exam.subject.examSubjectId}`}>
            {renderFullName(exam.subject.fullName)}
          </a>
        </div>

        <!-- MRN -->
        <div class="text-truncate d-none d-sm-block px-2 py-1">
          <small class="text-muted letter-space-1 d-block text-truncate">
            {translate('exam_data.medical_records_number', 1)}
          </small>
          <small
            class="text-muted letter-space-1 d-block d-md-none"
            title={translate('exam_data.medical_records_number', 1)}>
            {translate('exam_data.medical_records_number_short', 1)}
          </small>
          <a
            class="unlink small d-block"
            href={`#/patients/details/${exam.subject.examSubjectId}`}>
            {exam.subject.subjectId}
          </a>
        </div>

        <!-- Exam Indication -->
        <div class="text-truncate px-2 py-1">
          <small class="text-muted letter-space-1 d-block">
            {translate('exam_data.indication_description')}
          </small>
          <a
            class="unlink small d-block"
            href={`#/exams/details/${exam.examId}`}
            title={exam.descriptionUser}>
            {exam.descriptionUser}
          </a>
        </div>

        <!-- Exam Time -->
        <div class="text-truncate px-2 py-1">
          <small class="text-muted letter-space-1 d-block">
            {translate('exam_data.conducted_at', 1)}
          </small>
          <a
            class="unlink small d-block text-truncate"
            href={`#/exams/details/${exam.examId}`}
            title={exam.conductedDt}>
            {dateAndTime(exam.conductedDt)}
          </a>
        </div>
      </div>
    {/if}
  </div>

  <!-- Pagination and Close Controls -->
  <div class="d-inline-flex flex-grow-0">

    <!-- Header Pagination -->
    {#if exams.length > 1}
      <div
        class="d-flex flex-column flex-grow-0 ml-auto"
        title={translate('multiviewer.open_exam_count', exams.length)}>
        <a
          href={prevUrl}
          class="btn btn-sm py-0 align-items-end d-inline-flex flex-grow-1">
          <i class="fas fa-caret-up" />
          <span class="sr-only">{translate('multiviewer.show_prev_exam')}</span>
        </a>
        <a
          href={nextUrl}
          class="btn btn-sm py-0 align-items-start d-inline-flex flex-grow-1">
          <i class="fas fa-caret-down" />
          <span class="sr-only">{translate('multiviewer.show_next_exam')}</span>
        </a>
      </div>
    {/if}

    <!-- Close Button -->
    {#if hideExamHeader !== undefined}
      <button
        class="close px-2 pb-1 d-inline-flex align-items-center"
        class:ml-auto={exams.length <= 1}
        aria-label={translate('form_labels.close')}
        on:click={handleHide}>
        <span aria-hidden="true">×</span>
      </button>
    {/if}
  </div>
</header>
