<script context="module">
  // Import external dependencies.
  import { derived } from "svelte/store";
  import { queryParameters } from "../stores/router";

  // Constants
  export const urlDebounceMs = 303;

  // Reactivity!.. derived store to globally track scan IDs in the URL.
  let _scanIds = [];
  export const scanIds = derived(
    queryParameters,
    ($queryParameters, set) => {
      const newIds =
        !$queryParameters || !$queryParameters.scans
          ? []
          : $queryParameters.scans.split(",");
      if (JSON.stringify(_scanIds) !== JSON.stringify(newIds)) {
        _scanIds = newIds;
        set(_scanIds);
      }
    },
    _scanIds
  );
</script>

<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import VolumeViewer from "volume-viewer"; //eslint-disable-line import/no-unresolved
  import { tick, onDestroy, createEventDispatcher } from "svelte";
  import jQuery from "jquery";
  import ScanDetailsModal from "./scan-details-modal.svelte";
  import ViewerControls from "./viewer-controls.svelte";
  import { parseJSON } from "../helpers/parse";

  // Destructure some tools out of the compiled volume-viewer lib.
  const {
    Tables: { blackBgTable, bloodLuminosityTable, bloodGradientTable },
  } = VolumeViewer;

  // Create an event dispatcher
  const dispatch = createEventDispatcher();

  // Export our public props.
  export let scanId = false;
  export let scan = false;
  export let className = "";
  export let dragControl = "luminosity";
  export let zoom = false;
  export let brightness = false;
  export let contrast = false;
  export let scrollX = false;
  export let scrollY = false;
  export let scrollZ = false;
  export let panX = false;
  export let panY = false;
  export let globalScrollZ = 0;
  export let globalScrollY = 0;
  export let globalScrollX = 0;
  export let globalPanX = 0;
  export let globalPanY = 0;
  export let globalRotation = null;
  export let renderTrigger = 0;
  export let viewMode = "2d";
  export let rotationStr = false;
  export let clipStr = false;
  export let annotation = false;
  export let isInterpolated = false;

  // Internal binding.
  let componentEl;
  let canvasEl;
  let isLoaded = false;
  $: globalRotationObj =
    typeof globalRotation === "string"
      ? parseJSON(globalRotation)
      : globalRotation;

  // Parse Rotation and Clipping information out of the URL strings.
  $: rotation = rotationStr ? parseJSON(rotationStr) : false;
  let clipNormal = false;
  let clipOffset = false;
  $: parseClippingFromUrlString(clipStr);
  function parseClippingFromUrlString(urlString) {
    if (!urlString) {
      clipNormal = clipOffset = false;
    } else {
      clipNormal = parseJSON(urlString);
      clipOffset = clipNormal.clipOffset;
    }
  }

  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --|
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --|

  // Reactive Volume Viewer Properties!
  let volumeViewer = false;
  $: pixelSpacing = volumeViewer ? volumeViewer.getVolumeShape() : false;
  $: sliceThickness = volumeViewer ? volumeViewer.getVolumeShape().scaleZ : 0;

  // Auto values for brightness and contrast are detected during `addVolumeProcessedCallback`.
  let autoBrightness = false;
  let autoContrast = false;

  // Load the Volume When the Scan URL changes!
  let prevDataUrl = false;
  $: if (componentEl && scan && prevDataUrl !== scan.dataUrl) {
    prevDataUrl = scan.dataUrl;
    loadVolume(); // This function is purposely external to the $ block here.
  } else if (scan === null) {
    isLoaded = true;
  }
  function loadVolume() {
    // clear the old bindings.
    if (volumeViewer && volumeViewer.destroy) volumeViewer.destroy();
    volumeViewer = false;
    canvasEl.setAttribute("isLoaded", false);

    // prepare a new viewer object.
    const vv = new VolumeViewer(jQuery(componentEl));
    vv.setLuminosityTable(bloodLuminosityTable, 0);
    vv.setGradientTable(bloodGradientTable, 0);
    vv.enableLuminosityLighting(false);
    vv.setLuminosityAlpha(0.35);
    vv.setInputMode(VolumeViewer.INPUT_MODE_ROCKER);

    // create the volume.
    vv.enableAutoAdjustFramerate(true);
    vv.enableAutoCanvasResize(false);
    vv.enableClipping(false);
    vv.setZoomRangeMinMax(0.0625, 4);

    // load it from the url.
    vv.addVolumeProcessedCallback(function OnVolumeProcessed() {
      // Cleanup incase we re-process the volume.
      vv.clearActionCallbacks();
      vv.clearVolumeProcessedCallbacks();

      // Set it up for slices.
      vv.enableMiniCube();
      vv.setInputWindow();

      // Interpolation
      vv.enableInterpolation(isInterpolated);
      if (!isInterpolated) vv.enablePlaneSnapToVoxel(true);

      vv.setFaceAxial();
      vv.enableAutoRotation(false);
      vv.setPlaneOffsetXYZ(0.0, 0.0, 0.0);
      vv.setDefaultFilters(null, null);
      vv.setResamplingMultipliers(1.0, 1.0, 1.0);
      vv.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);

      // Gradient & Luminosity
      vv.setGradientRange(vv.getAutoGradientRange(0), 0);
      vv.setLuminosityRange(vv.getAutoLuminosityRange(), 0);

      // Listen for on-canvas changes like scrolling, luminosity, zoom, pan etc.
      vv.addActionCallback(({ actionName, isUserAction }) => {
        if (!isUserAction) return false;

        switch (actionName) {
          // Scroll Handling
          case VolumeViewer.ACTION_PLANE: {
            const { px, py, pz } = vv.getPlaneOffset();
            if (scrollZ === false) globalScrollZ = pz;
            else scrollZ = pz;

            if (scrollY === false) globalScrollY = py;
            else scrollY = py;

            if (scrollX === false) globalScrollX = px;
            else scrollX = px;
            break;
          }
          // Luminosity Handling
          case VolumeViewer.ACTION_THRESHOLDS: {
            const viewerLuminosity = vv.getLuminosityBrightnessAndContrast();
            brightness = viewerLuminosity.brightness;
            contrast = viewerLuminosity.contrast;
            break;
          }
          // Rotation Handling
          case VolumeViewer.ACTION_ROTATE: {
            const matrix = vv.getRotationMatrix();
            if (!rotation) globalRotation = matrix;
            else rotation = matrix;
            break;
          }
          // Clip Handling
          case VolumeViewer.ACTION_CLIP: {
            clipNormal = vv.getClipNormal();
            clipOffset = vv.getClipOffset();
            break;
          }
          // Zoom Handling
          case VolumeViewer.ACTION_ZOOM: {
            zoom = vv.getZoom();
            break;
          }
          // Pan Handling
          case VolumeViewer.ACTION_PAN: {
            const { x, y } = vv.getScreenPan();

            if (panX === false) globalPanX = x;
            else panX = x;

            if (panY === false) globalPanY = y;
            else panY = y;

            break;
          }
          default:
            break;
        }
      });

      // Read the default "auto" values for brightness and contrast.
      const brightnessAndContrast = vv.getLuminosityBrightnessAndContrast();
      autoBrightness = brightnessAndContrast.brightness;
      autoContrast = brightnessAndContrast.contrast;

      // Inject the loaded viewer into the svelte bindings.
      volumeViewer = vv;

      // Set an inspectable property on the canvas to signify loaded state.
      canvasEl.setAttribute("isLoaded", true);

      // Transition the canvas into view once it's had a chance to render.
      const isLoadedTimer = setTimeout(() => {
        isLoaded = true;
        onDestroy(() => clearTimeout(isLoadedTimer));
      }, 100);
    });

    // Request the volume from storage and load it into the volume-viewer.
    fetch(scan.dataUrl)
      .then(response => {
        if (response.ok) return response.arrayBuffer();
        throw new Error("No volume returned from API.");
      })
      .then(arrBuf => {
        if (vv.setVolumeNifti(arrBuf, scan.dataUrl)) return;
        vv.setVolumeDicom([arrBuf], scan.dataUrl);
      });
    // vv.loadRemoteVolume("volumes/BestVentricles_DL.nrrd");
  }

  // Update Volume Viewer when Props change (via human input or URL)
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --|

  // view mode - 2D, 3D, or MPR
  $: viewSettingsReactor(volumeViewer, viewMode);
  function viewSettingsReactor() {
    if (volumeViewer) {
      switch (viewMode) {
        case "2d": {
          volumeViewer.show2DView(isInterpolated);
          volumeViewer.enablePlaneInteriorClamping(true);
          volumeViewer.enableGradients(false);
          volumeViewer.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);
          volumeViewer.enableAutoResize(false);
          volumeViewer.setPlaneTable(blackBgTable);
          volumeViewer.enableInterpolation(isInterpolated);
          if (dragControl === "luminosity")
            volumeViewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_INPUT);
          else if (dragControl === "rocker")
            volumeViewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_SPIN);
          break;
        }
        case "3d": {
          volumeViewer.show3DView();
          volumeViewer.enableGradients(true);
          volumeViewer.enableAutoResize(true);
          volumeViewer.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);
          volumeViewer.setPlaneTable(blackBgTable);
          volumeViewer.enableInterpolation(isInterpolated);
          break;
        }
        case "mpr": {
          volumeViewer.showMPRView();
          volumeViewer.enableGradients(false);
          volumeViewer.setBackgroundColorRGBA(0.4, 0.4, 0.4, 1.0);
          volumeViewer.setPlaneTable(null);
          volumeViewer.enableAutoResize(false);
          volumeViewer.enableInterpolation(isInterpolated);
          break;
        }
        default:
          break;
      }
    }
  }

  // interpolation
  $: if (volumeViewer) {
    volumeViewer.enableInterpolation(isInterpolated);
  }

  // zoom
  $: if (volumeViewer) {
    if (zoom === false) zoom = volumeViewer.calculateZoomToFit();
    volumeViewer.setZoom(zoom);
  }

  // luminosity (brightness & contrast)
  $: if (volumeViewer && autoBrightness !== false && autoContrast !== false) {
    volumeViewer.setLuminosityBrightnessAndContrast(
      brightness || autoBrightness,
      contrast || autoContrast
    );
  }

  // scroll
  $: if (volumeViewer && viewMode) {
    volumeViewer.setPlaneOffsetXYZ(
      scrollX || globalScrollX || 0,
      scrollY || globalScrollY || 0,
      scrollZ || globalScrollZ || 0
    );
  }

  // drag / touch controls (depends on view mode too)
  $: if (volumeViewer) {
    if (dragControl === "luminosity")
      volumeViewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_INPUT);
    else if (dragControl === "rocker")
      volumeViewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_SPIN);
  }

  // rotation
  $: if (volumeViewer) {
    volumeViewer.setRotationMatrix(rotation || globalRotationObj);
  }

  // clipping
  $: if (volumeViewer && viewMode === "3d") {
    volumeViewer.setClipOffset(clipOffset);
  }
  $: if (volumeViewer && viewMode === "3d") {
    if (clipNormal) {
      volumeViewer.enableClipping();
      volumeViewer.setClipNormal(clipNormal);
    } else {
      volumeViewer.enableClipping(false);
    }
  }
  function handleReclip() {
    if (!volumeViewer || viewMode !== "3d") return false;

    clipOffset = 0.2;
    if (!clipNormal) {
      // Turn on Clipping
      volumeViewer.setClipNormalFromRotation();
      clipNormal = volumeViewer.getClipNormal();
    } else {
      // Update clipping...
      volumeViewer.setClipNormalFromRotation();
      const { x, y, z } = clipNormal;
      clipNormal = volumeViewer.getClipNormal();

      // If it hasn't changed, turn off clipping.
      if (x === clipNormal.x && y === clipNormal.y && z === clipNormal.z) {
        clipNormal = clipOffset = false;
      }
    }
  }

  // panning
  $: if (volumeViewer) {
    volumeViewer.setScreenPanXY(
      panX || globalPanX || 0,
      panY || globalPanY || 0
    );
  }

  // annotations
  $: if (volumeViewer && annotation) {
    // console.info({ annotation });
    // const { combined_nifti_url, id } = annotation;
    //TODO: Render the annotation volume.
    // Should just have to call vv.loadRemoteVolume(...) with the combined nifti URL.
    //NOTE: This is going to work by actually swapping out the plain,
    //      unannotated nifti with a new nifti that's a clone of plain + has
    //      another layer baked into it for the annotation.
    // Here's how to render SVG on top of the canvas.
    // In volume-viewer/webgl-demo.js, look for...
    //    viewer.addPostRenderCallback(function(evt) {
  }

  // Respond to the render trigger. This allows parents to trigger a re-render.
  let canvasWidth = 0;
  let canvasHeight = 0;
  $: if (renderTrigger) {
    tick().then(() => {
      if (!componentEl || !volumeViewer) return false;
      canvasWidth = componentEl.clientWidth;
      canvasHeight = componentEl.clientHeight;
      volumeViewer.invalidate();
    });
  }

  // Broadcast update events. The multiviewer uses this to update the URL.
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --|
  $: dispatch("controlChange", { key: "zoom", value: zoom });
  $: dispatch("controlChange", { key: "pox", value: scrollX });
  $: dispatch("controlChange", { key: "poy", value: scrollY });
  $: dispatch("controlChange", { key: "poz", value: scrollZ });
  $: dispatch("controlChange", { key: "panx", value: panX });
  $: dispatch("controlChange", { key: "pany", value: panY });
  $: dispatch("controlChange", { key: "bri", value: brightness });
  $: dispatch("controlChange", { key: "con", value: contrast });
  $: dispatch("controlChange", { key: "rot", value: rotation });
  $: dispatch("controlChange", {
    key: "cli",
    value: clipNormal ? { ...clipNormal, clipOffset } : false,
  });
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";

  .bg-spinner {
    top: 50%;
  }

  .viewer-component {
    background-color: $black;
    transition: all 0.2s ease-out;

    // Transition canvas once loaded.
    .the-canvas {
      opacity: 0;
      transition: opacity 0.2s ease-out;
      &:focus {
        outline: 2px dashed currentColor !important;
        outline-offset: -3px;
      }
    }
    &.is-loaded .the-canvas {
      opacity: 1;
    }
  }

  :global(.viewer-component.lowlight) {
    opacity: 0.2;
  }
</style>

<div
  class="viewer-component d-flex flex-column h-100 position-relative {className}"
  class:is-loaded={isLoaded}
  bind:this={componentEl}
  data-component="viewer">

  <!-- Not Found / Unauthorized -->
  {#if scan === null}
    <slot />
    <h2 class="p-3 text-center my-auto text-light">
      {translate('inaccessible')}
    </h2>
  {:else}
    <!-- Loading Underlay. Once canvas renders, you can't see it. -->
    <div
      class="d-flex justify-content-center align-items-center position-absolute
      w-100 bg-spinner"
      data-cy="viewer-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">{translate('loadingDotDotDot')}</span>
      </div>
    </div>

    <!-- WebGL Canvas -->
    <canvas
      class="the-canvas position-absolute top-0 left-0 right-0 bottom-0 w-100
      h-100"
      tabindex="-1"
      width={canvasWidth}
      height={canvasHeight}
      bind:this={canvasEl} />

    <!-- Innerts -->
    <slot />
    <div class="metadata | small w-100 px-1 px-lg-2 | animated fadeIn faster">
      <div class="d-flex justify-content-between">
        {#if pixelSpacing}
          {#if pixelSpacing.scaleX === pixelSpacing.scaleY}
            <span title={translate('exam_data.pixel_spacing')}>
              {pixelSpacing.scaleX.toFixed(2)}
            </span>
          {:else}
            <span title={translate('exam_data.pixel_spacing_xy')}>
              {pixelSpacing.scaleX.toFixed(2)} / {pixelSpacing.scaleY.toFixed(2)}
            </span>
          {/if}
        {:else}
          <span title={translate('exam_data.pixel_spacing')}>
            {translate('exam_data.pixel_spacing_unknown')}
          </span>
        {/if}
        <span title={translate('exam_data.slice_thickness')}>
          {sliceThickness || translate('exam_data.slice_thickness_unknown')}
        </span>
      </div>
    </div>

    <!-- Controls Overlay (Bottom Left) -->
    {#if volumeViewer}
      <ViewerControls
        {autoBrightness}
        {autoContrast}
        {viewMode}
        {volumeViewer}
        {globalRotation}
        bind:rotation
        bind:brightness
        bind:contrast
        bind:zoom
        bind:scrollZ
        bind:scrollY
        bind:scrollX
        bind:panX
        bind:panY
        bind:globalScrollZ
        bind:globalScrollY
        bind:globalScrollX
        bind:globalPanX
        bind:globalPanY
        bind:clipOffset
        on:reclip={handleReclip} />
    {/if}

    <!-- Scan Details Modal -->
    {#if $queryParameters.modal === `scan-details-${scanId}`}
      <ScanDetailsModal {scan} />
    {/if}
  {/if}
</div>
