// Import our external dependencies.
import { translate } from "../helpers/i18n";
import { writable } from "svelte/store";
import offlineStorage from "store/dist/store.modern.min";
import * as authServer from "../helpers/auth0";
import { client as api } from "../helpers/apollo";
import * as telemetry from "../helpers/telemetry";
import { GET_USER, registerUser } from "./user.graphql";

// Local properties
let token_expiration_timer = null;

// Overload the Apollo Client's unauthorization handler.
api.onUnauthorized = exit;

// Create a Svelte store to reactively store our User data (POJO)
// Read user data from local storage & confirm token freshness.
const user = writable(null);
if (checkTokenExpiration()) {
  user.set(offlineStorage.get("user"));
}

// Update local storage, telemetry, and global data when the user changes.
user.subscribe($user => {
  window.indigen = { ...window.indigen, userEmail: $user ? $user.email : null };
  offlineStorage.set("user", $user);
  if ($user && $user.userId) telemetry.trackUser($user);
  else telemetry.untrack();
});

// Private Datas & Functions -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  - |

async function getUserDetailsFromApi() {
  const resp = await api.query({ query: GET_USER });
  if (!resp.data.viewer) return;
  resp.data.viewer.user.organization = resp.data.viewer.organization;
  return resp.data.viewer.user;
}

function checkTokenExpiration(msAtExpiration) {
  // store new expiration if it's provided.
  if (msAtExpiration) offlineStorage.set("user-expires-at", msAtExpiration);

  // clear any existing timers.
  clearTimeout(token_expiration_timer);

  // check validity at this time.
  const user_expires_at =
    msAtExpiration || offlineStorage.get("user-expires-at");
  const user_expires_in = user_expires_at
    ? user_expires_at - Date.now()
    : false;

  // set a timer if the token is valid.
  if (user_expires_in > 0) {
    token_expiration_timer = setTimeout(exit, user_expires_in);
    return true;
  }

  // clear the user, since they've timed out.
  exit();
  return false;
}

// Extend custom functionality  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  - |

// Function to authenticate against Auth0. Gets details from our API too.
export async function authenticate({ email, password }) {
  const auth0_user_data = await authServer.authenticate({ email, password });

  // Track their auth token expiration.
  const { expiresIn = 0 } = auth0_user_data;
  const expiresAt = Date.now() + expiresIn;
  offlineStorage.set("user-expires-at", expiresAt);
  checkTokenExpiration(expiresAt);

  const our_user_data = await getUserDetailsFromApi();
  user.set({ ...auth0_user_data, ...our_user_data });
}

// Function to de-authenticate.
export function exit() {
  authServer.token.set("");
  api.resetStore();
  telemetry.untrack();
  user.set(null);
}

// Function to sign up for an account.
export async function doRegisterUser({ email, password, name }) {
  const {
    data: {
      registerUser: { user },
    },
  } = await api.mutate({
    mutation: registerUser,
    variables: { input: { email, password, name } },
  });
  return user;
}

// Validations for passwords.
export function getPasswordValidations() {
  return [
    {
      check(pwd) {
        return /\d/.test(pwd);
      },
      ruleText: translate("validation.password_number"),
    },
    {
      check(pwd) {
        return pwd && pwd.length && pwd.length >= 6;
      },
      ruleText: translate("validation.password_length"),
    },
    {
      check(pwd) {
        return pwd && pwd.length && pwd.toLowerCase() !== pwd;
      },
      ruleText: translate("validation.password_uppercase"),
    },
    {
      check(pwd) {
        return pwd && pwd.length && pwd.toUpperCase() !== pwd;
      },
      ruleText: translate("validation.password_lowercase"),
    },
  ];
}

export default user;
