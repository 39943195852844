// Include our external dependencies.
import { ready as i18nReady } from "i18n"; //eslint-disable-line import/no-unresolved
import { setServiceWorker } from "./helpers/service-worker";
import UI from "./ui.svelte";
import { showRefreshPrompt } from "./stores/notifications";
import makeUUID from "./helpers/uuid";
import { version } from "../../package.json";

// Render the application into the DOM (wait for translations to load).
i18nReady.then(function startApp() {
  // Render the application.
  const ui = new UI({
    target: document.getElementById("UI"),
    props: {
      isUpdateAvailable: false,
    },
  });

  // Remove the Splash Overlay.
  document.getElementById("Splash").className = "hidden";

  return ui;
});

const ui = {
  // These functions are exposed so they can be used by the service-worker module.
  showRefreshPrompt,
  setServiceWorker,
  version,
  makeUUID,
};

export default ui;
