<script>
  import { route1, route2 } from "../stores/router";
  import OrganizationHome from "./organization-home.svelte";
  import OrganizationDetails from "./organization-details.svelte";
</script>

<section data-component="organization-module" class="h-100">

  <!-- Sub Routing -->
  {#if $route1 === 'details'}
    <OrganizationDetails organizationId={$route2} />
  {:else if !$route1}
    <OrganizationHome />
  {/if}
</section>
