<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { onDestroy } from "svelte";
  import makeUUID from "../helpers/uuid";
  import {
    route,
    queryParameters,
    updateQueryParameters,
  } from "../stores/router";

  // External Props
  export let scanId = false;
  export let scanIds = false;
  export let scan = false;
  export let urlIndex = false;
  export let viewMode = "2d";
  export let isInterpolated = false;

  // Internals
  const queryParameterKeys = [
    "poz",
    "pox",
    "poy",
    "panx",
    "pany",
    "bri",
    "con",
    "mod",
    "rot",
    "cli",
    "zoom",
    "interp",
  ];
  const uuid = makeUUID();
  $: modeUrlKey = `mod${urlIndex}`;

  // This renders into the `Close (x)` button's href.
  $: closeLink = (function compute_closeLink() {
    if (!scanIds || !scanIds.length) return "";

    // Remove the scan from the list of scans
    const others = [...scanIds];
    others.splice(urlIndex, 1);
    const newQPs = { scans: others.toString() };

    // Decrement subsequent window modifiers.
    for (let i = urlIndex, l = scanIds.length; i < l; i++)
      queryParameterKeys.forEach(key => {
        newQPs[`${key}${i}`] = $queryParameters[`${key}${i + 1}`] || null;
      });

    // Format the URL and return it.
    return `#/${formatUrl({ $route, $queryParameters, newQPs })}`;
  })();

  // This renders into the `Expand This Panel` button's href.
  $: expandLink = (function compute_expandLink() {
    if (!scanIds || !scanIds.length) return "";

    // Set the open scans to just this one.
    const newQPs = { scans: scanId };

    // Clear all panel-specific query parameters.
    for (let i = 0, l = scanIds.length; i < l; i++)
      queryParameterKeys.forEach(key => (newQPs[`${key}${i}`] = null));

    // Move QPs for brightness, contract, and unlocked scroll.
    queryParameterKeys.forEach(key => {
      if ($queryParameters[`${key}${urlIndex}`]) {
        newQPs[`${key}0`] = $queryParameters[`${key}${urlIndex}`];
        if (urlIndex !== 0) newQPs[`${key}${urlIndex}`] = null;
      }
    });

    // Specifically, reset scr0 to just scroll... since it's the only panel it doesn't have to specifically unlink.
    if (newQPs.scr0) {
      newQPs.scr1 = newQPs.scr0;
      newQPs.scr0 = null;
    }

    // Format the URL and return it.
    return `#/${formatUrl({ $route, $queryParameters, newQPs })}`;
  })();

  let interpTimer = null;
  function handleInterpChange() {
    interpTimer = setTimeout(() => {
      updateQueryParameters({
        [`interp${urlIndex}`]: isInterpolated ? null : "false",
      });
    }, 300);
  }
  onDestroy(() => clearTimeout(interpTimer));
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  @import "src/ui/viewer-module/_vars";

  // Styles
  .panel-header {
    background-color: $gray-925;
    height: $pane-header-height;
    .btn-trans {
      border-color: transparent !important;
      background-color: transparent;
    }
    .viewer-header-btn {
      font-size: $input-btn-font-size;
    }
  }
  .custom-control-label {
    color: $input-color;
  }
</style>

<header class="panel-header | position-relative container-fluid px-0">
  <div class="row no-gutters align-items-center h-100">

    <!-- Title (Details Modal Trigger too!) -->
    <div class="col text-truncate pr-2">
      <a
        class="btn btn-trans btn-block text-left text-uppercase text-truncate
        pl-1 pl-lg-2"
        href={`#/${formatUrl({
          $route,
          $queryParameters,
          newQPs: { modal: `scan-details-${scanId}` },
        })}`}>
        <i class="fas fa-fw fa-info-circle mr-1" />
        {scan ? scan.descriptionUser.split('_').join(' ') : scanId}
      </a>
    </div>

    <!-- Ellipsis Menu -->
    <div class="col-auto h-100">
      <div class="dropdown h-100">
        <button
          class="btn btn-trans viewer-header-btn close px-2 h-100"
          type="button"
          id="EllipsisMenu{uuid}"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-cy="ellipsis-menu">
          <i class="fas fa-fw fa-ellipsis-h" />
          <span class="sr-only">{translate('more_options')}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="EllipsisMenu{uuid}">

          <!-- Viewer Settings Toggle -->
          <h6 class="dropdown-header">
            {translate('multiviewer.view_settings')}
          </h6>
          <div class="px-4 py-1">
            <div
              class="btn-group btn-group-sm btn-group-toggle w-100"
              data-toggle="buttons">
              <a
                class="btn btn-primary"
                class:active={!viewMode || viewMode === '2d'}
                href={`#/${formatUrl({
                  $route,
                  $queryParameters,
                  newQPs: {
                    [modeUrlKey]: null,
                    [`rot${urlIndex}`]: null,
                    [`cli${urlIndex}`]: null,
                  },
                })}`}>
                {translate('multiviewer.2d')}
              </a>
              <a
                class="btn btn-primary"
                class:active={viewMode === '3d'}
                href={`#/${formatUrl({
                  $route,
                  $queryParameters,
                  newQPs: { [modeUrlKey]: '3d' },
                })}`}>
                {translate('multiviewer.3d')}
              </a>
              <a
                class="btn btn-primary"
                class:active={viewMode === 'mpr'}
                href={`#/${formatUrl({
                  $route,
                  $queryParameters,
                  newQPs: { [modeUrlKey]: 'mpr' },
                })}`}>
                {translate('multiviewer.mpr')}
              </a>
            </div>
          </div>

          <!-- Interpolation Controls -->
          <div class="px-4 py-1">
            <div
              class="custom-control custom-switch ml-1 mt-2"
              on:click|stopPropagation>
              <input
                type="checkbox"
                class="custom-control-input"
                id={`InterpSwitch${urlIndex}`}
                bind:checked={isInterpolated}
                on:change={handleInterpChange} />
              <label
                class="custom-control-label pl-2"
                for={`InterpSwitch${urlIndex}`}>
                {translate('multiviewer.interpolation')}
              </label>
            </div>
          </div>

          <div class="dropdown-divider" />

          <!-- Downloads -->
          {#if scan && scan.dataUrl}
            <a class="dropdown-item" href={scan.dataUrl}>
              {translate('exam_data.download_image_data')}
            </a>
          {:else}
            <span
              class="dropdown-item disabled"
              tabindex="-1"
              aria-disabled="true">
              {translate('exam_data.download_image_data')}
            </span>
          {/if}
        </div>
      </div>
    </div>

    <!-- Expand Button -->
    {#if scanIds.length > 1}
      <div class="col-auto">
        <a
          class="viewer-header-btn btn-trans close px-2"
          aria-label={translate('form_labels.expand')}
          href={expandLink}>
          <i class="fas fa-fw fa-expand" />
        </a>
      </div>
    {/if}

    <!-- Close Button -->
    <div class="col-auto">
      <a
        class="close px-2 pb-1"
        aria-label={translate('form_labels.close')}
        href={closeLink}>
        <span aria-hidden="true">×</span>
      </a>
    </div>
  </div>
</header>
