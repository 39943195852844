<script>
  // Include our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { createEventDispatcher } from "svelte";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import Modal, { closeUrl } from "../../components/modal.svelte";
  import PhaseButton from "../../components/phase-button.svelte";
  import { client } from "../../helpers/apollo";
  import { route, queryParameters } from "../../stores/router";
  import { shortId, getBadgeClassName } from "../helpers";
  import { deleteSeries } from "../gql/delete-scan.graphql";

  // Props
  export let scanId = false;
  export let indication = false;

  // Event Dispatcher
  const dispatch = createEventDispatcher();

  // Highlight the "Cancel" button when opened.
  let cancelBtnEl = false;
  $: if (cancelBtnEl && cancelBtnEl.focus) cancelBtnEl.focus();

  // Async Deletion
  let isInFlight = false;
  let httpError = false;
  let wasDeleted = false;
  async function handleDeleteClick() {
    const cached_scanId = scanId;
    isInFlight = true;
    httpError = false;
    await client
      .mutate({
        mutation: deleteSeries,
        variables: {
          input: { seriesId: scanId },
        },
      })
      .catch(err => (httpError = err));
    isInFlight = false;

    if (httpError) return false;

    // Handle Successful Deletion...
    wasDeleted = true;
    dispatch("deleted", { scanId: cached_scanId });
  }
</script>

<Modal
  title={translate('scan_data.do_delete')}
  closeHref={`#/${formatUrl({
    $route,
    $queryParameters,
    newQPs: { modal: null, delIndication: null },
  })}`}>
  <div class="modal-body">
    <p>{translate('scan_data.delete_are_you_sure')}</p>
    {#if indication}
      <div
        class="border rounded px-3 py-2 scan-list-item selected {indication
          .split('_')
          .join(' ')
          .toLowerCase()}">
        <h5 class="font-weight-normal text-uppercase">
          {indication.replace(/_/g, ' ')}
        </h5>
        <span class="{getBadgeClassName(indication)} mr-2">
          {indication.replace(/_/g, ' ')}
        </span>
      </div>
    {/if}
  </div>
  <div class="modal-footer d-block">
    <div class="d-flex justify-content-between">
      <PhaseButton
        startClassName="btn-danger"
        type="button"
        on:click={handleDeleteClick}
        {isInFlight}
        isSuccessful={wasDeleted}
        successTxt={translate('form_labels.success')}>
        <span>{translate('form_labels.confirm_delete')}</span>
      </PhaseButton>
      {#if !wasDeleted}
        <a
          href={$closeUrl}
          bind:this={cancelBtnEl}
          class="btn btn-outline-secondary">
          {translate('form_labels.cancel')}
        </a>
      {/if}
    </div>

    {#if wasDeleted}
      <div
        class="alert alert-success ml-0 mb-0 mt-3 animated fadeIn faster"
        role="alert">
        {translate('scan_data.delete_success', { scanId: shortId(scanId) })}
      </div>
    {:else if httpError}
      <div
        class="alert alert-danger ml-0 mb-0 mt-3 animated fadeIn faster"
        role="alert">
        🤖 {httpError}
      </div>
    {/if}
  </div>
</Modal>
