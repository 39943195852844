<script>
  // Include our external depednencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import filter from "lodash-es/filter";

  // Props
  export let className = "";
  export let value = [];
  export let multiple = false;
  export let accept = "";

  // Supported file extensions - computed
  $: supportedExtensions = accept ? accept.replace(/ /g, "").split(",") : [];

  // Drag DOM Handlers.
  let isFileHovering = false;
  function handleFileDrop(event) {
    handleFileSelect(event.dataTransfer.files);
    isFileHovering = false;
  }
  function handleDragEnter(event) {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    isFileHovering = true;
  }

  // File Selection
  let fileSelectEl;
  function handleFileSelect(filesIn) {
    // Filter out extensions that we don't support.
    const files = filter(
      filesIn.length ? filesIn : fileSelectEl.files,
      file => {
        return (
          file &&
          file.name &&
          supportedExtensions.find(ext => file.name.indexOf(ext) !== -1)
        );
      }
    );
    value = files;
  }
</script>

<style type="text/scss">
  // Sass Variables
  @import "bootstrap/variables";
  $examsColor: map-get($module-colors, "exams");

  .upload-box {
    border: 2px dashed $secondary;
    height: 4rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    &.hovering,
    &:hover {
      border-color: currentColor;
      color: $examsColor !important;
    }
  }
</style>

<div class={className} data-component="drag-n-drop">
  <label
    class="upload-box text-muted rounded mb-0 p-3 d-flex align-items-center"
    for="ScanFileUploader"
    class:hovering={isFileHovering}
    on:dragenter={handleDragEnter}
    on:dragleave={() => (isFileHovering = false)}
    ondragover="return false"
    on:drop|preventDefault={handleFileDrop}>
    <span class="mx-auto">{translate('form_labels.drop_to_upload')}</span>
  </label>
  <input
    type="file"
    class="hidden-file-uploader h-0 w-0"
    id="ScanFileUploader"
    {accept}
    {multiple}
    on:change={handleFileSelect}
    bind:this={fileSelectEl} />
</div>
