// Import our external dependencies.
import { formatDate, parseDate } from "i18n"; //eslint-disable-line import/no-unresolved

export function dateAndTime(dateOrString) {
  const date = getDate(dateOrString);
  return formatDate(date, "MM/dd/yy - HH:mm");
}

export function shortDate(dateOrString) {
  if (!dateOrString) return "";
  const date = getDate(dateOrString);
  return formatDate(date, "MM/dd/yy");
}

export function dateInWords(dateOrString) {
  const date = getDate(dateOrString);
  return formatDate(date, "MMMM Mo, yyyy");
}

export function getInitials(nameTxt) {
  if (!nameTxt || !nameTxt.split) return "";
  const pieces = nameTxt.split(" ");
  return pieces.reduce((accumulator, piece) => `${accumulator}${piece[0]}`, "");
}

export function getTags(txt) {
  if (!txt) return [];
  const noPunc = txt.replace(/[.,/?!$%^&*;:{}=\-_`~()]/g, "");
  const words = noPunc.split(" ");
  return words.reduce((accumulator, word) => {
    if (word[0] === "#") accumulator.push(word.slice(1));
    return accumulator;
  }, []);
}

// This is a hack to hide non-imaging sequences in the viewer
const nonImagingSequences = ["cal", "service", "map", "test"];
function makeRegExp(nonImagingSequence) {
  return new RegExp(`(_${nonImagingSequence})?(${nonImagingSequence}_)?`, "g");
}
const patterns = nonImagingSequences.map(makeRegExp);

export function isImagingSequence({ descriptionUser }) {
  const found = patterns.map(pattern => {
    return descriptionUser
      .toLowerCase()
      .match(pattern)
      .some(Boolean);
  });
  return found.some(Boolean) === false;
}

// Private Functions
// -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
function getDate(dateOrString) {
  return typeof dateOrString === "string"
    ? parseDate(dateOrString)
    : dateOrString;
}
