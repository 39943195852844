<script>
  // Include our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import Modal from "../components/modal.svelte";

  const keyboard_shortcuts = [
    {
      one: "shift",
      two: "R",
      hint: "reset_orientation",
    },
    {
      one: "shift",
      two: "0",
      hint: "fit_viewport",
    },
    {
      one: "0",
      hint: "reset_input_wind",
    },
    {
      one: "shift",
      two: "arrows",
      hint: "pans",
    },
    {
      one: "shift",
      two: "plus_minus",
      hint: "zoom",
    },
    {
      one: "shift",
      two: "brackets",
      hint: "rotate_90",
    },
    {
      one: "shift",
      two: "H",
      hint: "horizontal_flip",
    },
    {
      one: "shift",
      two: "V",
      hint: "vertical_flip",
    },
    {
      one: "shift",
      two: "alt",
      three: "1",
      hint: "reo_coronal",
    },
    {
      one: "shift",
      two: "alt",
      three: "2",
      hint: "reo_sagittal",
    },
    {
      one: "shift",
      two: "alt",
      three: "3",
      hint: "reo_axial",
    },
    {
      one: "1",
      hint: "reo_anterior",
    },
    {
      one: "2",
      hint: "reo_posterior",
    },
    {
      one: "3",
      hint: "reo_left",
    },
    {
      one: "4",
      hint: "reo_right",
    },
    {
      one: "5",
      hint: "reo_superior",
    },
    {
      one: "6",
      hint: "reo_inferior",
    },
    {
      one: "up_down",
      hint: "move_clip",
    },
    {
      one: "home",
      hint: "first_slice",
    },
    {
      one: "end",
      hint: "last_slice",
    },
  ];
</script>

<style type="text/scss">
  /* Sass Variables */
  @import "bootstrap/variables";
  .tutorial-modal {
    :global(.modal-content) {
      border-top-color: $info;
    }
  }
</style>

<section class="tutorial-modal" data-component="tutorial-modal">
  <Modal
    title={translate('multiviewer.tutorial.keyboard_shortcuts')}
    className="modal-xl modal-dialog-scrollable">

    <!-- Core Content -->
    <div class="modal-body">

      <!-- Render all Shortcuts -->
      <ul class="list-group mb-3">
        {#each keyboard_shortcuts as { one, two, three, hint } (hint)}
          <li
            class="list-group-item d-flex justify-content-between
            align-items-center">
            <span>{translate(`multiviewer.kb_shorts.${hint}`)}</span>
            <div class="ws-nowrap">
              <kbd class="border border-light">
                <kbd>{translate(`multiviewer.kb_shorts.${one}`)}</kbd>
                {#if two}
                  +
                  <kbd>{translate(`multiviewer.kb_shorts.${two}`)}</kbd>
                {/if}
                {#if three}
                  +
                  <kbd>{translate(`multiviewer.kb_shorts.${three}`)}</kbd>
                {/if}
              </kbd>
            </div>
          </li>
        {/each}
      </ul>
    </div>
  </Modal>
</section>
