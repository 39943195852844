<script>
  // Include our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onDestroy } from "svelte";
  import Modal, { closeUrl } from "../../components/modal.svelte";
  import PhaseButton from "../../components/phase-button.svelte";
  import { client } from "../../helpers/apollo";
  import { shortId } from "../helpers";
  import { deleteExam } from "../gql/delete-exam.graphql";

  // Props
  export let examId = false;

  // Highlight the "Cancel" button when opened.
  let cancelBtnEl = false;
  $: if (cancelBtnEl && cancelBtnEl.focus) cancelBtnEl.focus();

  // Async Deletion
  let isInFlight = false;
  let httpError = false;
  let wasDeleted = false;
  async function handleDeleteClick() {
    isInFlight = true;
    httpError = false;
    await client
      .mutate({
        mutation: deleteExam,
        variables: {
          input: { examId },
        },
      })
      .catch(err => (httpError = err));
    isInFlight = false;

    if (httpError) return false;

    // Handle Successful Deletion...
    wasDeleted = true;
    redirectTimer = setTimeout(() => {
      window.location = "#/exams";
    }, 3500);
  }

  // Timer cleanup
  let redirectTimer = null;
  onDestroy(() => clearTimeout(redirectTimer));
</script>

<Modal title={translate('exam_data.do_delete')}>
  <div class="modal-body">
    <p>{translate('exam_data.delete_are_you_sure')}</p>
  </div>
  <div class="modal-footer d-block">
    <div class="d-flex justify-content-between">
      <PhaseButton
        startClassName="btn-danger"
        type="button"
        on:click={handleDeleteClick}
        {isInFlight}
        isSuccessful={wasDeleted}
        successTxt={translate('form_labels.success')}>
        <span>{translate('form_labels.confirm_delete')}</span>
      </PhaseButton>
      {#if !wasDeleted}
        <a
          href={$closeUrl}
          bind:this={cancelBtnEl}
          class="btn btn-outline-secondary">
          {translate('form_labels.cancel')}
        </a>
      {/if}
    </div>

    {#if wasDeleted}
      <div
        class="alert alert-success ml-0 mb-0 mt-3 animated fadeIn faster"
        role="alert">
        {translate('exam_data.delete_success', { examId: shortId(examId) })}
        <br />
        {translate('exam_data.delete_success_redirect')}
      </div>
    {:else if httpError}
      <div
        class="alert alert-danger ml-0 mb-0 mt-3 animated fadeIn faster"
        role="alert">
        🤖 {httpError}
      </div>
    {/if}
  </div>
</Modal>
