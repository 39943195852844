// Import our external dependencies.
import { translate } from "i18n"; //eslint-disable-line import/no-unresolved

// Helper Functions
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -|

export function renderFullName(fullName) {
  const unknown = translate("patient_data.unknown");
  if (!fullName) return unknown;

  const nameFirst = fullName.nameFirst || fullName.name_first || "";
  const nameMiddle = fullName.nameMiddle || fullName.name_middle || "";
  const nameLast = fullName.nameLast || fullName.name_last || "";

  const name_pieces = [];
  if (nameFirst) name_pieces.push(nameFirst);
  if (nameMiddle) name_pieces.push(nameMiddle);
  if (nameLast) name_pieces.push(nameLast);
  return name_pieces.join(" ") || unknown;
}

export function shortId(longId) {
  if (!longId) return "";
  const bits = longId.split("-");
  if (!bits || !bits.length) return longId;
  return `${bits[0]}…`;
}

// Function for parsing badge classnames out of a `descriptionUser`.
export function getBadgeClassName(descriptionUser = "") {
  // sanitize original description by removing spaces and parentheses
  const description = descriptionUser
    .replace("(", "")
    .replace(")", "")
    .replace(/ /g, "_");
  const badgeCNs = description.split("_").reduce((memo, chunk = "") => {
    return `${memo} badge-${chunk.toLowerCase()}`;
  }, "");
  return `badge text-uppercase${badgeCNs}`;
}
