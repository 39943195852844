<script>
  // Import our external dependencies.
  import { createEventDispatcher } from "svelte";
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import makeUUID from "../helpers/uuid";
  import debounce from "lodash-es/debounce";

  // External Props
  export let className = "";
  export let placeholder = translate("form_labels.search_placeholder");
  export let searchTxt = "";
  export let debounceTime = 350;

  // Internal Props
  const dispatch = createEventDispatcher();
  const uuid = makeUUID();

  // Search change handling with optional debounce.
  function handleSearchTxtChange({ target: { value } }) {
    dispatch("searchTxtChange", value);
  }
  const debounced_handleSearchTxtChange = debounce(
    handleSearchTxtChange,
    debounceTime
  );
</script>

<!-- Search Controls -->
<div
  class="d-flex justify-content-between {className}"
  data-component="search-bar">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text" id="seach-addon{uuid}">
        <i class="fas fa-search" />
      </span>
    </div>
    <input
      type="text"
      class="form-control"
      {placeholder}
      aria-label={placeholder}
      aria-describedby="seach-addon{uuid}"
      value={searchTxt}
      on:keyup={debounceTime ? debounced_handleSearchTxtChange : handleSearchTxtChange}
      on:focus />
  </div>
</div>
