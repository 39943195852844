// This function safely parses JSON from strings. If not readable, returns an object.
export function parseJSON(jsonStr = "") {
  let parsed = {};
  try {
    parsed = JSON.parse(jsonStr);
  } catch (e) {
    // There was a JSON parse error  :'(
  }
  return parsed;
}
