<script>
  // Include our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import uniqBy from "lodash-es/uniqBy";
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { client } from "../helpers/apollo";
  import { route, queryParameters } from "../stores/router";
  import { renderFullName } from "../exam-module/helpers";
  import { renderScanToAdd, getScanClassName } from "./helpers";
  import Modal from "../components/modal.svelte";
  import ExamList from "../exam-module/exam-list.svelte";
  import ExamListItem, { scansToAdd } from "./scan-search-item.svelte";
  import PatientListItem from "./patient-search-item.svelte";
  import { scanIds } from "./viewer.svelte";
  import { GET_SCAN } from "./viewer.graphql";

  // External Props
  export let exams = false;
  export let scans = false;

  // Reactive Properties!

  // List of Subjects to suggest Scans of
  $: subjects = compute_subjects(exams);
  function compute_subjects(exams) {
    if (!exams || !exams.length) return false;
    const subjects = exams.reduce((acc, exam) => {
      if (exam && exam.subject) acc.push(exam.subject);
      return acc;
    }, []);
    const deduped_subjects = uniqBy(subjects, "subjectId");
    return deduped_subjects.length ? deduped_subjects : false;
  }

  // List of Selected Scans to Add. Query API for full details of each.
  let detailedScansToAdd = [];
  $: compute_detailedScansToAdd($scansToAdd);
  function compute_detailedScansToAdd(scanIds) {
    scanIds.forEach(async (scanId, i) => {
      detailedScansToAdd[i] = false;
      const resp = await client.query({
        query: GET_SCAN,
        variables: { scanId },
      });
      const scan = resp.data.series;
      detailedScansToAdd[i] = scan;
      detailedScansToAdd = [...detailedScansToAdd];
    });
  }

  // The "Close" or "Cancel" URL.
  $: closeHref = `#/${formatUrl({
    $route,
    $queryParameters,
    newQPs: {
      "scans-to-add": null,
      modal: null,
      search: null,
      tab: null,
    },
  })}`;

  // The "Done" or "Add Scans" URL.
  $: doneUrl = $queryParameters["scans-to-add"]
    ? `#/${formatUrl({
        $route,
        $queryParameters,
        newQPs: {
          "scans-to-add": null,
          modal: null,
          search: null,
          tab: null,
          scans: [...$scanIds, $scansToAdd.toString()],
        },
      })}`
    : closeHref;
</script>

<style type="text/scss">
  /* Sass Variables */
  @import "bootstrap/variables";
  $scanColor: map-get($module-colors, "scans");

  .add-scan-modal {
    :global(.modal-content) {
      border-top-color: $scanColor !important;
    }
  }
  .modal-footer {
    :global(.scan-list-item) {
      border-top-width: 1px;
      min-height: 3.875rem;
      @each $scan, $color in $scan-colors {
        &.#{$scan} {
          border-top-color: $color !important;
        }
      }
    }
  }
  .secondary-nav {
    z-index: 1;
    .nav-link {
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 200ms ease-out;
      transform: translateY(1px);
      &.active {
        border-bottom-color: $scanColor;
      }
    }
  }
</style>

<div class="add-scan-modal" data-component="add-scan-modal">
  <Modal
    {closeHref}
    title={translate('multiviewer.add_scan')}
    className="modal-xl modal-dialog-scrollable">

    <!-- Tabs (only shows on mobile) -->
    <nav class="nav secondary-nav border-bottom d-xl-none">
      {#if subjects}
        <a
          class="nav-link unlink pt-4"
          class:active={$queryParameters.tab === 'subjects' || (subjects && !$queryParameters.tab)}
          href={`#/${formatUrl({
            $route,
            $queryParameters,
            newQPs: { tab: 'subjects' },
          })}`}>
          {#if subjects.length === 1}
            {renderFullName(subjects[0].fullName)}
          {:else}Subjects{/if}
        </a>
      {/if}
      <a
        class="nav-link unlink pt-4"
        class:active={$queryParameters.tab === 'search' || (!subjects && !$queryParameters.tab)}
        href={`#/${formatUrl({
          $route,
          $queryParameters,
          newQPs: { tab: 'search' },
        })}`}>
        Search
      </a>
    </nav>

    <!-- Core Contents -->
    <div class="modal-body container-fluid">
      <div class="row">
        <div class="col-xl-6">

          <!-- Patients -->
          <div
            class="d-none d-xl-block"
            class:d-block={$queryParameters.tab === 'subjects' || (subjects && !$queryParameters.tab)}>
            {#if subjects}
              {#each subjects as subject, i}
                <div class="mb-4">
                  <h2>
                    <a
                      class="unlink"
                      href={`#/patients/details/${subject.subjectId}`}>
                      {renderFullName(subject.fullName)}
                    </a>
                  </h2>
                  <ExamList
                    ExamListItem={PatientListItem}
                    filter={subject.subjectId}
                    perPage={3}
                    searchUrlKey={`search-${i}`} />
                </div>
              {/each}
            {/if}
          </div>
        </div>
        <div class="col-xl-6">

          <!-- Exam Search -->
          <div
            class="d-none d-xl-block"
            class:d-block={$queryParameters.tab === 'search' || (!subjects && !$queryParameters.tab)}>
            <h2>Exam Search</h2>
            <ExamList {ExamListItem} perPage={3} />
          </div>
        </div>
      </div>
    </div>

    <!-- Selected Scans & Go Button -->
    <div class="modal-footer container-fluid py-2 d-block">
      <div class="row align-items-center">
        {#if $scansToAdd && $scansToAdd.length}
          <!-- Selected Scans Dropdown (Mobile Only) -->
          <div class="col-lg d-xl-none">
            <!-- Example split button -->
            <div class="btn-group d-flex dropup">
              <button
                type="button"
                class="btn btn-dark scan-list-item flex-grow-1 {getScanClassName(detailedScansToAdd[0])}"
                class:rounded={$scansToAdd.length <= 1}>
                {@html renderScanToAdd($scansToAdd[0], detailedScansToAdd[0], $route, $queryParameters, $scansToAdd)}
              </button>
              {#if $scansToAdd.length > 1}
                <button
                  type="button"
                  class="btn btn-dark scan-list-item dropdown-toggle
                  dropdown-toggle-split flex-grow-0 {getScanClassName(detailedScansToAdd[0])}"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <span class="sr-only">{translate('toggle_dropdown')}</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  {#each $scansToAdd as id, i (id)}
                    {#if i !== 0}
                      <div class="p-1">
                        <button
                          class="btn btn-dark scan-list-item w-100 {getScanClassName(detailedScansToAdd[i])}">
                          {@html renderScanToAdd(id, detailedScansToAdd[i], $route, $queryParameters, $scansToAdd)}
                        </button>
                      </div>
                    {/if}
                  {/each}
                </div>
              {/if}
            </div>
          </div>

          <!-- Selected Scans List (XL+) -->
          <div class="col-xl d-none d-xl-block">
            <div class="form-row mt--2">
              {#each $scansToAdd as id, i (id)}
                <div class="col-xl-4 mt-2">
                  <div
                    class="btn btn-dark scan-list-item btn-block {getScanClassName(detailedScansToAdd[i])}">
                    {@html renderScanToAdd(id, detailedScansToAdd[i], $route, $queryParameters, $scansToAdd)}
                  </div>
                </div>
              {/each}
            </div>
          </div>
        {/if}
        <div class="col-lg-auto ml-auto">
          <a
            class="btn btn-block btn-primary mt-2 mt-lg-0"
            class:mt-2={$scansToAdd && $scansToAdd.length}
            href={doneUrl}>
            {#if $scansToAdd.length}
              {translate('multiviewer.add_scan_confirmation', $scansToAdd.length)}
            {:else}{translate('nevermind')}{/if}
          </a>
        </div>
      </div>
    </div>
  </Modal>
</div>
